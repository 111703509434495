import RouteName, { toString } from "./RouteName"
import Route from "./Route"
import RouteConfiguration from "./RouteConfiguration"
import RouteFactory from "./RouteFactory"

import defaultRouteConfiguration from "./defaultRouteConfiguration"

const routeFactory = new RouteFactory(defaultRouteConfiguration)

export { 
    RouteName,
    toString,
    Route,
    RouteConfiguration,
    RouteFactory,
    routeFactory,
}