import { useFetchArrayData } from "../../tools/fetchRequest";
import { ActivityGroupItemResponse } from "../../models/api/admin/activity-groups/ActivityGroupItemResponse";
import { ListActivityGroupsQuery } from "../../models/api/admin/activity-groups/ListActivityGroupsQuery";

const listQuery = new ListActivityGroupsQuery();

function useListActivityGroups() {
    return useFetchArrayData<ActivityGroupItemResponse>(listQuery);
};

export default useListActivityGroups;