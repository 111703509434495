import React from "react";

import authToken from "./AuthToken";

function useAuthToken(): [
    string | null,
    React.Dispatch<React.SetStateAction<string | null>>
] {
    const [token, setToken] = React.useState<string | null>(authToken.get());

    React.useEffect(() => {
        if (token !== null && token !== undefined && token.length > 0) {
            authToken.set(token);
        }
        else {
            authToken.clear();
        }
    }, [token]);

    return [token, setToken];
}

export default useAuthToken;
