import React from "react";

import { InfoIcon } from "../../App/Theme";
import { FormFieldsBuilder } from "../../components/FormField";
import { useAuthContext } from "../../tools/Auth";
import { IField } from "../../models/api/IField";
import FormPaper from "../../components/FormPaper";
import { IAuthPayload } from "../../tools/Auth/AuthContext";
import { useNotificationContext } from "../../components/Notification";

class ProfileResponse {
  firstName!: string;
  lastName!: string;

  public static Fields = {
    firstName: { name: "firstName", type: "string" },
    lastName: { name: "lastName", type: "string" }
  } as Record<"firstName" | "lastName", IField>;
}

function emptyResponseBuilder(payload: IAuthPayload | null) {
  let response = new ProfileResponse();
  response.firstName = payload ? payload.firstName : "";
  response.lastName = payload ? payload.firstName : "";
  return response;
}

export default function ProfileFormPaper() {

  const { payload } = useAuthContext();
  const { success } = useNotificationContext();

  const fields = React.useMemo(() => buildFields(), []);
  const [initial, setInitial] = React.useState(emptyResponseBuilder(payload));

  React.useEffect(() => {
    setInitial(emptyResponseBuilder(payload));
  }, [payload]);

  const buildMessage = React.useCallback((data: ProfileResponse) => {
    // return new SendAdminContactEmailCommand(data);
    // TODO : return command to update profile
  }, []);

  const handleSuccess = React.useCallback(() => {
    success("Votre profil a bien été mis à jour");
  }, [success]);

  return (

    <FormPaper<ProfileResponse>
      title="Mes informations"
      icon={<InfoIcon />}

      form={{
        fields,
        initial: initial,
        onSuccess: handleSuccess,
        onPrepareMessage: buildMessage,
      }}
    />
  );
}

function buildFields() {

  const builder = new FormFieldsBuilder<ProfileResponse>(ProfileResponse.Fields);

  builder
    .setFieldProps(ProfileResponse.Fields.lastName, {
      label: "Nom",
      position: 0,
    })
    .setFieldProps(ProfileResponse.Fields.firstName, {
      label: "Prénom",
      position: 1,
    })
    .disabledDefaultAutoFocus()
    ;

  return builder.build();

}
