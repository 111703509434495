import { Guid } from "guid-typescript";
import { IMessage } from "../../../IMessage";

//Generated code, do not modify

export class DeleteEventCoverCommand implements IMessage {
    eventId!: Guid;

    public constructor(init?: Partial<DeleteEventCoverCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "DELETE";

    getUrl = () => `/admin/events/${this.eventId.toString()}/cover`;

    getQueryString = () => undefined;

    getBody = () => undefined;
}