import React from "react";
import { Guid } from "guid-typescript";

import FormDialog from "../../components/FormDialog";
import { FormFieldsBuilder } from "../../components/FormField";

import { ActivityGroupIcon } from "../../App/Theme";
import { ActivityGroupResponse } from "../../models/api/admin/activity-groups/ActivityGroupResponse";
import { CreateActivityGroupCommand } from "../../models/api/admin/activity-groups/CreateActivityGroupCommand";
import { UpdateActivityGroupCommand } from "../../models/api/admin/activity-groups/UpdateActivityGroupCommand";
import useGetActivityGroup from "./useGetActivityGroup";

interface ActivityGroupFormDialogProps {
    open: boolean;
    activityGroupId: Guid;
    onClose: () => void;
    onAfterSave: () => void;
}

export default React.memo(({ open, activityGroupId, onClose, onAfterSave }: ActivityGroupFormDialogProps) => {

    const [loading, activityGroup] = useGetActivityGroup(activityGroupId);

    const submitLabel = React.useMemo(
        () => activityGroupId.isEmpty() ? "Ajouter" : "Editer",
        [activityGroupId]
    );

    const fields = React.useMemo(() => buildFields(), []);

    const buildMessage = React.useCallback((data: ActivityGroupResponse) => {
        return activityGroupId.isEmpty()
            ? new CreateActivityGroupCommand(data)
            : new UpdateActivityGroupCommand(data);
    }, [activityGroupId]);

    return (
        <FormDialog<ActivityGroupResponse>
            title={`${submitLabel} un groupe d'activité`}
            icon={<ActivityGroupIcon />}
            open={open}
            onClose={onClose}
            loading={loading}

            form={{
                submitLabel,
                fields,
                initial: activityGroup,
                onSuccess: onAfterSave,
                onPrepareMessage: buildMessage,
            }}

        />
    );
});

function buildFields() {

    const builder = new FormFieldsBuilder<ActivityGroupResponse>(ActivityGroupResponse.Fields);

    builder
        .hiddenField(ActivityGroupResponse.Fields.id)
        .setFieldProps(ActivityGroupResponse.Fields.name, {
            label: "nom",
        });

    return builder.build();
}
