import React from "react";
import { Guid } from "guid-typescript";

import { EventOverviewResponse } from "../../../models/api/admin/events/overview/EventOverviewResponse";
import { UpdateEventUrlsCommand } from "../../../models/api/admin/events/urls/UpdateEventUrlsCommand";
import { ContactIcon } from "../../../App/Theme";
import { FormFieldsBuilder } from "../../../components/FormField";
import { useNotificationContext } from "../../../components/Notification";
import FormPaper from "../../../components/FormPaper";
import { IField } from "../../../models/api/IField";

class UpdateEventUrlsResponse {
    id!: Guid;
    facebookUrl!: string;
    officialUrl!: string;
    busUrl!: string;
    contactEmail!: string;

    public static Fields = {
        id: { name: "id", type: "string" },
        facebookUrl: { name: "facebookUrl", type: "string" },
        officialUrl: { name: "officialUrl", type: "string" },
        busUrl: { name: "busUrl", type: "string" },
        contactEmail: { name: "contactEmail", type: "string" },
    } as Record<"id" | "facebookUrl" | "officialUrl" | "busUrl" | "contactEmail", IField>;
}

function emptyResponseBuilder(overview: EventOverviewResponse) {
    let response = new UpdateEventUrlsResponse();
    response.id = overview.id;
    response.facebookUrl = overview.facebookUrl ? overview.facebookUrl : "";
    response.officialUrl = overview.officialUrl ? overview.officialUrl : "";
    response.busUrl = overview.busUrl ? overview.busUrl : "";
    response.contactEmail = overview.contactEmail ? overview.contactEmail : "";
    return response;
}

export interface InformationsProps {
    loading: boolean,
    event: EventOverviewResponse,
}

export default function Informations({ loading, event }: InformationsProps) {

    const { success } = useNotificationContext();

    const title = loading ? "Chargement de l'évènement ..." : "Contact & informations";
    const fields = React.useMemo(() => buildFields(), []);
    const [initial, setInitial] = React.useState(emptyResponseBuilder(event));

    React.useEffect(() => {
        setInitial(emptyResponseBuilder(event));
    }, [event]);

    const buildMessage = React.useCallback((data: UpdateEventUrlsResponse) => {
        return new UpdateEventUrlsCommand(data);
    }, []);

    const handleSuccess = React.useCallback(() => {
        success("Informations mise à jour");
    }, [success]);

    return (
        <FormPaper<UpdateEventUrlsResponse>
            title={title}
            icon={<ContactIcon />}

            form={{
                fields,
                initial,
                onSuccess: handleSuccess,
                onPrepareMessage: buildMessage,
            }}

        />
    );
}

function buildFields() {

    const builder = new FormFieldsBuilder<UpdateEventUrlsResponse>(UpdateEventUrlsResponse.Fields);

    builder
        .hiddenField(UpdateEventUrlsResponse.Fields.id)
        .setFieldProps(UpdateEventUrlsResponse.Fields.contactEmail, {
            position: 0,
            type: "email",
            label: "Contact email",
            required: false,
            margin: "dense",
        })
        .setFieldProps(UpdateEventUrlsResponse.Fields.officialUrl, {
            label: "Official URL",
            placeholder: "ex : https://www.daxlaferia.fr",
            required: false,
            margin: "dense",
        })
        .setFieldProps(UpdateEventUrlsResponse.Fields.facebookUrl, {
            label: "Facebook URL",
            placeholder: "ex : https://www.facebook.com/events/802418699917470/",
            required: false,
            margin: "dense",
        })
        .setFieldProps(UpdateEventUrlsResponse.Fields.busUrl, {
            label: "Bus URL",
            placeholder: "ex : https://www.lesbusdesferias.com/dax-2019.html",
            required: false,
            margin: "dense",
        })
        .disabledDefaultAutoFocus()
        ;

    return builder.build();

}
