import { Guid } from "guid-typescript";
import { IField } from "../../IField";

//Generated code, do not modify

export class ActivityTypeItemResponse {
    id!: Guid;
    name!: string;
    activityGroupId!: Guid;
    activityGroupName!: string;
    filterable!: boolean;
    isDefaultSelected!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        activityGroupId: { name: "activityGroupId", type: "Guid" },
        activityGroupName: { name: "activityGroupName", type: "string" },
        filterable: { name: "filterable", type: "boolean" },
        isDefaultSelected: { name: "isDefaultSelected", type: "boolean" },
    } as Record<"id" | "name" | "activityGroupId" | "activityGroupName" | "filterable" | "isDefaultSelected", IField>;
}
