import { Guid } from "guid-typescript";
import { IField } from "../../IField";

//Generated code, do not modify

export class TownResponse {
    id!: Guid;
    departmentId!: Guid;
    code!: string;
    name!: string;
    slug!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        departmentId: { name: "departmentId", type: "Guid" },
        code: { name: "code", type: "string" },
        name: { name: "name", type: "string" },
        slug: { name: "slug", type: "string" },
    } as Record<"id" | "departmentId" | "code" | "name" | "slug", IField>;
}
