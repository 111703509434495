import React from "react";
import { Guid } from "guid-typescript";

import FormDialog from "../../components/FormDialog";

import { DepartmentIcon } from "../../App/Theme";
import { DepartmentResponse } from "../../models/api/admin/departments/DepartmentResponse";
import { CreateDepartmentCommand } from "../../models/api/admin/departments/CreateDepartmentCommand";
import { UpdateDepartmentCommand } from "../../models/api/admin/departments/UpdateDepartmentCommand";
import useGetDepartment from "./useGetDepartment";
import { FormFieldsBuilder } from "../../components/FormField";

interface DepartmentFormDialogProps {
    open: boolean;
    departmentId: Guid;
    onClose: () => void;
    onAfterSave: () => void;
}

export default React.memo(({ open, departmentId, onClose, onAfterSave }: DepartmentFormDialogProps) => {

    const [loading, department] = useGetDepartment(departmentId);

    const submitLabel = React.useMemo(
        () => departmentId.isEmpty() ? "Ajouter" : "Editer",
        [departmentId]
    );

    const fields = React.useMemo(() => buildFields(), []);

    const buildMessage = React.useCallback((data: DepartmentResponse) => {
        return departmentId.isEmpty()
            ? new CreateDepartmentCommand(data)
            : new UpdateDepartmentCommand(data);
    }, [departmentId]);

    return (
        <FormDialog<DepartmentResponse>
            title={`${submitLabel} un département`}
            icon={<DepartmentIcon />}
            open={open}
            onClose={onClose}
            loading={loading}

            form={{
                submitLabel,
                fields,
                initial: department,
                onSuccess: onAfterSave,
                onPrepareMessage: buildMessage,
            }}
        />
    );
});

function buildFields() {

    const builder = new FormFieldsBuilder<DepartmentResponse>(DepartmentResponse.Fields);

    builder
        .hiddenField(DepartmentResponse.Fields.id)
        .attachFieldToSlug(
            DepartmentResponse.Fields.name,
            DepartmentResponse.Fields.slug
        )
        .setFieldProps(DepartmentResponse.Fields.name, {
            label: "nom",
            position: 0,
        })
        .setFieldProps(DepartmentResponse.Fields.slug, {
            position: 1,
        })
        .setFieldProps(DepartmentResponse.Fields.code, {
            type: "number",
            label: "code (à 2 chiffres)",
            placeholder: "exemple : 40"
        });

    return builder.build();
}
