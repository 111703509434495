import React from "react";
import { Guid } from "guid-typescript";

import FormDialog from "../../components/FormDialog";
import { FormFieldsBuilder } from "../../components/FormField";

import { ActivityTypeIcon } from "../../App/Theme";
import useGetActivityType from "./useGetActivityType";
import { ActivityTypeResponse } from "../../models/api/admin/activity-types/ActivityTypeResponse";
import { CreateActivityTypeCommand } from "../../models/api/admin/activity-types/CreateActivityTypeCommand";
import { UpdateActivityTypeCommand } from "../../models/api/admin/activity-types/UpdateActivityTypeCommand";
import { useListActivityGroups } from "../ActivityGroup";
import { ActivityGroupItemResponse } from "../../models/api/admin/activity-groups/ActivityGroupItemResponse";

interface ActivityTypeFromDialogProps {
    open: boolean;
    activityTypeId: Guid;
    onClose: () => void;
    onAfterSave: () => void;
}

export default React.memo(({ open, activityTypeId, onClose, onAfterSave }: ActivityTypeFromDialogProps) => {

    const [activityGroupsLoading, activityGroups] = useListActivityGroups();

    const [loading, activityType] = useGetActivityType(activityTypeId, activityGroups);

    const submitLabel = React.useMemo(
        () => activityTypeId.isEmpty() ? "Ajouter" : "Editer",
        [activityTypeId]
    );

    const fields = React.useMemo(() => {
        return buildFields(activityGroupsLoading ? [] : activityGroups)
    }, [activityGroupsLoading, activityGroups]);

    const buildMessage = React.useCallback((data: ActivityTypeResponse) => {
        return activityTypeId.isEmpty()
            ? new CreateActivityTypeCommand(data)
            : new UpdateActivityTypeCommand(data);
    }, [activityTypeId]);

    return (
        <FormDialog<ActivityTypeResponse>
            title={`${submitLabel} une type d'activité`}
            icon={<ActivityTypeIcon />}
            open={open}
            onClose={onClose}
            loading={loading}

            form={{
                submitLabel,
                fields,
                initial: activityType,
                onSuccess: onAfterSave,
                onPrepareMessage: buildMessage,
            }}

        />
    );
});

function buildFields(activityGroups: ActivityGroupItemResponse[]) {

    const builder = new FormFieldsBuilder<ActivityTypeResponse>(ActivityTypeResponse.Fields);

    const activityGroupsOptions = activityGroups.map(ag => {
        return {
            label: ag.name,
            value: ag.id.toString(),
        }
    });

    builder
        .hiddenField(ActivityTypeResponse.Fields.id)
        .setFieldProps(ActivityTypeResponse.Fields.name, {
            label: "nom",
            position: 0,
        })
        .setFieldProps(ActivityTypeResponse.Fields.activityGroupId, {
            position: 2,
            label: "groupe",
            type: "autocomplete",
            noOptionsText: "aucun groupe d'activité trouvé",
            options: activityGroupsOptions,
        })
        .setFieldProps(ActivityTypeResponse.Fields.filterable, {
            label: "filtrable depuis la timeline ?",
            onChange: (d: ActivityTypeResponse) => {
                d.isDefaultSelected = false;
                return d;
            }
        })
        .setFieldProps(ActivityTypeResponse.Fields.isDefaultSelected, {
            label: "filtre activé par défaut ?",
            placeholder: "(nécessite le filtrage depuis la timeline activé)",
            onChange: (d: ActivityTypeResponse) => {
                d.filterable = d.isDefaultSelected ? true : d.filterable;
                return d;
            }
        })
        ;

    return builder.build();
}
