import React from "react";
import { Guid } from "guid-typescript";

import { useFetchDataById } from "../../tools/fetchRequest";
import { GetActivityQuery } from "../../models/api/admin/activities/GetActivityQuery";
import { ActivityResponse } from "../../models/api/admin/activities/ActivityResponse";
import { ActivityTypeItemResponse } from "../../models/api/admin/activity-types/ActivityTypeItemResponse";

const getEventDayQueryBuilder = (id: Guid) => new GetActivityQuery({ id });

const useGetActivity = (id: Guid, activityTypes: ActivityTypeItemResponse[]) => {

    const emptyActivityBuilder = React.useCallback(() => {
        let entity = new ActivityResponse();
        entity.id = Guid.createEmpty();
        entity.name = "";
        entity.activityTypeId = activityTypes.length > 0
            ? activityTypes[0].id
            : Guid.createEmpty();
        entity.description = "";
        entity.isArchived = false;
        entity.time = "12:00";
        return entity;
    }, [activityTypes]);

    return useFetchDataById(
        id,
        getEventDayQueryBuilder,
        emptyActivityBuilder
    );
};

export default useGetActivity;