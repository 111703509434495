import { IField } from "../../models/api/IField";
import { slugify } from "../../tools/StringExtension";
import IFieldBaseProps from "./IFieldBaseProps";
import { SelectFieldProps } from "./SelectField";
import { DateFieldProps } from "./DateField";
import { TimeFieldProps } from "./TimeField";
import { TimesFieldProps } from "./TimesField";
import { AutoCompleteFieldProps } from "./AutoCompleteField";
import { TextFieldProps } from "./TextField";
import { FormError } from "../../models/response";

export interface FieldRenderProps<T> {
    fieldProps: IFieldBaseProps;
    data: T;
    errors: FormError[];
}

export interface IFormFieldProps<T> extends IFieldBaseProps {
    hidden: boolean;
    position: number;

    render?: (renderProps: FieldRenderProps<T>) => JSX.Element;
    onChange?: (d: T) => T;
    autoClearAdornment?: TextFieldProps["autoClearAdornment"];
    options?: SelectFieldProps["options"] | AutoCompleteFieldProps["options"];
    format?: DateFieldProps["format"];
    minDate?: DateFieldProps["minDate"];
    maxDate?: DateFieldProps["maxDate"];
    minutesStep?: TimeFieldProps["minutesStep"] | TimesFieldProps["minutesStep"]
    groupBy?: AutoCompleteFieldProps["groupBy"];
    noOptionsText?: AutoCompleteFieldProps["noOptionsText"];
    startAdornment?: React.ReactNode;
}

export type IFormFieldOptions = IFormFieldProps<any>;

export default class FormFieldsBuilder<T> {

    fieldNames: string[];
    columns: IFormFieldOptions[];
    defaultAutoFocus: boolean;

    constructor(entityFields: Record<string, IField>) {
        this.columns = [];
        this.fieldNames = Object.keys(entityFields);
        this.defaultAutoFocus = true;

        this.fieldNames.forEach(k => {
            const f = entityFields[k];
            this.columns.push({
                fieldName: f.name,
                type: f.type,
                label: f.name,
                hidden: false,
                position: 500,
            });
        });
    }

    setFieldProps<P extends Partial<IFormFieldProps<T>>>(field: IField, props: P): FormFieldsBuilder<T> {
        const idx = this.fieldNames.findIndex(x => x === field.name);
        if (idx !== -1) {
            this.columns[idx] = { ...this.columns[idx], ...props };
        }
        return this;
    }

    hiddenField(field: IField): FormFieldsBuilder<T> {
        this.setFieldProps(field, { hidden: true });
        return this;
    }

    attachFieldToSlug(field: IField, slugField: IField): FormFieldsBuilder<T> {
        this.setFieldProps(field, {
            onChange: (item: any) => {
                item[slugField.name] = slugify(item[field.name]);
                return item;
            }
        });
        this.setFieldProps(slugField, {
            disabled: true,
        })
        return this;
    }

    addCustomField<P extends Partial<IFormFieldProps<T>>>(props: P): FormFieldsBuilder<T> {
        this.columns.push({
            fieldName: "customField",
            type: "customType",
            hidden: false,
            position: 500,
            ...props
        })
        return this;
    }

    disabledDefaultAutoFocus(): FormFieldsBuilder<T> {
        this.defaultAutoFocus = false;
        return this;
    }

    build(): IFormFieldOptions[] {
        let fields = this.columns
            .filter(c => c.hidden !== true)
            .sort((a, b) => this.compare(a.position, b.position));
        if (this.defaultAutoFocus) {
            const autoFocusField = fields.find(x => x.autoFocus);
            if (autoFocusField === undefined) {
                fields[0].autoFocus = true;
            }
        }
        return fields;
    }

    public compare(a: number | undefined, b: number | undefined) {
        if (a === undefined && b === undefined) {
            return 1;
        }
        else if (a === undefined && b !== undefined) {
            return 1;
        }
        else if (a !== undefined && b === undefined) {
            return -1;
        }
        else {
            return (a as number) - (b as number);
        }
    }
}