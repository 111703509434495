import React from "react";
import { Guid } from "guid-typescript";

import FormDialog from "../../components/FormDialog";
import { FormFieldsBuilder } from "../../components/FormField";

import { EventIcon } from "../../App/Theme";
import useGetEvent from "./useGetEvent";
import { EventResponse } from "../../models/api/admin/events/EventResponse";
import { CreateEventCommand } from "../../models/api/admin/events/CreateEventCommand";
import { UpdateEventCommand } from "../../models/api/admin/events/UpdateEventCommand";
import { useSearchTowns } from "../Town";
import { useListEventTypes } from "../EventType";
import { TownItemResponse } from "../../models/api/admin/towns/ODataResponse[TownItemResponse]";
import { EventTypeItemResponse } from "../../models/api/admin/event-types/EventTypeItemResponse";
import { toUtcDate, lastDayOfYear, format } from "../../tools/DateExtension";

interface EventFormDialogProps {
    open: boolean;
    eventId: Guid;
    onClose: () => void;
    onAfterSave: () => void;
}

// TODO
// Quand on ajoute créer un évènement, possibilité de redéfinir le type 
// d'event à la volée (cf ce qu'on a fait pour créer une activité avec 
// son type d'activité qui peut être redéfinie à la volée)
// > Cela permettra d'aléger la liste des types d'event pour que ce soit plus lisible ...

export default React.memo(({ open, eventId, onClose, onAfterSave }: EventFormDialogProps) => {

    const [townsLoading, towns] = useSearchTowns();
    const [eventTypesLoading, eventTypes] = useListEventTypes();

    const [loading, event] = useGetEvent(eventId, towns, eventTypes);

    const submitLabel = React.useMemo(
        () => eventId.isEmpty() ? "Ajouter" : "Editer",
        [eventId]
    );

    const fields = React.useMemo(() => {
        const bTowns = townsLoading ? [] : towns;
        const bEventTypes = eventTypesLoading ? [] : eventTypes;
        return buildFields(bTowns, bEventTypes);
    }, [townsLoading, towns, eventTypesLoading, eventTypes]);

    const buildMessage = React.useCallback((data: EventResponse) => {
        return eventId.isEmpty()
            ? new CreateEventCommand(data)
            : new UpdateEventCommand(data);
    }, [eventId]);

    return (
        <FormDialog<EventResponse>
            title={`${submitLabel} un évènement`}
            icon={<EventIcon />}
            open={open}
            onClose={onClose}
            loading={loading}

            form={{
                submitLabel,
                fields,
                initial: event,
                onSuccess: onAfterSave,
                onPrepareMessage: buildMessage,
            }}
        />
    );
});

function buildFields(towns: TownItemResponse[], eventTypes: EventTypeItemResponse[]) {

    const builder = new FormFieldsBuilder<EventResponse>(EventResponse.Fields);

    const townOptions = towns.map(d => {
        return {
            label: `${d.code} - ${d.name}`,
            value: d.id.toString(),
        }
    });

    const eventTypeOptions = eventTypes.map(et => {
        return {
            label: et.name,
            value: et.id.toString(),
        }
    });

    builder
        .hiddenField(EventResponse.Fields.id)
        .setFieldProps(EventResponse.Fields.townId, {
            position: 1,
            label: "ville",
            type: "autocomplete",
            noOptionsText: "aucune ville trouvée",
            options: townOptions,
        })
        .setFieldProps(EventResponse.Fields.eventTypeId, {
            position: 1,
            label: "type d'évènement",
            type: "autocomplete",
            noOptionsText: "aucun type d'évènement trouvé",
            options: eventTypeOptions,
        })
        .setFieldProps(EventResponse.Fields.startAt, {
            label: "du",
            minDate: new Date(),
            maxDate: toUtcDate(lastDayOfYear()),
        })
        .setFieldProps(EventResponse.Fields.endAt, {
            label: "au",
            minDate: new Date(),
            maxDate: toUtcDate(lastDayOfYear()),
        })
        .setFieldProps(EventResponse.Fields.areDatesValidated, {
            label: "dates valides ?",
            placeholder: "(les dates ne pourront être modifiées ultérieurement)",
        })
        .addCustomField({
            render: ({ data: event }) => {
                let query = "fetes+2020";
                const town = towns.find(x => x.id.toString() === event.townId.toString());
                const eventType = eventTypes.find(x => x.id.toString() === event.eventTypeId.toString());
                if (town && eventType) {
                    query = `fetes+${town.name}+${eventType.name}+${format(event.startAt, "YYYY")}`
                }
                return (
                    <div style={{ marginTop: 8 }}>
                        <a
                            href={`https://www.google.com/search?q=${query}&oq=${query}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "inherit", textDecoration: "underline" }}
                        >
                            >>> Recherche google
                    </a>
                    </div>
                );
            }
        })
        ;

    return builder.build();
}
