import React from "react";
import { Guid } from "guid-typescript";

import { IconButton, Tooltip, Typography, CircularProgress } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme, darken } from "@material-ui/core/styles";

import { Activity } from "../../../models/api/admin/event-days/program/EventDayProgramResponse";
import Paper from "../../../components/Paper";
import { ActivityIcon, EditIcon, ArchiveIcon, AddImageIcon, UnarchiveIcon } from "../../../App/Theme";
import ReactMarkdown from "react-markdown";
import { ArchiveActivityCommand } from "../../../models/api/admin/activities/archive/ArchiveActivityCommand";
import { UnarchiveActivityCommand } from "../../../models/api/admin/activities/unarchive/UnarchiveActivityCommand";
import { fetchRequest } from "../../../tools/fetchRequest";
import { useNotificationContext } from "../../../components/Notification";
import ImageSelector from "../../../components/ImageSelector";
import readFileContent from "../../../tools/readFileContent";
import { CreateActivityCoverCommand } from "../../../models/api/admin/activities/cover/CreateActivityCoverCommand";
import { DeleteActivityCoverCommand } from "../../../models/api/admin/activities/cover/DeleteActivityCoverCommand";

const useStyles = makeStyles((theme: Theme) => createStyles({
    disabledPaper: {
        backgroundColor: darken(theme.palette.background.paper, 0.2),
    },
    actions: {
        position: "absolute",
        top: theme.spacing(1) * 2,
        right: theme.spacing(1) * 2,
        "& button": {
            marginLeft: theme.spacing(1),
        }
    },
    subtitle: {
        marginTop: theme.spacing(1) * -2,
    },
    description: {
        marginTop: theme.spacing(1),
        paddingLeft: theme.spacing(1) * 2,
        paddingTop: theme.spacing(1) * 0.5,
        paddingBottom: theme.spacing(1) * 0.5,
        borderLeftWidth: 5,
        borderLeftColor: theme.palette.background.default,
        borderLeftStyle: "solid",
        "& p": {
            marginTop: theme.spacing(1) * 0.5,
            marginBottom: theme.spacing(1) * 0.5,
        },
        "& a": {
            color: theme.palette.text.hint,
            textDecoration: "underline",
        }
    },
}));

export interface ActivityCardProps {
    activity: Activity;
    onEdit: (id: Guid) => void;
    reloadProgram?: () => void;
}

function ActivityCard({ activity, onEdit, reloadProgram }: ActivityCardProps) {

    const classes = useStyles();

    const { success, error } = useNotificationContext();
    const [archiveLoading, setArchiveLoading] = React.useState(false);
    const [coverLoading, setCoverLoading] = React.useState(false);

    const activityName = React.useMemo(() => {
        return activity.name ? activity.name : activity.activityTypeName
    }, [activity]);

    const imageRef = React.useRef<HTMLInputElement>(null);

    const handleArchive = React.useCallback(async () => {
        try {
            setArchiveLoading(true);
            const command = new ArchiveActivityCommand({ id: activity.id });
            await fetchRequest(command);
            setArchiveLoading(false);
            success("L'activité a bien été archivée");
            reloadProgram && reloadProgram();
        }
        catch (err) {
            setArchiveLoading(false);
            error("L'activité n'a pas pu être archivée, veuillez réessayer.");
        }
    }, [activity, success, error, reloadProgram]);

    const handleUnarchive = React.useCallback(async () => {
        try {
            setArchiveLoading(true);
            const command = new UnarchiveActivityCommand({ id: activity.id });
            await fetchRequest(command);
            setArchiveLoading(false);
            success("L'activité a bien été désarchivée");
            reloadProgram && reloadProgram();
        }
        catch (err) {
            setArchiveLoading(false);
            error("L'activité n'a pas pu être désarchivée, veuillez réessayer.");
        }
    }, [activity, success, error, reloadProgram]);

    const handleUpdateCover = React.useCallback((file: File) => {
        readFileContent(file, async (content) => {
            try {
                setCoverLoading(true);
                if (content !== null) {
                    const command = new CreateActivityCoverCommand({
                        activityId: activity.id,
                        mimeType: file.type,
                        content: content.split("base64,")[1],
                    });
                    await fetchRequest(command);
                    setCoverLoading(false);
                    reloadProgram && reloadProgram();
                    success("La couverture a bien été mise à jour");
                }
                else {
                    throw new Error("unreadable file content");
                }
            }
            catch (err) {
                setCoverLoading(false);
                error("Une erreur est survenue !");
            }
        });
    }, [activity, reloadProgram, success, error]);

    const handleDeleteCover = React.useCallback(async () => {
        try {
            setCoverLoading(true);
            const command = new DeleteActivityCoverCommand({ activityId: activity.id });
            await fetchRequest(command);
            setCoverLoading(false);
            reloadProgram && reloadProgram();
            success("La couverture a bien été supprimée");
        }
        catch (err) {
            setCoverLoading(false);
            error("Une erreur est survenue !");
        }
    }, [activity, reloadProgram, success, error]);

    return (
        <Paper
            name={`${activity.time} - ${activityName}`}
            icon={<ActivityIcon />}
            className={activity.isArchived ? classes.disabledPaper : undefined}
        >
            <span className={classes.actions}>

                {!activity.isArchived &&
                    <Tooltip
                        title="Editer l'activité"
                        placement="bottom"
                    >
                        <IconButton
                            color="primary"
                            onClick={() => onEdit(activity.id)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                }

                {!activity.isArchived &&
                    <Tooltip
                        title={activity.coverId ? "Changer l'image" : "Ajouter une image"}
                        placement="bottom"
                    >
                        <IconButton
                            color="default"
                            onClick={() => {
                                if (imageRef.current) {
                                    imageRef.current.click();
                                }
                            }}
                        >
                            <AddImageIcon />
                        </IconButton>
                    </Tooltip>
                }

                <Tooltip
                    title={(activity.isArchived ? "Désarchiver" : "Archiver") + " l'activité"}
                    placement="bottom"
                >
                    <IconButton
                        color="secondary"
                        onClick={activity.isArchived ? handleUnarchive : handleArchive}
                        disabled={archiveLoading}
                    >
                        {archiveLoading && <CircularProgress size={18} />}
                        {!archiveLoading
                            ? activity.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />
                            : null
                        }
                    </IconButton>
                </Tooltip>

            </span>

            <Typography component="p" variant="body2" className={classes.subtitle}>
                <em>{activity.activityGroupName} ~ {activity.activityTypeName}</em>
            </Typography>

            {activity.description &&
                <Typography component="article" variant="body1" className={classes.description}>
                    <ReactMarkdown source={activity.description} />
                </Typography>
            }


            <ImageSelector
                inputRef={imageRef}
                width={400}
                height={150}
                name="Sélectionner une image de couverture"
                imageId={activity.coverId}
                onChange={handleUpdateCover}
                onDelete={handleDeleteCover}
                deleteLoading={coverLoading}
                hidden={!activity.coverId}
            />

        </Paper>
    );
}

export default ActivityCard;