import React from "react";

import NotificationContext from "./NotificationContext";
import Notification, { NotificationType } from "./Notification";

export interface NotificationContextProviderProps {
    children?: React.ReactNode,
}

export default function NotificationContextProvider({ children }: NotificationContextProviderProps) {

    const [state, setState] =
        React.useState<{ message: string, type: NotificationType } | null>(null);

    const clear = React.useCallback(() => {
        setState(s => null);
    }, []);

    const setNotification = React.useCallback((type: NotificationType, message: string) => {
        clear();
        // use timeout to waiting refresh render after clear
        setTimeout(() => {
            setState(s => { return { type, message } });
        }, 100);
    }, [clear]);

    const info = React.useCallback((message: string) => {
        setNotification("info", message);
    }, [setNotification]);

    const success = React.useCallback((message: string) => {
        setNotification("success", message);
    }, [setNotification]);

    const warning = React.useCallback((message: string) => {
        setNotification("warning", message);
    }, [setNotification]);

    const error = React.useCallback((message: string) => {
        setNotification("error", message);
    }, [setNotification]);

    return (
        <NotificationContext.Provider
            value={{ info, success, warning, error, clear }}
        >
            {children}
            {state &&
                <Notification
                    open={true}
                    onClose={clear}
                    autoHideDuration={3000}
                    {...state}
                />
            }
        </NotificationContext.Provider>
    );
}
