import React from "react";
// import jwtDecode from "jwt-decode";

import AuthContext, { IAuthPayload } from "./AuthContext";
import useAuthToken from "./useAuthToken";

export interface AuthContextProviderProps {
    children?: React.ReactNode,
}

export default function AuthContextProvider({ children }: AuthContextProviderProps) {

    const [token, setToken] = useAuthToken();
    const [state, setState] = React.useState<{ isLogged: boolean, payload: IAuthPayload | null }>({
        isLogged: true, // prevent protected page changed first render
        payload: null,
    });

    React.useEffect(() => {
        setState({
            isLogged: token !== null,
            // payload: token !== null ? jwtDecode(token) : null, // TODO : use token payload when api token done !
            payload: token !== null ? { firstName: "John", lastName: "Doe" } : null,
        });
    }, [token]);

    return (
        <AuthContext.Provider value={{ setToken, ...state }}>
            {children}
        </AuthContext.Provider>
    );
}
