import React from "react";

import {
    FormControl, FormControlLabel, Switch, Typography, FormHelperText,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import IFieldBaseProps, { useFormField } from "./IFieldBaseProps";
import { FormError } from "../../models/response";
import { capitalize } from "../../tools/StringExtension";


const useStyles = makeStyles((theme: Theme) => createStyles({
    labelContainer: {
        marginLeft: 0,
        marginRight: "auto",
    },
    placeholder: {
        display: "block",
    }
}));

export interface SwitchFieldProps extends IFieldBaseProps {
    data: any;
    errors?: FormError[];
    onChange?: (fieldName: string, flag: boolean) => void;
}

function SwitchField(inProps: SwitchFieldProps) {

    const classes = useStyles();

    const [props, value, error] =
        useFormField<SwitchFieldProps, boolean>(inProps, inProps.data, inProps.errors);

    return (
        <FormControl
            required={props.required}
            fullWidth={props.fullWidth}
            margin={props.margin}
            color={props.color}
            error={error !== undefined}
        >
            <FormControlLabel
                disabled={props.disabled}
                control={
                    <Switch
                        checked={value}
                        color={props.color}
                        onChange={e => {
                            const value = e.currentTarget.checked as boolean;
                            props.onChange && props.onChange(props.fieldName, value);
                        }}
                    />

                }
                value={props.fieldName}
                label={<>
                    <Typography variant="subtitle1">
                        {props.label ? capitalize(props.label) : ""}{props.required ? " *" : ""}
                    </Typography>
                    {props.placeholder &&
                        <Typography variant="caption" className={classes.placeholder}>
                            {props.placeholder}
                        </Typography>
                    }
                </>}
                labelPlacement="start"
                className={classes.labelContainer}
            />

            {error && <FormHelperText>{error}</FormHelperText>}

        </FormControl>
    )
}

export default SwitchField