import { Guid } from "guid-typescript";
import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class CreateActivityCommand implements IMessage {
    eventDayId!: Guid;
    activityTypeId!: Guid;
    time!: string;
    name!: string;
    description!: string;

    public constructor(init?: Partial<CreateActivityCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST";

    getUrl = () => `/admin/activities`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            eventDayId: this.eventDayId,
            activityTypeId: this.activityTypeId,
            time: this.time,
            name: this.name,
            description: this.description,
        };
    }
}