import React from "react";

import { EventDayProgramResponse } from "../../../models/api/admin/event-days/program/EventDayProgramResponse";
import Paper from "../../../components/Paper";
import { ProgramIcon } from "../../../App/Theme";
import { Typography } from "@material-ui/core";
import { format } from "../../../tools/DateExtension";

export interface HeaderProps {
    loading: boolean;
    eventDayProgram: EventDayProgramResponse;
}

function Header({ loading, eventDayProgram: program }: HeaderProps) {

    const title = loading
        ? "Programme du ..."
        : `Programme du ${format(program.date, "dddd Do MMMM YYYY")}`

    return (
        <Paper
            name={title}
            icon={<ProgramIcon />}
            loading={loading}
        >
            <Typography component="p" variant="subtitle1">
                <em>Nom de la journée : </em>
                {loading && "..."}
                {!loading && program.name ? program.name : "<pas de nom>"}
            </Typography>

            <Typography component="p" variant="subtitle1">
                <em>Heure de fermeture : </em>
                {loading && "..."}
                {!loading && program.endAt}
            </Typography>

            <Typography component="p" variant="subtitle1">
                <em>Horaires des bus : </em>
                {loading && "..."}
                {!loading && program.busTimes.join(", ")}
            </Typography>

        </Paper>
    );
}

export default Header;