import React from "react";

import {
  Dialog, DialogContent, DialogTitle,
  DialogActions, DialogContentText,
  Button
} from "@material-ui/core";

interface FatalErrorDialogProps {
  onReload: () => void;
}

function FatalErrorDialog({ onReload }: FatalErrorDialogProps) {

  return (
    <Dialog open={true}>

      <DialogTitle>
        Une erreur s'est produite :(
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Il semblerait qu'une erreur se soit produite : pas de panique.
          Cela peut être dû à un problème réseau ou une maintenance du serveur.
          Veuillez ré-essayer de recharger la page.
        </DialogContentText>
        <DialogContentText>
          Si l'erreur persiste, merci de contacter l'administrateur du site
          via <a href="mailto:contact@ferias.fr">contact@ferias.fr</a>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onReload}
        >
          Recharger la page
          </Button>
      </DialogActions>

    </Dialog>
  )
}

export default class FatalErrorBoundary extends React.Component {
  state = {
    error: false,
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error: true });
  }

  reloadHandler = () => {
    this.setState({ error: false })
  }

  render() {
    if (this.state.error === true) {
      document.title = "Erreur | Ferias40"
      return <FatalErrorDialog onReload={this.reloadHandler} />
    }

    return this.props.children;
  }
}
