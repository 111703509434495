import { IMessage } from "../../../IMessage";

//Generated code, do not modify

export class CreateEventsForNewYearCommand implements IMessage {
    sourceYear!: number;
    targetYear!: number;

    public constructor(init?: Partial<CreateEventsForNewYearCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST";

    getUrl = () => `/admin/events/new-year`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            sourceYear: this.sourceYear,
            targetYear: this.targetYear,
        };
    }
}