import React from "react";
import { Guid } from "guid-typescript";

import { useFetchDataById } from "../../tools/fetchRequest";
import { GetActivityTypeQuery } from "../../models/api/admin/activity-types/GetActivityTypeQuery";
import { ActivityTypeResponse } from "../../models/api/admin/activity-types/ActivityTypeResponse";
import { ActivityGroupItemResponse } from "../../models/api/admin/activity-groups/ActivityGroupItemResponse";

const getQueryBuilder = (id: Guid) => new GetActivityTypeQuery({ id });

const useGetActivityType = (id: Guid, activityGroups: ActivityGroupItemResponse[] = []) => {

    const emptyBuilder = React.useCallback(() => {
        let entity = new ActivityTypeResponse();
        entity.id = Guid.createEmpty();
        entity.name = "";
        entity.filterable = false;
        entity.isDefaultSelected = false;
        entity.activityGroupId = activityGroups.length > 0
            ? activityGroups[0].id
            : Guid.createEmpty();
        return entity;
    }, [activityGroups]);

    return useFetchDataById(
        id,
        getQueryBuilder,
        emptyBuilder,
    );
};

export default useGetActivityType;