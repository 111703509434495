import React from "react";

import {
    IconButton,
    Menu, MenuItem, ListItemIcon
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/styles";

import { ODataFilter, ODataFilterOperator } from "../../models/api/OData";
import {
    ContainsIcon,
    EqualIcon, NotEqualIcon,
    LessThanIcon, LessThanOrEqualIcon,
    GreaterThanIcon, GreaterThanOrEqualIcon
} from "../../App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    button: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
    },
    item: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    itemIcon: {
        minWidth: theme.spacing(3),
        maxWidth: theme.spacing(3),
    }
}));

const OPERATORS_ICON = {
    contains: <ContainsIcon />,
    eq: <EqualIcon />,
    ne: <NotEqualIcon />,
    lt: <LessThanIcon />,
    le: <LessThanOrEqualIcon />,
    gt: <GreaterThanIcon />,
    ge: <GreaterThanOrEqualIcon />,
};

export interface CellFilterOperatorProps<T> {
    fieldName: string;
    operators: ODataFilterOperator[];
    filter: ODataFilter;
    onChange: (fieldName: string, operator: ODataFilterOperator) => void;
}

export default function CellFilterOperator<T>({ fieldName, operators, filter, onChange }: CellFilterOperatorProps<T>) {

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleOpenMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }, []);

    const handleCloseMenu = React.useCallback(() => setAnchorEl(null), []);

    const handleOperatorChange = React.useCallback((_fieldName: string, _operator: ODataFilterOperator) => {
        onChange(_fieldName, _operator);
        handleCloseMenu();
    }, [handleCloseMenu, onChange]);

    return (
        <>
            <IconButton
                size="small"
                aria-controls="filter-operator"
                className={classes.button}
                onClick={handleOpenMenu}
            >

                {OPERATORS_ICON[filter.operator]}

            </IconButton>

            <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                keepMounted={true}
                onClose={handleCloseMenu}
            >
                {operators.map((op, idx) => {
                    return (
                        <MenuItem
                            key={idx}
                            selected={filter.operator === op}
                            className={classes.item}
                            onClick={() => handleOperatorChange(fieldName, op)}
                        >
                            <ListItemIcon className={classes.itemIcon}>
                                {OPERATORS_ICON[op]}
                            </ListItemIcon>
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};
