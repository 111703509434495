import { IMessage } from "../../../IMessage";

//Generated code, do not modify

export class ResetUserPasswordCommand implements IMessage {
    token!: string;
    password!: string;
    passwordConfirmation!: string;

    public constructor(init?: Partial<ResetUserPasswordCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT";

    getUrl = () => `/admin/users/password`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            token: this.token,
            password: this.password,
            passwordConfirmation: this.passwordConfirmation,
        };
    }
}