import React from "react";
import clsx from "clsx";

import { SvgIconProps, Button, CircularProgress } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { FormError } from "../../models/response";
import { fetchRequest } from "../../tools/fetchRequest";
import FormField, { IFormFieldOptions } from "../FormField";

const useStyles = makeStyles((theme: Theme) => createStyles({
    row: {
        display: "flex",
        flexDirection: "row",
    },
    submitBtn: {
        marginTop: theme.spacing(1) * 2,
        marginLeft: "auto",
    },
    hidden: {
        display: "none",
    },
    errorContainer: {
        display: "flex",
        justifyContent: "center",
    },
    errorChip: {
        textAlign: "center",
        padding: theme.spacing(0.5, 2),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        borderRadius: theme.shape.borderRadius,
    }
}));

export interface FormProps<T, R = any> {
    initial: T;
    fields: IFormFieldOptions[];
    onPrepareMessage: (model: T) => any;

    isSubmit?: boolean;
    submitLabel?: string;
    submitIcon?: React.ReactElement<SvgIconProps>;
    submitRef?: React.RefObject<HTMLButtonElement>;
    submitClassname?: string;
    onSuccess?: (response: R) => void;
    onError?: (error: any) => void;
}

export default function Form<T, R = any>({
    initial, fields,
    onPrepareMessage, onSuccess, onError,
    isSubmit, submitRef,
    submitLabel, submitIcon, submitClassname,
}: FormProps<T, R>) {

    const classes = useStyles();

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [fieldsErrors, setFieldsErrors] = React.useState<FormError[]>([]);
    const [data, setData] = React.useState<any>(initial);

    React.useEffect(() => {
        setError(null);
        setFieldsErrors([]);
        setLoading(false);
        setData(initial);
    }, [initial, setData]);

    const handleChange = React.useCallback((fieldName: string, value: any) => {
        setData((d: any) => {
            let dd = { ...d, [fieldName]: value };
            const field = fields.find(x => x.fieldName === fieldName);
            return field && field.onChange ? field.onChange(dd) : dd;
        });
    }, [fields]);

    const handleSubmit = React.useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setLoading(true);
        setError(null);
        setFieldsErrors([]);

        try {
            const message = onPrepareMessage(data);
            var response = await fetchRequest(message) as R;
            setLoading(false);
            onSuccess && onSuccess(response);
        }
        catch (err) {
            setLoading(false);

            onError && onError(err);

            if (err && err.response
                && err.response.data
                && err.response.status === 417) {

                const errData = err.response.data;

                if (Array.isArray(errData)) {
                    const formErrors = errData as FormError[];
                    setFieldsErrors(formErrors);
                }
                else if (errData["message"]) {
                    setError(errData["message"]);
                }
                else {
                    console.error("undefined error : ", errData);
                }
            }
            else {
                throw err;
            }
        }

    }, [data, onPrepareMessage, onError, onSuccess]);

    return (
        <form onSubmit={handleSubmit}>

            {error &&
                <div className={classes.errorContainer}>
                    <p className={classes.errorChip}>
                        {error}
                    </p>
                </div>
            }

            {fields.map((field, idx) =>
                <FormField
                    key={idx}
                    field={field}
                    data={data}
                    errors={fieldsErrors}
                    onChange={handleChange}
                />
            )}

            <div className={clsx(classes.row, isSubmit === false ? classes.hidden : undefined)}>
                <Button
                    color="secondary"
                    variant="contained"
                    type="submit"
                    disabled={loading}
                    ref={submitRef}
                    className={clsx(classes.submitBtn, submitClassname)}
                    endIcon={loading && submitIcon
                        ? <CircularProgress size={18} />
                        : (!loading && submitIcon ? submitIcon : undefined)
                    }
                >
                    {submitLabel ? submitLabel : "Sauvegarder"}
                </Button>
            </div>

        </form>
    );
}
