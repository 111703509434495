import React from "react";
import { Guid } from "guid-typescript";

import Paper from "../../components/Paper";
import CornerButton from "../../components/CornerButton";
import { CentralColumnLayout } from "../../components/Layouts";
import { AddIcon, EventIcon } from "../../App/Theme";
import useSearchEvents from "./useSearchEvents";
import EventsGrid from "./EventsGrid";
import EventFormDialog from "./EventFormDialog";
import { useStateDelay } from "../../tools/useStateDelay";
import { useNotificationContext } from "../../components/Notification";

import { SearchEventsQuery } from "../../models/api/admin/events/SearchEventsQuery";
import { ODataOrderBy, OrderDirection } from "../../models/api/OData";
import { EventItemResponse } from "../../models/api/admin/events/ODataResponse[EventItemResponse]";

export default function ListEventsPage() {

  document.title = "Liste des évènements - Administration - Ferias40";

  const { success } = useNotificationContext();

  const emptyGuid = Guid.createEmpty();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogEventId, setDialogEventId, setDialogEventIdDelay] = useStateDelay(emptyGuid);

  const query = React.useMemo(() => new SearchEventsQuery({
    top: 10,
    skip: 0,
    orderBy: new ODataOrderBy({
      direction: OrderDirection.Asc,
      member: EventItemResponse.Fields.startAt.name,
    }),
  }), []);
  const [loading, events, eventsCount, updateEvents] = useSearchEvents(query);

  const handleClose = React.useCallback(() => {
    setDialogOpen(false);
    setDialogEventIdDelay(emptyGuid);
  }, [emptyGuid, setDialogEventIdDelay]);

  const handleCreate = React.useCallback(() => {
    setDialogEventId(emptyGuid);
    setDialogOpen(true);
  }, [emptyGuid, setDialogEventId]);

  const handleEdit = React.useCallback((id: Guid) => {
    setDialogEventId(id);
    setDialogOpen(true);
  }, [setDialogEventId]);

  const handleAfterSave = React.useCallback(() => {
    updateEvents();
    success("L'évènement a bien été enregistré");
  }, [updateEvents, success]);

  return (
    <CentralColumnLayout size="xl">

      <CornerButton
        tooltip="Ajouter un évènement"
        icon={<AddIcon />}
        onClick={handleCreate}
      />

      <Paper
        name="Liste des évènements"
        icon={<EventIcon />}
      >
        <EventsGrid
          count={eventsCount}
          events={events}
          loading={loading}
          onEdit={handleEdit}

          query={query}
          fetchQuery={updateEvents}
        />
      </Paper>

      <EventFormDialog
        open={dialogOpen}
        onClose={handleClose}
        eventId={dialogEventId}
        onAfterSave={handleAfterSave}
      />

    </CentralColumnLayout>
  );
}
