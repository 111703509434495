import React from "react";

import {
  Table, TableBody,
  TableCell, TableHead, TableRow, CircularProgress
} from "@material-ui/core";
import { Padding } from "@material-ui/core/Table";
import { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/styles";

import { IGridColumnOptions, IGridColumnProps } from "./types";
import CellHeader from "./CellHeader";
import CellData from "./CellData";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    position: "relative",
  },
  table: {
    "& tbody tr:nth-of-type(even)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  loadingContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    zIndex: theme.zIndex.appBar - 1,
  },
  tableHead: theme.mixins.getTableHeader(theme),
}));

export interface DataGridProps<T> {
  data: T[],
  loading?: boolean,
  padding?: Padding,
  columnsProps: IGridColumnOptions<T>[],
}

export default function DataGrid<T>({ data, padding, loading, columnsProps }: DataGridProps<T>) {

  const nbColumn = React.useMemo(() => columnsProps.length, [columnsProps]);

  const renderHeaderRow = React.useCallback(() => {
    return (
      <TableRow>
        {columnsProps.map((columnProps: IGridColumnProps<T>, idx) => {
          return <CellHeader<T>
            key={idx}
            {...columnProps}
          />
        })}
      </TableRow>
    )
  }, [columnsProps]);

  const renderDataRow = React.useCallback((data: any, key: number) => {
    return (
      <TableRow key={key}>
        {columnsProps.map((columnProps: IGridColumnProps<T>, idx) => {
          return <CellData<T>
            key={key * nbColumn + idx}
            {...columnProps}
            data={data}
          />
        })}
      </TableRow>
    )
  }, [columnsProps, nbColumn]);

  const renderEmptyRow = React.useCallback(() => {
    return (
      <TableRow>
        <TableCell
          colSpan={nbColumn}
          scope="row"
        >
          Aucune données trouvées
        </TableCell>
      </TableRow>
    )
  }, [nbColumn]);

  const classes = useStyles();
  const pPadding = padding ? padding : "default";

  return (
    <div className={classes.container}>
      {loading ? <div className={classes.loadingContainer}><CircularProgress size={28} /></div> : null}

      <Table
        className={classes.table}
        padding={pPadding}
      >

        <TableHead className={classes.tableHead}>
          {renderHeaderRow()}
        </TableHead>

        <TableBody>
          {
            data.length === 0
              ? renderEmptyRow()
              : data.map((d, idx) => renderDataRow(d, idx))
          }
        </TableBody>

      </Table>
    </div>
  )
}
