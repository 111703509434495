import { IMessage } from "../../../IMessage";

//Generated code, do not modify

export class AskPasswordResetQuery implements IMessage {
    email!: string;

    public constructor(init?: Partial<AskPasswordResetQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET";

    getUrl = () => `/admin/users/password`;

    getQueryString = () => {
        return {
            email: this.email,
        }
    }

    getBody = () => undefined;
}