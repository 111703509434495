import React from "react";

import { TableCell } from "@material-ui/core";

import { IGridColumnProps } from "./types";
import { ToggleOnIcon, ToggleOffIcon } from "../../App/Theme";
import { format } from "../../tools/DateExtension";

export interface CellDataProps<T> extends IGridColumnProps<T> {
    data: any;
}

export default function CellData<T>({ data, ...props }: CellDataProps<T>) {

    const type = React.useMemo(() => props.type ? props.type.toLowerCase() : "action", [props]);
    const value = React.useMemo(() => data[props.fieldName], [data, props]);

    const content = React.useMemo(() => {
        if (props.render) {
            return props.render(data);
        }

        if (value === undefined || value === null) {
            return null;
        }
        if (type === "date") {
            const dateFormat = props.format ? props.format : "dddd Do MMMM";
            return format(value, dateFormat);
        }
        if (type === "boolean") {
            return value === true
                ? <ToggleOnIcon color="inherit" titleAccess="Oui" />
                : <ToggleOffIcon titleAccess="Non" color="disabled" />;
        }
        return value.toString();

    }, [data, type, value, props]);

    const title = React.useMemo(() => {
        if (type === "boolean") {
            return value === true ? "oui" : "non"
        }
        if (typeof content === "string") {
            return content;
        }
        return undefined;
    }, [content, type, value]);

    return (
        <TableCell
            align={props.align}
            padding={props.padding}
            scope={props.scope}
            title={title}
        >
            {content}
        </TableCell>
    );
};
