import { Guid } from "guid-typescript";
import { IField } from "../../IField";

//Generated code, do not modify

export class EventDayResponse {
    id!: Guid;
    date!: Date;
    name!: string;
    endAt!: string;
    busTimes!: string[];

    public static Fields = {
        id: { name: "id", type: "Guid" },
        date: { name: "date", type: "Date" },
        name: { name: "name", type: "string" },
        endAt: { name: "endAt", type: "string" },
        busTimes: { name: "busTimes", type: "string[]" },
    } as Record<"id" | "date" | "name" | "endAt" | "busTimes", IField>;
}
