import React from "react";

interface AuthErrorBoundaryProps {
    logout: () => void,
}

class AuthErrorBoundary extends React.Component<AuthErrorBoundaryProps> {
    componentDidCatch(error: any, errorInfo: any) {
        if (error.response && error.response.status === 401) {
            this.props.logout();
        }
        else {
            throw error;
        }
    }

    render() {
        return this.props.children;
    }
}

export default AuthErrorBoundary;