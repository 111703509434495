import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class CreateActivityGroupCommand implements IMessage {
    name!: string;

    public constructor(init?: Partial<CreateActivityGroupCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST";

    getUrl = () => `/admin/activity-groups`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            name: this.name,
        };
    }
}