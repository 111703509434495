import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class SendAdminContactEmailCommand implements IMessage {
    to!: string;
    subject!: string;
    body!: string;

    public constructor(init?: Partial<SendAdminContactEmailCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST";

    getUrl = () => `/admin/contact`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            to: this.to,
            subject: this.subject,
            body: this.body,
        };
    }
}