import React from "react";
import { Guid } from "guid-typescript";

import Paper from "../../components/Paper";
import CornerButton from "../../components/CornerButton";
import { CentralColumnLayout } from "../../components/Layouts";
import { ActivityGroupIcon, AddIcon } from "../../App/Theme";
import ActivityGroupsGrid from "./ActivityGroupsGrid";
import ActivityGroupFormDialog from "./ActivityGroupFormDialog";
import useListActivityGroups from "./useListActivityGroups";
import { useStateDelay } from "../../tools/useStateDelay";
import { useNotificationContext } from "../../components/Notification";

export default function ListActivityGroupsPage() {

  document.title = "Liste des groupes d'activité - Administration - Ferias40"

  const { success } = useNotificationContext();

  const emptyGuid = Guid.createEmpty();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogEntityId, setDialogEntityId, setDialogEntityIdDelay] = useStateDelay(emptyGuid);

  const [loading, activityGroups, updateActivityGroups] = useListActivityGroups();

  const handleClose = React.useCallback(() => {
    setDialogOpen(false);
    setDialogEntityIdDelay(emptyGuid);
  }, [emptyGuid, setDialogEntityIdDelay]);

  const handleCreate = React.useCallback(() => {
    setDialogEntityId(emptyGuid);
    setDialogOpen(true);
  }, [emptyGuid, setDialogEntityId]);

  const handleEdit = React.useCallback((id: Guid) => {
    setDialogEntityId(id);
    setDialogOpen(true);
  }, [setDialogEntityId]);

  const handleAfterSave = React.useCallback(() => {
    updateActivityGroups();
    success("Le groupe d'activité a bien été enregistré");
  }, [updateActivityGroups, success]);

  return (
    <CentralColumnLayout size="sm">

      <CornerButton
        tooltip="Ajouter un groupe d'activité"
        icon={<AddIcon />}
        onClick={handleCreate}
      />

      <Paper
        name="Liste des groupes d'activités"
        icon={<ActivityGroupIcon />}
      >
        <ActivityGroupsGrid
          activityGroups={activityGroups}
          loading={loading}
          onEdit={handleEdit}
        />
      </Paper>

      <ActivityGroupFormDialog
        open={dialogOpen}
        onClose={handleClose}
        activityGroupId={dialogEntityId}
        onAfterSave={handleAfterSave}
      />

    </CentralColumnLayout>
  );
}
