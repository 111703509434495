import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class CreateDepartmentCommand implements IMessage {
    code!: string;
    name!: string;

    public constructor(init?: Partial<CreateDepartmentCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST";

    getUrl = () => `/admin/departments`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            code: this.code,
            name: this.name,
        };
    }
}