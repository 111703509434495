import React from "react";
import { Route as RRoute, RouteComponentProps, Redirect } from "react-router-dom";

import { routeFactory, RouteName } from "../../tools/Route";
import { useAuthContext, AuthErrorBoundary } from "../../tools/Auth";

export interface ProtectedRouteProps {
    name: RouteName;
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

export default function ProtectedRoute({ name, component: Component }: ProtectedRouteProps) {
    const { isLogged, setToken } = useAuthContext();

    const logout = React.useCallback(() => setToken(null), [setToken]);

    return <RRoute
        exact
        path={routeFactory.fullPath(name)}
        render={props => {
            if (isLogged) {
                return (
                    <AuthErrorBoundary logout={logout}>
                        <Component {...props} />
                    </AuthErrorBoundary>
                );
            }
            else {
                return <Redirect to={routeFactory.fullPath(RouteName.Login)} />
            }
        }}
    />
}
