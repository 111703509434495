import React from "react";
import clsx from "clsx";

import {
    Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    withStartIcon: {
        "& span": {
            marginLeft: theme.spacing(1),
        }
    },
    withEndIcon: {
        "& span": {
            marginRight: theme.spacing(1),
        }
    }
}));

export interface TitleProps {
    startIcon?: React.ReactElement<SvgIconProps>;
    title: string;
    endIcon?: React.ReactElement<SvgIconProps>;
    className?: string;
}

export default function Title({ startIcon, title, endIcon, className }: TitleProps) {

    const classes = useStyles();

    const _className = clsx(
        classes.container,
        startIcon ? classes.withStartIcon : undefined,
        endIcon ? classes.withEndIcon : undefined,
        className
    );

    return (
        <Typography
            variant="h6"
            className={_className}
        >
            {startIcon ? startIcon : null}
            <span>{title}</span>
            {endIcon ? endIcon : null}
        </Typography>
    )
}
