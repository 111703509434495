import React from "react";
import { Guid } from "guid-typescript";

import Paper from "../../components/Paper";
import CornerButton from "../../components/CornerButton";
import { CentralColumnLayout } from "../../components/Layouts";
import { EventTypeIcon, AddIcon } from "../../App/Theme";
import EventTypesGrid from "./EventTypesGrid";
import EventTypeFormDialog from "./EventTypeFormDialog";
import useListEventTypes from "./useListEventTypes";
import { useStateDelay } from "../../tools/useStateDelay";
import { useNotificationContext } from "../../components/Notification";

export default function ListEventTypesPage() {

  document.title = "Liste des types d'évènement - Administration - Ferias40";

  const { success } = useNotificationContext();

  const emptyGuid = Guid.createEmpty();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogId, setDialogId, setDialogIdDelay] = useStateDelay(emptyGuid);

  const [loading, eventTypes, updateEventTypes] = useListEventTypes();

  const handleClose = React.useCallback(() => {
    setDialogOpen(false);
    setDialogIdDelay(emptyGuid);
  }, [emptyGuid, setDialogIdDelay]);

  const handleCreate = React.useCallback(() => {
    setDialogId(emptyGuid);
    setDialogOpen(true);
  }, [emptyGuid, setDialogId]);

  const handleEdit = React.useCallback((id: Guid) => {
    setDialogId(id);
    setDialogOpen(true);
  }, [setDialogId]);

  const handleAfterSave = React.useCallback(() => {
    updateEventTypes();
    success("Le type d'évènement a bien été enregistré");
  }, [updateEventTypes, success]);

  return (
    <CentralColumnLayout size="md">

      <CornerButton
        tooltip="Ajouter un type d'évènement"
        icon={<AddIcon />}
        onClick={handleCreate}
      />

      <Paper
        name="Liste des types d'évènement"
        icon={<EventTypeIcon />}
      >
        <EventTypesGrid
          eventTypes={eventTypes}
          loading={loading}
          onEdit={handleEdit}
        />
      </Paper>

      <EventTypeFormDialog
        open={dialogOpen}
        onClose={handleClose}
        eventTypeId={dialogId}
        onAfterSave={handleAfterSave}
      />

    </CentralColumnLayout>
  );
}
