import React from "react";

import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { IconButton } from "@material-ui/core";

import { IGridColumnOptions } from "./types";
import { IField } from "../../models/api/IField";
import { EditIcon } from "../../App/Theme";

const POSITION_MAX = 500;

export default class DataGridColumnsBuilder<T> {

    fieldNames: string[];
    columns: IGridColumnOptions<T>[];

    constructor(entityFields: Record<string, IField>) {
        this.columns = [];
        this.fieldNames = Object.keys(entityFields);

        this.fieldNames.forEach(k => {
            const f = entityFields[k];
            this.columns.push({
                fieldName: f.name,
                type: f.type,
                label: f.name,
                hidden: false,
                position: POSITION_MAX,
            });
        });
    }

    public hiddenField(field: IField): DataGridColumnsBuilder<T> {
        this.setFieldProps(field, { hidden: true });
        return this;
    }

    public setFieldProps<P extends Partial<IGridColumnOptions<T>>>(field: IField, props: P): DataGridColumnsBuilder<T> {
        const idx = this.fieldNames.findIndex(x => x === field.name);
        if (idx !== -1) {
            this.columns[idx] = { ...this.columns[idx], ...props };
        }
        return this;
    }

    public addColumn<P extends Partial<IGridColumnOptions<T>>>(props: P): DataGridColumnsBuilder<T> {
        this.columns.push({
            hidden: false,
            position: POSITION_MAX,
            ...props as IGridColumnOptions<T>
        });
        return this;
    }

    public addAction(icon: React.ReactElement<SvgIconProps>, onAction: (item: T) => void): DataGridColumnsBuilder<T> {
        return this.addColumn({
            hidden: false,
            position: POSITION_MAX,
            type: "action",
            padding: "checkbox",
            render: (item: T) =>
                <IconButton onClick={() => onAction(item)} color="primary">
                    {icon}
                </IconButton>
        });
    }

    public addEditColumn(onEdit: (item: T) => void): DataGridColumnsBuilder<T> {
        this.addAction(<EditIcon />, onEdit);
        return this;
    }

    public build(): IGridColumnOptions<T>[] {
        return this.columns
            .filter(c => c.hidden !== true)
            .sort((a, b) => this.compare(a.position, b.position));
    }

    public compare(a: number | undefined, b: number | undefined) {
        if (a === undefined && b === undefined) {
            return 1;
        }
        else if (a === undefined && b !== undefined) {
            return 1;
        }
        else if (a !== undefined && b === undefined) {
            return -1;
        }
        else {
            return (a as number) - (b as number);
        }
    }
}