import React from "react";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from "@date-io/moment";

import IFieldBaseProps, { useFormField } from "./IFieldBaseProps";
import { FormError } from "../../models/response"
import { capitalize } from "../../tools/StringExtension";
import { timeToDate } from "../../tools/DateExtension";

export interface TimeFieldProps extends IFieldBaseProps {
    data: any;
    errors?: FormError[];
    onChange?: (fieldName: string, time: string) => void;

    minutesStep?: number;
}

function TimeField(inProps: TimeFieldProps) {

    const [props, value, error] =
        useFormField<TimeFieldProps, string>({
            minutesStep: 5,
            ...inProps,
        }, inProps.data, inProps.errors);

    const handleChange = React.useCallback((d: MaterialUiPickersDate) => {
        if (d && props.onChange) {
            const time = d.format("HH:mm");
            props.onChange(props.fieldName, time);
        }
    }, [props]);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <TimePicker
                required={props.required}
                fullWidth={props.fullWidth}
                disabled={props.disabled}
                margin={props.margin}
                autoFocus={props.autoFocus}
                color={props.color}

                ampm={false}
                minutesStep={props.minutesStep}
                label={props.label ? capitalize(props.label) : undefined}
                value={timeToDate(value)}
                onChange={handleChange}
                error={error !== undefined}
                helperText={error}
            />
        </MuiPickersUtilsProvider>
    )

}

export default TimeField