import { Guid } from "guid-typescript";
import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class UpdateActivityCommand implements IMessage {
    id!: Guid;
    activityTypeId!: Guid;
    time!: string;
    name!: string;
    description!: string;

    public constructor(init?: Partial<UpdateActivityCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT";

    getUrl = () => `/admin/activities/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            activityTypeId: this.activityTypeId,
            time: this.time,
            name: this.name,
            description: this.description,
        };
    }
}