import React from "react";

import { fetchRequest } from "../../../tools/fetchRequest";
import { EventOverviewResponse } from "../../../models/api/admin/events/overview/EventOverviewResponse";
import { CreateEventCoverCommand } from "../../../models/api/admin/events/cover/CreateEventCoverCommand";
import { DeleteEventCoverCommand } from "../../../models/api/admin/events/cover/DeleteEventCoverCommand";
import Paper from "../../../components/Paper";
import ImageSelector from "../../../components/ImageSelector";
import { useNotificationContext } from "../../../components/Notification";
import readFileContent from "../../../tools/readFileContent";

export interface CoverProps {
  loading: boolean,
  event: EventOverviewResponse,
  reloadEvent: () => void,
}

export default function Cover({ loading, event, reloadEvent }: CoverProps) {

  const { success, error } = useNotificationContext();
  const [coverLoading, setCoverLoading] = React.useState(false);

  const handleUpdateCover = React.useCallback((file: File) => {
    readFileContent(file, async (content) => {
      try {
        setCoverLoading(true);
        if (content !== null) {
          const command = new CreateEventCoverCommand({
            eventId: event.id,
            mimeType: file.type,
            content: content.split("base64,")[1],
          });
          await fetchRequest(command);
          setCoverLoading(false);
          reloadEvent();
          success("La couverture a bien été mise à jour");
        }
        else {
          throw new Error("unreadable file content");
        }
      }
      catch (err) {
        setCoverLoading(false);
        error("Une erreur est survenue !");
      }
    });
  }, [event, reloadEvent, success, error]);

  const handleDeleteCover = React.useCallback(async () => {
    try {
      setCoverLoading(true);
      const command = new DeleteEventCoverCommand({ eventId: event.id });
      await fetchRequest(command);
      setCoverLoading(false);
      reloadEvent();
      success("La couverture a bien été supprimée");
    }
    catch (err) {
      setCoverLoading(false);
      error("Une erreur est survenue !");
    }
  }, [event, reloadEvent, success, error]);

  let coverId = loading ? null : event.coverId;

  return (
    <Paper
      loading={loading}
    >
      <ImageSelector
        width={400}
        height={150}
        name="Sélectionner une image de couverture"
        imageId={coverId}
        onChange={handleUpdateCover}
        onDelete={handleDeleteCover}
        deleteLoading={coverLoading}
      />
    </Paper>
  );
}
