import React from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { useAuthContext } from "../../tools/Auth";
import { useNotificationContext } from "../../components/Notification";
import { FormFieldsBuilder } from "../../components/FormField";
import { IField } from "../../models/api/IField";
import { AuthenticateUserCommand } from "../../models/api/admin/users/access-token/AuthenticateUserCommand";
import { AccessTokenResponse } from "../../models/api/admin/users/access-token/AccessTokenResponse";
import Form from "../../components/Form";

const useStyles = makeStyles((theme: Theme) => createStyles({
  submit: {
    marginTop: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

class LoginResponse {
  email!: string;
  password!: string;

  public static Fields = {
    email: { name: "email", type: "string" },
    password: { name: "password", type: "string" }
  } as Record<"email" | "password", IField>;
}

function emptyResponseBuilder() {
  let response = new LoginResponse();
  response.email = "";
  response.password = "";
  return response;
}

export default function LoginForm() {

  const classes = useStyles();
  const { success } = useNotificationContext();
  const { setToken } = useAuthContext();

  const fields = React.useMemo(() => buildFields(), []);
  const initial = React.useMemo(() => emptyResponseBuilder(), []);

  const buildMessage = React.useCallback((data: LoginResponse) => {
    return new AuthenticateUserCommand(data);
  }, []);

  const handleSuccess = React.useCallback((response: AccessTokenResponse) => {
    success("Les évènements ont bien été générés.");
    setToken(response.accessToken);
  }, [setToken, success]);

  return (
    <Form<LoginResponse, AccessTokenResponse>
      submitClassname={classes.submit}
      submitLabel="Se connecter"
      fields={fields}
      initial={initial}
      onSuccess={handleSuccess}
      onPrepareMessage={buildMessage}
    />
  )
}

function buildFields() {

  const builder = new FormFieldsBuilder<LoginResponse>(LoginResponse.Fields);

  builder
    .setFieldProps(LoginResponse.Fields.email, {
      label: "Adresse email",
      placeholder: "ex : contact@ferias40.fr",
      type: "email",
    })
    .setFieldProps(LoginResponse.Fields.password, {
      label: "Mot de passe",
      type: "password",
    })
    ;

  return builder.build();
}
