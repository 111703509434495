import React from "react";
import { PropTypes } from "@material-ui/core";
import { FormError } from "../../models/response";

export default interface IFieldBaseProps {
    fieldName: string;
    type?: string;

    color?: "primary" | "secondary";
    autoComplete?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    label?: string;
    margin?: PropTypes.Margin;
    placeholder?: string;
    required?: boolean;
}

export function useFormFieldDefaultProps<T extends IFieldBaseProps>(props: T): T {
    const fieldName = props.fieldName;
    const defaultProps = React.useMemo(() => {
        return {
            color: "secondary",
            autoComplete: "off",
            autoFocus: false,
            disabled: false,
            fullWidth: true,
            margin: "normal",
            required: true,
            label: fieldName,
        }
    }, [fieldName]);
    return React.useMemo(() => {
        return { ...defaultProps, ...props };
    }, [props, defaultProps]);
}

export function useFormFieldValue<T>(fieldName: string, data: any): T {
    return React.useMemo(() => {
        if (data[fieldName] === undefined) {
            throw new Error(`invalid fieldName '${fieldName}' in data : ${JSON.stringify(data)}`);
        }
        return data[fieldName] as T;
    }, [fieldName, data]);
}

export function useFormFieldError(fieldName: string, errors?: FormError[]): string | undefined {
    return React.useMemo(() => {
        if (errors === undefined) return undefined;
        const error = errors.find(x => x.fieldName === fieldName);
        return error === undefined ? undefined : error.message;
    }, [fieldName, errors])
}

export function useFormField<T extends IFieldBaseProps, D>(props: T, data: any, errors?: FormError[]): [T, D, string | undefined] {
    const defaultProps = useFormFieldDefaultProps<T>(props);
    const value = useFormFieldValue<D>(props.fieldName, data);
    const error = useFormFieldError(props.fieldName, errors);

    return [defaultProps, value, error];
}