import { useFetchArrayData } from "../../tools/fetchRequest";
import { ActivityTypeItemResponse } from "../../models/api/admin/activity-types/ActivityTypeItemResponse";
import { ListActivityTypesQuery } from "../../models/api/admin/activity-types/ListActivityTypesQuery";

const listQuery = new ListActivityTypesQuery();

function useListActivityTypes() {
    return useFetchArrayData<ActivityTypeItemResponse>(listQuery);
};

export default useListActivityTypes;