import React from "react";

import {
    SvgIconProps,
} from "@material-ui/core";

import Paper from "../Paper";
import Form, { FormProps } from "../Form";
import { SaveIcon } from "../../App/Theme";

export interface FormPaperProps<T, R = any> {
    title: string;
    icon?: React.ReactElement<SvgIconProps>;
    loading?: boolean;

    form: FormProps<T, R>;
}

export default function FormPaper<T, R = any>(props: FormPaperProps<T, R>) {

    return (
        <Paper
            name={props.title}
            icon={props.icon}
            loading={props.loading}
        >
            <Form<T, R>
                submitIcon={<SaveIcon />}
                {...props.form}
            />

        </Paper>
    )
}
