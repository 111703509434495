import React from "react";
import { Guid } from "guid-typescript";

import FormDialog from "../../components/FormDialog";

import { TownIcon } from "../../App/Theme";
import { FormFieldsBuilder } from "../../components/FormField";
import useGetTown from "./useGetTown";
import { TownResponse } from "../../models/api/admin/towns/TownResponse";
import { CreateTownCommand } from "../../models/api/admin/towns/CreateTownCommand";
import { UpdateTownCommand } from "../../models/api/admin/towns/UpdateTownCommand";
import { useListDepartments } from "../Department";
import { DepartmentItemResponse } from "../../models/api/admin/departments/DepartmentItemResponse";

interface TownFormDialogProps {
    open: boolean;
    townId: Guid;
    onClose: () => void;
    onAfterSave: () => void;
}

export default React.memo(({ open, townId, onClose, onAfterSave }: TownFormDialogProps) => {

    const [departmentsLoading, departments] = useListDepartments();

    const [loading, town] = useGetTown(townId, departments);

    const submitLabel = React.useMemo(
        () => townId.isEmpty() ? "Ajouter" : "Editer",
        [townId]
    );

    const fields = React.useMemo(() => {
        return buildFields(departmentsLoading ? [] : departments)
    }, [departmentsLoading, departments]);

    const buildMessage = React.useCallback((data: TownResponse) => {
        return townId.isEmpty()
            ? new CreateTownCommand(data)
            : new UpdateTownCommand(data);
    }, [townId]);

    return (
        <FormDialog<TownResponse>
            title={`${submitLabel} une ville`}
            icon={<TownIcon />}
            open={open}
            onClose={onClose}
            loading={loading}

            form={{
                submitLabel,
                fields,
                initial: town,
                onSuccess: onAfterSave,
                onPrepareMessage: buildMessage,
            }}
        />
    );
});

function buildFields(departments: DepartmentItemResponse[]) {

    const builder = new FormFieldsBuilder<TownResponse>(TownResponse.Fields);

    const departmentOptions = departments.map(d => {
        return {
            label: `${d.code} - ${d.name}`,
            value: d.id.toString(),
        }
    });

    builder
        .hiddenField(TownResponse.Fields.id)
        .attachFieldToSlug(
            TownResponse.Fields.name,
            TownResponse.Fields.slug
        )
        .setFieldProps(TownResponse.Fields.name, {
            label: "nom",
            position: 0,
        })
        .setFieldProps(TownResponse.Fields.slug, {
            position: 1,
        })
        .setFieldProps(TownResponse.Fields.departmentId, {
            position: 2,
            label: "département",
            type: "select",
            options: departmentOptions,
        })
        .setFieldProps(TownResponse.Fields.code, {
            type: "number",
            label: "code (à 5 chiffres)",
            placeholder: "exemple : 40250"
        });

    return builder.build();
}
