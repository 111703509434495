import React from "react";

export interface INotificationContext {
    info: (message: string) => void,
    success: (message: string) => void,
    warning: (message: string) => void,
    error: (message: string) => void,
    clear: () => void,

}

function buildError(method: string): Error {
    return new Error(`undefined INotificationContext.${method}() method`);
}

const NotificationContext = React.createContext<INotificationContext>({
    info: () => { throw buildError("info") },
    success: () => { throw buildError("success") },
    warning: () => { throw buildError("warning") },
    error: () => { throw buildError("error") },
    clear: () => { throw buildError("clear") },
});

export default NotificationContext;