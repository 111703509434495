import React from "react";
import { Guid } from "guid-typescript";
import { IconButton } from "@material-ui/core";

import ODataGrid, { ODataGridColumnsBuilder } from "../../components/ODataGrid";
import { EventItemResponse } from "../../models/api/admin/events/ODataResponse[EventItemResponse]";
import { EditIcon, EventIcon, TownIcon, EventTypeIcon } from "../../App/Theme";
import { routeFactory, RouteName } from "../../tools/Route";
import { SearchEventsQuery } from "../../models/api/admin/events/SearchEventsQuery";
import { lastDayOfYear, toUtcDate } from "../../tools/DateExtension";
import { SelectOption } from "../../components/FormField/SelectField";

interface EventsGridProps {
  loading: boolean;
  count: number;
  events: EventItemResponse[];
  onEdit: (id: Guid) => void;

  query: SearchEventsQuery;
  fetchQuery: () => void;
}

const BOOLEAN_OPTIONS = [
  { label: "Tous", value: "undefined" },
  { label: "Oui", value: "true" },
  { label: "Non", value: "false" }
] as SelectOption[];

export default function EventsGrid({ loading, count, events, onEdit, query, fetchQuery }: EventsGridProps) {

  const columns = React.useMemo(() => buildColumns(onEdit), [onEdit]);

  const initialFiltersValue = React.useMemo(() => {
    return {
      [EventItemResponse.Fields.endAt.name]: toUtcDate(lastDayOfYear()),
      [EventItemResponse.Fields.areDatesValidated.name]: BOOLEAN_OPTIONS[0].value,
      [EventItemResponse.Fields.isArchived.name]: BOOLEAN_OPTIONS[0].value,
    }
  }, []);

  return (
    <ODataGrid<EventItemResponse>
      count={count}
      data={events}
      loading={loading}
      columnsProps={columns}

      initialFiltersValue={initialFiltersValue}
      query={query}
      fetchQuery={fetchQuery}
    />
  );
}

function buildColumns(onEdit: (d: Guid) => void) {

  const builder = new ODataGridColumnsBuilder<EventItemResponse>(EventItemResponse.Fields);

  builder
    .hiddenField(EventItemResponse.Fields.id)
    .hiddenField(EventItemResponse.Fields.townId)
    .hiddenField(EventItemResponse.Fields.eventTypeId)
    .setFieldProps(EventItemResponse.Fields.townName, {
      label: "Ville",
      position: 0,
      icon: <TownIcon />,
      sortable: true,
      filterable: true,
    })
    .setFieldProps(EventItemResponse.Fields.eventTypeName, {
      label: "évènement",
      position: 1,
      icon: <EventTypeIcon />,
      sortable: true,
      filterable: true,
    })
    .setFieldProps(EventItemResponse.Fields.startAt, {
      label: "du",
      sortable: true,
      format: "Do MMM YYYY",
      filterable: true,
      filterOperators: ["ge", "gt"],
      filterField: {
        format: "DD/MM/YYYY",
      }
    })
    .setFieldProps(EventItemResponse.Fields.endAt, {
      label: "au",
      sortable: true,
      format: "Do MMM YYYY",
      filterable: true,
      filterOperators: ["le", "lt"],
      filterField: {
        format: "DD/MM/YYYY",
      }
    })
    .setFieldProps(EventItemResponse.Fields.areDatesValidated, {
      label: "dates validées ?",
      padding: "checkbox",
      align: "center",
      filterable: true,
      filterOperators: ["eq"],
      filterField: {
        type: "select",
        options: BOOLEAN_OPTIONS,
      }
    })
    .setFieldProps(EventItemResponse.Fields.isArchived, {
      label: "archivé ?",
      padding: "checkbox",
      align: "center",
      filterable: true,
      filterOperators: ["eq"],
      filterField: {
        type: "select",
        options: BOOLEAN_OPTIONS,
      }
    })
    .addColumn({
      padding: "checkbox",
      render: (event: EventItemResponse) => {
        if (event.areDatesValidated) {
          const route = routeFactory
            .get(RouteName.EventOverview)
            .setParameters({ eventId: event.id.toString() })
            .fullPath();
          return (
            <IconButton href={route} color="primary">
              <EventIcon />
            </IconButton>
          );
        }
        return (
          <IconButton onClick={() => onEdit(event.id)} color="primary">
            <EditIcon />
          </IconButton>
        );
      }
    })
    ;

  return builder.build();
}
