import { Guid } from "guid-typescript";
import { IMessage } from "../../../IMessage";

//Generated code, do not modify

export class CreateActivityCoverCommand implements IMessage {
    activityId!: Guid;
    mimeType!: string;
    content!: string;

    public constructor(init?: Partial<CreateActivityCoverCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST";

    getUrl = () => `/admin/activities/${this.activityId.toString()}/cover`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            mimeType: this.mimeType,
            content: this.content,
        };
    }
}