import React from "react";

import { TableCell } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/styles";

import { ODataFilter, ODataFilterOperator } from "../../models/api/OData";
import { IODataGridColumnProps } from "./types";
import FormField, { IFormFieldOptions } from "../FormField";
import CellFilterOperator from "./CellFilterOperator";

const useStyles = makeStyles((theme: Theme) => createStyles({
    cell: {
        padding: theme.spacing(0.5, 0, 0.5, 2),
    },
}));

export interface CellFilterProps<T> extends IODataGridColumnProps<T> {
    filter: ODataFilter;
    onValueChange: (fieldName: string, value: any) => void;
    onOperatorChange: (fieldName: string, operator: ODataFilterOperator) => void;
    onFilters: (fieldName: string, value: any) => void;
}

export default function CellFilter<T>({ filter, onValueChange, onOperatorChange, onFilters, type, ...props }: CellFilterProps<T>) {

    const classes = useStyles();

    const timer = React.useRef<NodeJS.Timeout | null>(null);

    const handleValueChange = React.useCallback((_fieldName: string, _value: any) => {
        onValueChange(_fieldName, _value);

        const delayTypes = ["string", "number", "email"];
        if (delayTypes.includes(type)) {
            if (timer.current) {
                clearTimeout(timer.current)
            }
            timer.current = setTimeout(() => {
                onFilters(_fieldName, _value);
            }, 400);
        }
        else {
            onFilters(_fieldName, _value);
        }
    }, [type, onValueChange, onFilters]);


    const handleOperatorChange = React.useCallback((_fieldName: string, _operator: ODataFilterOperator) => {
        onOperatorChange(_fieldName, _operator);
        onFilters(_fieldName, filter.value);
    }, [filter.value, onFilters, onOperatorChange]);

    return (
        <TableCell
            align={props.align}
            padding="none"
            className={classes.cell}
        >

            <FormField
                field={{
                    ...props.filterField as IFormFieldOptions,
                    startAdornment: <CellFilterOperator
                        fieldName={filter.fieldName}
                        operators={props.filterOperators}
                        filter={filter}
                        onChange={handleOperatorChange}
                    />
                }}
                errors={[]}
                data={{ [filter.fieldName]: filter.value }}
                onChange={handleValueChange}
            />

        </TableCell>
    );
};
