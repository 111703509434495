import { Guid } from "guid-typescript";
import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class CreateActivityTypeCommand implements IMessage {
    activityGroupId!: Guid;
    name!: string;
    filterable!: boolean;
    isDefaultSelected!: boolean;

    public constructor(init?: Partial<CreateActivityTypeCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST";

    getUrl = () => `/admin/activity-types`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            activityGroupId: this.activityGroupId,
            name: this.name,
            filterable: this.filterable,
            isDefaultSelected: this.isDefaultSelected,
        };
    }
}