import { Guid } from "guid-typescript";
import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class UpdateEventDayCommand implements IMessage {
    id!: Guid;
    name!: string;
    endAt!: string;
    busTimes!: string[];

    public constructor(init?: Partial<UpdateEventDayCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT";

    getUrl = () => `/admin/event-days/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            name: this.name,
            endAt: this.endAt,
            busTimes: this.busTimes,
        };
    }
}