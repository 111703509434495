import { Guid } from "guid-typescript";
import { IMessage } from "../../../IMessage";

//Generated code, do not modify

export class DeleteActivityCoverCommand implements IMessage {
    activityId!: Guid;

    public constructor(init?: Partial<DeleteActivityCoverCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "DELETE";

    getUrl = () => `/admin/activities/${this.activityId.toString()}/cover`;

    getQueryString = () => undefined;

    getBody = () => undefined;
}