import React from "react";

import { FormError } from "../../models/response";
import { IFormFieldOptions } from "./FormFieldsBuilder";
import TextField from "./TextField";
import SelectField from "./SelectField";
import SwitchField from "./SwitchField";
import DateField from "./DateField";
import TimeField from "./TimeField";
import TimesField from "./TimesField";
import RichTextField from "./RichTextField";
import AutoCompleteField from "./AutoCompleteField";

export interface IFormFieldProps {
    field: IFormFieldOptions;
    data: any;
    errors: FormError[];
    onChange: (fieldName: string, value: any) => void;
}

function FormField({ field, data, onChange, errors }: IFormFieldProps) {

    if (field.render !== undefined) {
        return field.render({ fieldProps: field, data, errors });
    }

    const type = field.type ? field.type.toLowerCase() : "string";

    let Component = TextField as (props: any) => JSX.Element;
    switch (type) {
        case "string":
        case "number":
        case "email":
        case "password":
            Component = TextField;
            break;
        case "select":
            Component = SelectField;
            break;
        case "boolean":
            Component = SwitchField;
            break;
        case "date":
            Component = DateField;
            break;
        case "time":
            Component = TimeField;
            break;
        case "time[]":
            Component = TimesField;
            break;
        case "richtext":
            Component = RichTextField;
            break;
        case "autocomplete":
            Component = AutoCompleteField;
            break;
        default:
            console.error(`undefined form field type ${type}`, field);
    }

    return <Component
        {...field}
        data={data}
        onChange={onChange}
        errors={errors}
    />
}

export default FormField;
