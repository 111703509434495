import React from "react";
import { Theme } from "@material-ui/core/styles";

export type ThemeType = "dark" | "light";

export interface IThemeContext {
    set: (type: ThemeType) => void;
    type: ThemeType;
    theme: Theme;
}

const ThemeContext = React.createContext<IThemeContext>({
    set: () => { throw new Error("undefined IThemeContext.info() method"); },
    type: "" as ThemeType,
    theme: {} as Theme,
});

export default ThemeContext;