class AuthToken {

    private KEY: string;

    constructor() {
        this.KEY = "appAccessToken";
    }

    isExist(): boolean {
        return this.get() !== null;
    }

    get(): string | null {
        return localStorage.getItem(this.KEY);
    }

    set(token: string) {
        localStorage.setItem(this.KEY, token);
    }

    clear(): void {
        localStorage.removeItem(this.KEY);
    }
}

const authToken = new AuthToken();

export default authToken;