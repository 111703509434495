import { Guid } from "guid-typescript";
import { IField } from "../../IField";

//Generated code, do not modify

export class ODataResponse<EventItemResponse> {
    results!: EventItemResponse[];
    count!: number;

    public static Fields = {
        results: { name: "results", type: "EventItemResponse[]" },
        count: { name: "count", type: "number" },
    } as Record<"results" | "count", IField>;
}

export class EventItemResponse {
    id!: Guid;
    townId!: Guid;
    townName!: string;
    eventTypeId!: Guid;
    eventTypeName!: string;
    startAt!: Date;
    endAt!: Date;
    areDatesValidated!: boolean;
    isArchived!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        townId: { name: "townId", type: "Guid" },
        townName: { name: "townName", type: "string" },
        eventTypeId: { name: "eventTypeId", type: "Guid" },
        eventTypeName: { name: "eventTypeName", type: "string" },
        startAt: { name: "startAt", type: "Date" },
        endAt: { name: "endAt", type: "Date" },
        areDatesValidated: { name: "areDatesValidated", type: "boolean" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<"id" | "townId" | "townName" | "eventTypeId" | "eventTypeName" | "startAt" | "endAt" | "areDatesValidated" | "isArchived", IField>;
}
