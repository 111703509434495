import React from "react";
import { Guid } from "guid-typescript";

import Paper from "../../components/Paper";
import CornerButton from "../../components/CornerButton";
import { CentralColumnLayout } from "../../components/Layouts";
import { DepartmentIcon, AddIcon } from "../../App/Theme";
import DepartmentsGrid from "./DepartmentsGrid";
import DepartmentFormDialog from "./DepartmentFormDialog";
import useListDepartments from "./useListDepartments";
import { useStateDelay } from "../../tools/useStateDelay";
import { useNotificationContext } from "../../components/Notification";

export default function ListDepartmentsPage() {

  document.title = "Liste des départements - Administration - Ferias40";

  const { success } = useNotificationContext();

  const emptyGuid = Guid.createEmpty();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogDepartmentId, setDialogDepartmentId, setDialogDepartmentIdDelay] = useStateDelay(emptyGuid);

  const [loading, departments, updateDepartments] = useListDepartments();

  const handleClose = React.useCallback(() => {
    setDialogOpen(false);
    setDialogDepartmentIdDelay(emptyGuid);
  }, [emptyGuid, setDialogDepartmentIdDelay]);

  const handleCreate = React.useCallback(() => {
    setDialogDepartmentId(emptyGuid);
    setDialogOpen(true);
  }, [emptyGuid, setDialogDepartmentId]);

  const handleEdit = React.useCallback((id: Guid) => {
    setDialogDepartmentId(id);
    setDialogOpen(true);
  }, [setDialogDepartmentId]);

  const handleAfterSave = React.useCallback(() => {
    updateDepartments();
    success("Le département a bien été enregistré");
  }, [updateDepartments, success]);

  return (
    <CentralColumnLayout size="sm">

      <CornerButton
        tooltip="Ajouter un département"
        icon={<AddIcon />}
        onClick={handleCreate}
      />

      <Paper
        name="Liste des départements"
        icon={< DepartmentIcon />}
      >
        <DepartmentsGrid
          departments={departments}
          loading={loading}
          onEdit={handleEdit}
        />
      </Paper>

      <DepartmentFormDialog
        open={dialogOpen}
        onClose={handleClose}
        departmentId={dialogDepartmentId}
        onAfterSave={handleAfterSave}
      />

    </CentralColumnLayout>
  );
}
