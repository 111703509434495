import React from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Drawer as MuiDrawer,
  Hidden, Divider,
  List, ListItem,
  ListItemIcon, ListItemText,
  Typography,
  Theme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";

import {
  DepartmentIcon, TownIcon,
  ActivityGroupIcon, ActivityTypeIcon,
  EventTypeIcon, EventIcon, ContactIcon,
  useThemeContext, SettingsIcon,
} from "../Theme";
import { routeFactory, RouteName } from "../../tools/Route";

interface IMenuItem {
  name: string,
  icon?: any,
  href: string,
}

const menu = [
  [
    {
      name: "Départements",
      icon: <DepartmentIcon />,
      href: routeFactory.fullPath(RouteName.DepartmentList),
    },
    {
      name: "Villes",
      icon: <TownIcon />,
      href: routeFactory.fullPath(RouteName.TownList),
    },
  ],
  [
    {
      name: "Groupes d'activités",
      icon: <ActivityGroupIcon />,
      href: routeFactory.fullPath(RouteName.ActivityGroupList),
    },
    {
      name: "Types d'activités",
      icon: <ActivityTypeIcon />,
      href: routeFactory.fullPath(RouteName.ActivityTypeList),
    },
    {
      name: "Types d'évènement",
      icon: <EventTypeIcon />,
      href: routeFactory.fullPath(RouteName.EventTypeList),
    },
    {
      name: "Evènements",
      icon: <EventIcon />,
      href: routeFactory.fullPath(RouteName.EventList),
    },
  ],
  [
    {
      name: "Paramètres",
      icon: <SettingsIcon />,
      href: routeFactory.fullPath(RouteName.Settings),
    },
    {
      name: "Contact",
      icon: <ContactIcon />,
      href: routeFactory.fullPath(RouteName.Contact),
    }
  ]
] as IMenuItem[][]

// --------------------------------------------------------

const useLinkStyles = makeStyles((theme: Theme) => createStyles({
  link: {
    color: "inherit",
  }
}));

interface MenuItemProps {
  item: IMenuItem;
}

function MenuItem({ item: { href, name, icon } }: MenuItemProps) {
  const location = useLocation();
  const classes = useLinkStyles();

  return (
    <Link to={href} className={classes.link}>
      <ListItem button selected={location.pathname.includes(href)}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
      </ListItem>
    </Link>
  );
}

// --------------------------------------------------------

interface MenuItemListProps {
  items: IMenuItem[];
}

function MenuItemList({ items }: MenuItemListProps) {
  return <>
    <Divider />
    <List>
      {items.map((item, idx) => <MenuItem key={idx} item={item} />)}
    </List>
  </>
}

// --------------------------------------------------------

const useStyles = makeStyles((theme: Theme) => createStyles({
  drawer: {
    [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
      width: theme.mixins.drawer.width,
      flexShrink: 0,
    },
  },
  paper: {
    width: theme.mixins.drawer.width,
  },
  header: {
    ...theme.mixins.toolbar,
    color: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export interface DrawerProps {
  open: boolean,
  onDrawerToggle: any,
}

export default function Drawer({ open, onDrawerToggle }: DrawerProps) {

  const classes = useStyles();
  const { theme } = useThemeContext();

  const drawerMenu = React.useMemo(() => {
    return menu.map((m, idx) => <MenuItemList key={idx} items={m} />)
  }, []);

  const drawer = React.useMemo(() => {
    return (<>
      <Link className={classes.header} to={routeFactory.fullPath(RouteName.Home)}>
        <Typography variant="h6" noWrap>
          Ferias40
        </Typography>
      </Link>
      {drawerMenu}
    </>)
  }, [classes.header, drawerMenu]);

  const handleClick = React.useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onDrawerToggle();
  }, [onDrawerToggle]);

  return (
    <nav className={classes.drawer}>

      <Hidden
        only={theme.mixins.drawer.largeDevice}
        implementation={theme.mixins.drawer.implementation}
      >
        <MuiDrawer
          closeAfterTransition={true}
          open={open}
          anchor="left"
          variant="temporary"
          classes={{ paper: classes.paper }}
          onClose={onDrawerToggle}
          onClick={handleClick}
        >
          {drawer}
        </MuiDrawer>
      </Hidden>

      <Hidden
        only={theme.mixins.drawer.smallDevice}
        implementation={theme.mixins.drawer.implementation}
      >
        <MuiDrawer
          open
          variant="permanent"
          classes={{ paper: classes.paper }}
        >
          {drawer}
        </MuiDrawer>
      </Hidden>

    </nav>
  )
}