import React from "react";
import { TableCell, TableSortLabel } from "@material-ui/core";

import { IODataGridColumnProps } from "./types";
import { ODataOrderBy, OrderDirection } from "../../models/api/OData";
import { CellHeaderLabel } from "../DataGrid";

export interface CellHeaderProps<T> extends IODataGridColumnProps<T> {
    orderBy: ODataOrderBy;
    onOrderBy: (fieldName: string, direction: OrderDirection) => void;
}

export default function CellHeaderSortable<T>({ align, fieldName, ...props }: CellHeaderProps<T>) {

    const orderBy = React.useMemo(() => {
        return {
            fieldName: props.orderBy.member,
            direction: props.orderBy.direction === OrderDirection.Asc ? "desc" : "asc",
        } as { fieldName: string, direction: "asc" | "desc" }
    }, [props.orderBy]);


    const handleSort = React.useCallback(() => {
        let direction = OrderDirection.Asc;
        if (orderBy.fieldName === fieldName) {
            direction = orderBy.direction === "asc" ? OrderDirection.Asc : OrderDirection.Desc;
        }
        props.onOrderBy(fieldName, direction);
    }, [fieldName, orderBy, props]);

    return (
        <TableCell
            align={align}
            padding="default"
        >
            <TableSortLabel
                active={orderBy.fieldName === fieldName}
                direction={orderBy.direction}
                onClick={handleSort}
            >
                <CellHeaderLabel
                    icon={props.icon}
                    label={props.label}
                    fieldName={fieldName}
                />
            </TableSortLabel>
        </TableCell>
    );
};
