import React from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import { ProtectedRoute } from "../components/Routes";
import { RouteName, routeFactory } from "../tools/Route";

import LoginPage from "../pages/Login";
import { ResetPasswordPage } from "../pages/ResetPassword";
import { HomePage } from "../pages/Home";
import { ListDepartmentsPage } from "../pages/Department";
import { ListTownsPage } from "../pages/Town";
import ProfilePage from "../pages/Profile";
import { ListActivityGroupsPage } from "../pages/ActivityGroup";
import { ListActivityTypesPage } from "../pages/ActivityType";
import { ListEventTypesPage } from "../pages/EventType";
import { ListEventsPage } from "../pages/Event";
import { EventOverviewPage } from "../pages/Event/overview";
import { EventDayProgramPage } from "../pages/EventDay/program";
import { ContactPage } from "../pages/Contact";
import SettingsPage from "../pages/Settings";
import { PageLayout } from "../components/Layouts";

const useStyles = makeStyles({
  root: {
    display: "flex",
    // overflow: "hidden",
  },
});

export default function App() {

  const classes = useStyles();
  const location = useLocation();

  if (routeFactory.isMatch(location, RouteName.Login)) {
    return <LoginPage />;
  }
  else if (routeFactory.isMatch(location, RouteName.ResetPassword)) {
    return <ResetPasswordPage />;
  }

  return (
    <div className={classes.root}>

      <PageLayout>

        <ProtectedRoute name={RouteName.Home} component={HomePage} />
        <ProtectedRoute name={RouteName.Profile} component={ProfilePage} />

        <ProtectedRoute name={RouteName.DepartmentList} component={ListDepartmentsPage} />
        <ProtectedRoute name={RouteName.TownList} component={ListTownsPage} />

        <ProtectedRoute name={RouteName.ActivityGroupList} component={ListActivityGroupsPage} />
        <ProtectedRoute name={RouteName.ActivityTypeList} component={ListActivityTypesPage} />

        <ProtectedRoute name={RouteName.EventTypeList} component={ListEventTypesPage} />
        <ProtectedRoute name={RouteName.EventList} component={ListEventsPage} />
        <ProtectedRoute name={RouteName.EventOverview} component={EventOverviewPage} />
        <ProtectedRoute name={RouteName.EventDayProgram} component={EventDayProgramPage} />

        <ProtectedRoute name={RouteName.Contact} component={ContactPage} />
        <ProtectedRoute name={RouteName.Settings} component={SettingsPage} />

      </PageLayout>

    </div>
  );

}
