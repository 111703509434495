import React from "react";
import { Guid } from "guid-typescript";

import FormDialog from "../../components/FormDialog";
import { ActivityIcon } from "../../App/Theme";
import { ActivityResponse } from "../../models/api/admin/activities/ActivityResponse";
import { CreateActivityCommand } from "../../models/api/admin/activities/CreateActivityCommand";
import { UpdateActivityCommand } from "../../models/api/admin/activities/UpdateActivityCommand";
import useGetActivity from "./useGetActivity";
import { FormFieldsBuilder } from "../../components/FormField";
import { useListActivityTypes } from "../ActivityType";
import { ActivityTypeItemResponse } from "../../models/api/admin/activity-types/ActivityTypeItemResponse";

interface ActivityFormDialogProps {
    open: boolean;
    eventDayId: Guid;
    activityId: Guid;
    onClose: () => void;
    onAfterSave: () => void;
}

export default React.memo(({ open, activityId, eventDayId, onClose, onAfterSave }: ActivityFormDialogProps) => {

    const [activityTypesLoading, activityTypes] = useListActivityTypes();

    const [loading, activity] = useGetActivity(activityId, activityTypes);

    const submitLabel = React.useMemo(
        () => activityId.isEmpty() ? "Ajouter" : "Editer",
        [activityId]
    );

    const fields = React.useMemo(() => {
        return buildFields(activityTypesLoading ? [] : activityTypes)
    }, [activityTypesLoading, activityTypes]);

    const buildMessage = React.useCallback((data: ActivityResponse) => {
        return activityId.isEmpty()
            ? new CreateActivityCommand({ ...data, eventDayId })
            : new UpdateActivityCommand(data);
    }, [activityId, eventDayId]);

    return (
        <FormDialog<ActivityResponse>
            title={`${submitLabel} une activité`}
            icon={<ActivityIcon />}
            open={open}
            onClose={onClose}
            loading={loading}

            form={{
                submitLabel,
                fields,
                initial: activity,
                onSuccess: onAfterSave,
                onPrepareMessage: buildMessage,
            }}
        />
    );
});

function buildFields(activityTypes: ActivityTypeItemResponse[]) {

    const builder = new FormFieldsBuilder<ActivityResponse>(ActivityResponse.Fields);

    const activityTypeOptions = activityTypes.map(at => {
        return {
            label: `${at.name}`,
            value: at.id.toString(),
            groupName: at.activityGroupName
        }
    });

    builder
        .hiddenField(ActivityResponse.Fields.id)
        .hiddenField(ActivityResponse.Fields.isArchived)
        .setFieldProps(ActivityResponse.Fields.activityTypeId, {
            position: 0,
            label: "activité",
            type: "autocomplete",
            noOptionsText: "aucune activité trouvée",
            options: activityTypeOptions,
            groupBy: (option: any) => {
                return option.groupName;
            }
        })
        .setFieldProps(ActivityResponse.Fields.name, {
            label: "nom",
            position: 1,
            required: false,
        })
        .setFieldProps(ActivityResponse.Fields.time, {
            type: "time",
            label: "Heure",
        })
        .setFieldProps(ActivityResponse.Fields.description, {
            type: "richtext",
            label: "Description",
        })
        ;

    return builder.build();
}
