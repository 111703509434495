import React from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { IField } from "../../models/api/IField";
import { ResetUserPasswordCommand } from "../../models/api/admin/users/password/ResetUserPasswordCommand";
import { FormFieldsBuilder } from "../../components/FormField";
import Form from "../../components/Form";
import { SaveIcon } from "../../App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
  submit: {
    marginTop: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

class ResetPasswordResponse {
  password!: string;
  passwordConfirmation!: string;
  token!: string;

  public static Fields = {
    password: { name: "password", type: "string" },
    passwordConfirmation: { name: "passwordConfirmation", type: "string" },
    token: { name: "token", type: "string" }
  } as Record<"password" | "passwordConfirmation" | "token", IField>;
}

function emptyResponseBuilder(token?: string) {
  let response = new ResetPasswordResponse();
  response.password = "";
  response.passwordConfirmation = "";
  response.token = token ? token : "";
  return response;
}

export interface ResetPasswordFormProps {
  token: string | undefined;
  onSuccess: () => void;
}

export default function ResetPasswordForm(props: ResetPasswordFormProps) {

  const classes = useStyles();

  const fields = React.useMemo(() => buildFields(), []);
  const initial = React.useMemo(() => emptyResponseBuilder(props.token), [props.token]);

  const buildMessage = React.useCallback((data: ResetPasswordResponse) => {
    if (!data.token) {
      throw new Error("invalid token");
    }
    return new ResetUserPasswordCommand(data);
  }, []);

  return (
    <Form<ResetPasswordResponse>
      submitClassname={classes.submit}
      submitLabel="Enregistrer"
      submitIcon={<SaveIcon />}
      fields={fields}
      initial={initial}
      onSuccess={props.onSuccess}
      onPrepareMessage={buildMessage}
    />
  )
}

function buildFields() {

  const builder = new FormFieldsBuilder<ResetPasswordResponse>(ResetPasswordResponse.Fields);

  builder
    .hiddenField(ResetPasswordResponse.Fields.token)
    .setFieldProps(ResetPasswordResponse.Fields.password, {
      label: "Nouveau mot de passe",
      type: "password",
    })
    .setFieldProps(ResetPasswordResponse.Fields.passwordConfirmation, {
      label: "Confirmer le nouveau mot de passe",
      type: "password",
    })
    ;

  return builder.build();
}
