import React from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from '@date-io/moment';

import IFieldBaseProps, { useFormField } from "./IFieldBaseProps";
import { FormError } from "../../models/response";
import { capitalize } from "../../tools/StringExtension";

export interface DateFieldProps extends IFieldBaseProps {
    data: any;
    errors?: FormError[];
    onChange?: (fieldName: string, date: Date) => void;

    format?: string;
    minDate?: Date;
    maxDate?: Date;
    startAdornment?: React.ReactNode;
}

function DateField(inProps: DateFieldProps) {

    const [props, value, error] =
        useFormField<DateFieldProps, Date>({
            format: "dddd DD MMMM YYYY",
            ...inProps
        }, inProps.data, inProps.errors);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
                required={props.required}
                fullWidth={props.fullWidth}
                margin={props.margin}
                disabled={props.disabled}
                autoFocus={props.autoFocus}
                color={props.color}

                InputLabelProps={{ shrink: true }}
                label={props.label ? capitalize(props.label) : undefined}
                value={value}
                minDate={props.minDate}
                maxDate={props.maxDate}
                format={props.format}

                onChange={(d: MaterialUiPickersDate) => {
                    if (d !== null && props.onChange) {
                        props.onChange(props.fieldName, d.toDate());
                    }
                }}
                error={error !== undefined}
                helperText={error}

                InputProps={{
                    startAdornment: props.startAdornment,
                    onClick: ((e) => {
                        if (props.startAdornment && (e.target as any).nodeName !== "INPUT") {
                            e.stopPropagation();
                            e.preventDefault();
                        }
                    })
                }}
            />
        </MuiPickersUtilsProvider>
    )

}

export default DateField