import { Guid } from "guid-typescript";
import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class CreateEventCommand implements IMessage {
    townId!: Guid;
    eventTypeId!: Guid;
    startAt!: Date;
    endAt!: Date;
    areDatesValidated!: boolean;

    public constructor(init?: Partial<CreateEventCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "POST";

    getUrl = () => `/admin/events`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            townId: this.townId,
            eventTypeId: this.eventTypeId,
            startAt: this.startAt,
            endAt: this.endAt,
            areDatesValidated: this.areDatesValidated,
        };
    }
}