import { Guid } from "guid-typescript";
import { IField } from "../../IField";

//Generated code, do not modify

export class EventResponse {
    id!: Guid;
    townId!: Guid;
    eventTypeId!: Guid;
    startAt!: Date;
    endAt!: Date;
    areDatesValidated!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        townId: { name: "townId", type: "Guid" },
        eventTypeId: { name: "eventTypeId", type: "Guid" },
        startAt: { name: "startAt", type: "Date" },
        endAt: { name: "endAt", type: "Date" },
        areDatesValidated: { name: "areDatesValidated", type: "boolean" },
    } as Record<"id" | "townId" | "eventTypeId" | "startAt" | "endAt" | "areDatesValidated", IField>;
}
