import React from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Chip, IconButton } from "@material-ui/core";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import MomentUtils from "@date-io/moment";

import IFieldBaseProps, { useFormField } from "./IFieldBaseProps";
import { FormError } from "../../models/response";
import { InfoIcon, AddIcon } from "../../App/Theme";
import { capitalize } from "../../tools/StringExtension";
import { timeToDate } from "../../tools/DateExtension";


const useStyles = makeStyles((theme: Theme) => createStyles({
    timesContainer: {
        marginTop: theme.spacing(1) * 0.5,
    },
    timeChip: {
        marginRight: theme.spacing(1),
    }
}));

export interface TimesFieldProps extends IFieldBaseProps {
    data: any;
    errors?: FormError[];
    onChange?: (fieldName: string, value: string[]) => void;

    minutesStep?: number;
}

function TimesField(inProps: TimesFieldProps) {

    const classes = useStyles();
    const [value, setValue] = React.useState("20:30");

    const [props, busTimes, error] =
        useFormField<TimesFieldProps, string[]>({
            minutesStep: 5,
            ...inProps,
        }, inProps.data, inProps.errors);

    const handleChange = React.useCallback((d: MaterialUiPickersDate) => {
        if (d) {
            const time = d.format("HH:mm");
            setValue(time);
        }
    }, [setValue]);

    const handleAddTime = React.useCallback((e: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (busTimes.find(x => x === value) === undefined) {
            busTimes.push(value);
            busTimes.sort();
            props.onChange && props.onChange(props.fieldName, busTimes);
            setValue("20:30");
        }
    }, [busTimes, props, value, setValue]);

    return (
        <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <TimePicker
                    required={props.required}
                    fullWidth={props.fullWidth}
                    disabled={props.disabled}
                    margin={props.margin}
                    autoFocus={props.autoFocus}
                    color={props.color}

                    ampm={false}
                    minutesStep={props.minutesStep}
                    label={props.label ? capitalize(props.label) : undefined}
                    value={timeToDate(value)}
                    onChange={handleChange}
                    error={error !== undefined}
                    helperText={error}

                    InputProps={{
                        endAdornment: (
                            <IconButton
                                aria-label="Add time"
                                onClick={handleAddTime}
                                size="small"
                            >
                                <AddIcon />
                            </IconButton>
                        ),
                    }}
                />
            </MuiPickersUtilsProvider>

            <div className={classes.timesContainer}>
                {busTimes.map((bt, idx) =>
                    <Chip
                        key={idx}
                        label={bt}
                        onDelete={() => {
                            busTimes.splice(idx, 1);
                            props.onChange && props.onChange(props.fieldName, busTimes);
                        }}
                        color="default"
                        variant="outlined"
                        className={classes.timeChip}
                    />
                )}
                {busTimes.length === 0 && props.placeholder !== undefined &&
                    <Chip
                        icon={<InfoIcon />}
                        label={props.placeholder}
                        color="default"
                        variant="outlined"
                    />
                }
            </div>
        </>
    );
}

export default TimesField;