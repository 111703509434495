import React from "react";
import { Link } from "react-router-dom";

import {
  AppBar, Toolbar as MToolbar,
  Typography, IconButton,
  Menu, MenuItem, ListItemIcon, ListItemText, Fab,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import {
  MenuIcon,
  AccountIcon, LogoutIcon,
  useThemeContext, BrightnessIcon
} from "../Theme";
import { useAuthContext } from "../../tools/Auth";
import { routeFactory, RouteName } from "../../tools/Route";


const useProfileMenuStyles = makeStyles((theme: Theme) => createStyles({
  menu: {
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    "& p": {
      fontSize: theme.typography.h6.fontSize,
    }
  },
  link: {
    color: "inherit",
  }
}));

interface ProfileMenuProps {
  id: string;
  anchorEl: null | Element | ((element: Element) => Element);
  keepMounted: boolean;
  onClose: () => void;
}

function ProfileMenu(props: ProfileMenuProps) {

  const classes = useProfileMenuStyles();
  const { setToken, payload } = useAuthContext();
  const { type: themeType, set: setTheme } = useThemeContext();

  const handleClick = React.useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement
    if (target.closest("ul > a")) {
      props.onClose();
    }
  }, [props]);

  const changeTheme = React.useCallback(() => {
    setTheme(themeType === "dark" ? "light" : "dark");
  }, [setTheme, themeType]);

  const handleLogout = React.useCallback(() => setToken(null), [setToken]);

  return (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={Boolean(props.anchorEl)}
      {...props}
      className={classes.menu}
      onClick={handleClick}
    >
      {payload &&
        <div className={classes.header}>
          <Fab size="small" disabled>
            {payload.firstName[0].toUpperCase()} {payload.lastName[0].toUpperCase()}
          </Fab>
          <p>
            {payload.firstName} {payload.lastName}
          </p>
        </div>
      }

      <MenuItem onClick={changeTheme}>
        <ListItemIcon>
          <BrightnessIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={themeType === "dark" ? "Switch light" : "Switch dark"} />
      </MenuItem>

      <Link to={routeFactory.fullPath(RouteName.Profile)} className={classes.link}>
        <MenuItem>
          <ListItemIcon>
            <AccountIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Mon profil" />
        </MenuItem>
      </Link>

      <MenuItem onClick={handleLogout}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Se déconnecter" />
      </MenuItem>
    </Menu>
  );
}

// --------------------------------------------------------

const useToolbarStyles = makeStyles((theme: Theme) => createStyles({
  appBar: {
    marginLeft: theme.mixins.drawer.width,
    [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
      width: `calc(100% - ${theme.mixins.drawer.width}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(1) * 2,
    [theme.breakpoints.up(theme.mixins.drawer.breakpoint)]: {
      display: "none",
    },
  },
  rightActions: {
    marginLeft: "auto",
  },
}));

export interface ToolbarProps {
  onDrawerToggle: any,
}

export default function Toolbar({ onDrawerToggle }: ToolbarProps) {

  const classes = useToolbarStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = React.useCallback(() => setAnchorEl(null), []);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <MToolbar>

        <IconButton
          color="inherit"
          aria-label="Open drawer"
          onClick={onDrawerToggle}
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <Typography variant="h6" color="inherit" noWrap>
          Administration
        </Typography>

        <div className={classes.rightActions}>
          <IconButton
            aria-controls="toolbar-profile-menu"
            onClick={handleClick}
          >
            <AccountIcon />
          </IconButton>

          <ProfileMenu
            id="toolbar-profile-menu"
            keepMounted={true}
            anchorEl={anchorEl}
            onClose={handleClose}
          />
        </div>

      </MToolbar>
    </AppBar>
  )
}
