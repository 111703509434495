import RouteName, { toString } from "./RouteName"
import Route from "./Route"
import RouteConfiguration from "./RouteConfiguration"

import * as H from "history";

export default class RouteFactory {

    routes: Route[]

    constructor(config: RouteConfiguration) {
        this.routes = config.routes
    }

    public get(name: RouteName): Route {
        const rName = toString(name)
        const route = this.routes.find(r => r.name() === rName)

        if (route) {
            return route.clone()
        }

        throw Error(`undefined Route named "${rName}"`)
    }

    public fullPath(name: RouteName): string {
        return this.get(name).fullPath();
    }

    public isMatch(location: H.Location, name: RouteName, parameters: Record<string, string> | null = null): boolean {
        return this.get(name).isMatch(location, parameters);
    }

}