import React, { ReactNode } from "react";
import clsx from "clsx";

import {
  Paper as MPaper, CircularProgress,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

import Title from "../Title"

const useStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    position: "relative",
    width: "100%",
    overflowX: "auto",
    padding: theme.spacing(1) * 2,
    marginBottom: theme.spacing(1),
  },
  title: {
    marginLeft: theme.spacing(1) * 2,
    marginRight: theme.spacing(1) * 2,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
  },
  container: {
    marginLeft: theme.spacing(1) * 2,
    marginRight: theme.spacing(1) * 2,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  loadingContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    zIndex: theme.zIndex.appBar - 1,
  },
}));

export interface PaperProps {
  name?: string,
  icon?: React.ReactElement<SvgIconProps>,
  children?: ReactNode,
  className?: string,
  loading?: boolean,
}

export default function Paper({ name, icon, children, className, loading }: PaperProps) {

  const classes = useStyles();

  const title = name
    ? (
      <div className={classes.title}>
        <Title title={name} startIcon={icon} />
      </div>
    )
    : null

  return (
    <MPaper className={clsx(classes.paper, className)}>
      {title}
      <div className={classes.container}>
        {children}
      </div>

      {loading &&
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      }

    </MPaper>
  )
}
