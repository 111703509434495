import { useFetchOData } from "../../tools/fetchRequest";
import { SearchTownsQuery } from "../../models/api/admin/towns/SearchTownsQuery";
import { TownItemResponse } from "../../models/api/admin/towns/ODataResponse[TownItemResponse]";
import { ODataOrderBy, OrderDirection } from "../../models/api/OData";

const _defaultSearchTownsQuery = new SearchTownsQuery({
    top: 1000,
    skip: 0,
    orderBy: new ODataOrderBy({
        direction: OrderDirection.Asc,
        member: TownItemResponse.Fields.name.name,
    }),
});

function useSearchTowns(searchTownsQuery: SearchTownsQuery | undefined = undefined) {
    return useFetchOData<TownItemResponse>(searchTownsQuery !== undefined
        ? searchTownsQuery
        : _defaultSearchTownsQuery
    );
};

export default useSearchTowns;