import React from "react";

import { TableCell } from "@material-ui/core";
import { IGridColumnProps } from "./types";
import CellHeaderLabel from "./CellHeaderLabel";

export interface CellHeaderProps<T> extends IGridColumnProps<T> {
}

export default function CellHeader<T>({ align, ...props }: CellHeaderProps<T>) {
    return (
        <TableCell align={align} padding="default">
            <CellHeaderLabel
                {...props}
            />
        </TableCell>
    );
};

