import { useFetchArrayData } from "../../tools/fetchRequest";
import { TimelineMonthItemResponse } from "../../models/api/timeline-months/TimelineMonthItemResponse";
import { ListTimelineMonthsQuery } from "../../models/api/timeline-months/ListTimelineMonthsQuery";

const listTimelineMonthsQuery = new ListTimelineMonthsQuery();

function useListTimelineMonths() {
    return useFetchArrayData<TimelineMonthItemResponse>(listTimelineMonthsQuery);
}

export default useListTimelineMonths;