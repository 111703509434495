import { Guid } from "guid-typescript";

import { useFetchDataById } from "../../tools/fetchRequest";
import { GetActivityGroupQuery } from "../../models/api/admin/activity-groups/GetActivityGroupQuery";
import { ActivityGroupResponse } from "../../models/api/admin/activity-groups/ActivityGroupResponse";

function emptyBuilder() {
    let response = new ActivityGroupResponse();
    response.id = Guid.createEmpty();
    response.name = "";
    return response;
}

const getQueryBuilder = (id: Guid) => new GetActivityGroupQuery({id});

const useGetActivityGroup = (id: Guid) => {
    return useFetchDataById(
        id,
        getQueryBuilder,
        emptyBuilder
    );
};

export default useGetActivityGroup;