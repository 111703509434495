import React from "react";

import { SvgIconProps } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { capitalize } from "../../tools/StringExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    cell: {
        flexDirection: "row",
        alignContent: "center",
        verticalAlign: "middle",
    },
    icon: {
        marginRight: theme.spacing(1),
        "& svg": {
            verticalAlign: "middle",
        },
    },
    title: {
        whiteSpace: "nowrap",
    },
}));

interface HeaderLabelProps {
    fieldName: string;
    icon?: React.ReactElement<SvgIconProps>;
    label?: string;
}

export default React.memo(({ icon, label, fieldName }: HeaderLabelProps) => {
    const classes = useStyles();

    return (
        <span className={classes.cell}>
            {icon && <span className={classes.icon}>{icon}</span>}
            <span className={classes.title}>
                {label && capitalize(label)}
                {!label && fieldName && capitalize(fieldName)}
                {!label && !fieldName && null}
            </span>
        </span>
    );
});