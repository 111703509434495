import React from "react";
import { Guid } from "guid-typescript";

import Paper from "../../components/Paper";
import CornerButton from "../../components/CornerButton";
import { CentralColumnLayout } from "../../components/Layouts";
import { AddIcon, TownIcon } from "../../App/Theme";
import useSearchTowns from "./useSearchTowns";
import TownsGrid from "./TownsGrid";
import TownFormDialog from "./TownFormDialog";
import { useStateDelay } from "../../tools/useStateDelay";
import { useNotificationContext } from "../../components/Notification";

import { OrderDirection, ODataOrderBy } from "../../models/api/OData";
import { SearchTownsQuery } from "../../models/api/admin/towns/SearchTownsQuery";
import { TownItemResponse } from "../../models/api/admin/towns/ODataResponse[TownItemResponse]";

export default function ListTownsPage() {

  document.title = "Liste des villes - Administration - Ferias40";

  const { success } = useNotificationContext();

  const emptyGuid = Guid.createEmpty();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogTownId, setDialogTownId, setDialogTownIdDelay] = useStateDelay(emptyGuid);

  const query = React.useMemo(() => new SearchTownsQuery({
    top: 10,
    skip: 0,
    orderBy: new ODataOrderBy({
      direction: OrderDirection.Asc,
      member: TownItemResponse.Fields.name.name,
    }),
  }), []);
  const [loading, towns, townsCount, updateTowns] = useSearchTowns(query);

  const handleClose = React.useCallback(() => {
    setDialogOpen(false);
    setDialogTownIdDelay(emptyGuid);
  }, [emptyGuid, setDialogTownIdDelay]);

  const handleCreate = React.useCallback(() => {
    setDialogTownId(emptyGuid);
    setDialogOpen(true);
  }, [emptyGuid, setDialogTownId]);

  const handleEdit = React.useCallback((id: Guid) => {
    setDialogTownId(id);
    setDialogOpen(true);
  }, [setDialogTownId]);

  const handleAfterSave = React.useCallback(() => {
    updateTowns();
    success("La ville a bien été enregistrée");
  }, [updateTowns, success]);

  return (
    <CentralColumnLayout size="xl">

      <CornerButton
        tooltip="Ajouter une ville"
        icon={<AddIcon />}
        onClick={handleCreate}
      />

      <Paper
        name="Liste des villes"
        icon={<TownIcon />}
      >
        <TownsGrid
          count={townsCount}
          towns={towns}
          loading={loading}
          onEdit={handleEdit}

          query={query}
          fetchQuery={updateTowns}
        />
      </Paper>

      <TownFormDialog
        open={dialogOpen}
        onClose={handleClose}
        townId={dialogTownId}
        onAfterSave={handleAfterSave}
      />

    </CentralColumnLayout>
  );
}
