import React from "react";

import { Grid } from "@material-ui/core";

import TimelineMonthsPaper from "./TimelineMonthsPaper";
import EventsNewYearFormPaper from "./EventsNewYearFormPaper";

export default function SettingsPage() {

  document.title = "Paramètre de la timeline - Administration - Ferias40";

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="flex-start"
      spacing={2}
    >

      <Grid item lg={6} md={12}>
        <EventsNewYearFormPaper />
      </Grid>

      <Grid item lg={6} md={12}>
        <TimelineMonthsPaper />
      </Grid>

    </Grid>
  );
}

