import React from "react";

import {
    FormControl, InputLabel,
    FormHelperText, Input, MenuItem
} from "@material-ui/core";
import Select, { SelectProps } from "@material-ui/core/Select";

import IFieldBaseProps, { useFormField } from "./IFieldBaseProps";
import { FormError } from "../../models/response";
import { capitalize } from "../../tools/StringExtension";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 250,
            // width: 250,
        },
    },
}

export interface SelectOption {
    label: any;
    value: any;
    enabled?: boolean;
}

export interface SelectFieldProps extends IFieldBaseProps {
    data: any;
    errors?: FormError[];
    onChange?: (fieldName: string, option: any) => void;

    options?: SelectOption[];
    renderValue?: (value: SelectProps["value"]) => React.ReactNode;
    startAdornment?: React.ReactNode;
}

function SelectField(inProps: SelectFieldProps) {

    const [props, value, error] =
        useFormField<SelectFieldProps, any>(inProps, inProps.data, inProps.errors);

    return (
        <FormControl
            required={props.required}
            fullWidth={props.fullWidth}
            margin={props.margin}
            disabled={props.disabled}
            color={props.color}
            error={error !== undefined}
        >
            {props.label &&
                <InputLabel htmlFor={props.fieldName}>
                    {capitalize(props.label)}
                </InputLabel>
            }

            <Select
                autoFocus={props.autoFocus}
                value={value}
                onChange={e => {
                    const value = e.target.value;
                    props.onChange && props.onChange(props.fieldName, value);
                }}
                input={<Input name={props.fieldName} startAdornment={props.startAdornment} />}
                inputProps={{ name: props.fieldName }}
                MenuProps={MenuProps}
                renderValue={props.renderValue}
            >

                {props.options && props.options.map(opt =>
                    <MenuItem value={opt.value} key={opt.value} disabled={opt.enabled === false}>
                        {opt.label}
                    </MenuItem>
                )}

            </Select>

            {error !== undefined ? <FormHelperText>{error}</FormHelperText> : null}

        </FormControl>
    )
}

export default SelectField