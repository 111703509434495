import React from "react";

import { IconButton } from "@material-ui/core";
import MuiTextField from "@material-ui/core/TextField";

import IFieldBaseProps, { useFormField } from "./IFieldBaseProps";
import { FormError } from "../../models/response";
import { capitalize } from "../../tools/StringExtension";
import { CloseIcon } from "../../App/Theme";

export interface TextFieldProps extends IFieldBaseProps {
    autoClearAdornment?: boolean;
    data: any;
    errors?: FormError[];
    onChange?: (fieldName: string, value: string) => void;
    startAdornment?: React.ReactNode;
}

export default function TextField(inProps: TextFieldProps) {

    const [props, value, error] =
        useFormField<TextFieldProps, string>({
            autoClearAdornment: false,
            ...inProps,
        }, inProps.data, inProps.errors);

    return (
        <MuiTextField
            required={props.required}
            fullWidth={props.fullWidth}
            margin={props.margin}
            autoComplete={props.autoComplete}
            disabled={props.disabled}
            color={props.color}

            type={props.type}
            autoFocus={props.autoFocus}

            label={props.label ? capitalize(props.label) : undefined}
            value={value}
            placeholder={props.placeholder}
            onChange={e => {
                const value = e.target.value;
                props.onChange && props.onChange(props.fieldName, value);
            }}
            error={error !== undefined}
            helperText={error}

            InputProps={{
                startAdornment: props.startAdornment,
                endAdornment: (
                    props.autoClearAdornment && value && value.length > 0 && !props.disabled &&
                    <IconButton
                        size="small"
                        aria-label="Effacer"
                        onClick={() => props.onChange && props.onChange(props.fieldName, "")}
                    >
                        <CloseIcon />
                    </IconButton>
                ),
            }}
        />
    )
}
