import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import Toolbar from "../../App/Toolbar";
import Drawer from "../../App/Drawer";

const useStyles = makeStyles((theme: Theme) => createStyles({
    toolbar: theme.mixins.toolbar,
    content: {
        position: "relative",
        flexGrow: 1,
        padding: theme.spacing(1) * 3,
        overflow: "auto",
    },
}));

export interface PageLayoutProps {
    children?: React.ReactNode | React.ReactNode[];
}

export default function PageLayout({ children }: PageLayoutProps) {

    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    return <>
        <Toolbar onDrawerToggle={setDrawerOpen} />

        <Drawer
            open={drawerOpen}
            onDrawerToggle={setDrawerOpen}
        />

        <main className={classes.content}>

            <div className={classes.toolbar} />

            {children}

        </main>
    </>

}
