import { Guid } from "guid-typescript";

import { useFetchDataById } from "../../../tools/fetchRequest";
import { GetEventOverviewQuery } from "../../../models/api/admin/events/overview/GetEventOverviewQuery";
import { EventOverviewResponse } from "../../../models/api/admin/events/overview/EventOverviewResponse";

function emptyEventOverviewBuilder() {
    let event = new EventOverviewResponse();
    event.id = Guid.createEmpty();
    event.startAt = new Date();
    event.endAt = new Date();
    event.eventTypeName = "undefined";
    event.townName = "undefined";
    event.eventDays = [];
    return event;
}

const getEventQueryBuilder = (id: Guid) => new GetEventOverviewQuery({ id });

const useGetEventOverview = (id: Guid) => {

    return useFetchDataById(
        id,
        getEventQueryBuilder,
        emptyEventOverviewBuilder,
    );
};

export default useGetEventOverview;