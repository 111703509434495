import RouteName, { toString } from "./RouteName"

import * as H from "history";

const ROUTE_BASE = ""

export default class Route {

    private _name: RouteName;
    private _path: string;

    constructor(name: RouteName, path: string) {
        this._name = name
        this._path = path
    }

    public clone(): Route {
        return new Route(this._name, this._path)
    }

    public name(): string {
        return toString(this._name)
    }
    
    public fullPath(): string {
        return ROUTE_BASE + this._path
    }

    public isMatch(location: H.Location, parameters: Record<string, string> | null = null): boolean {
        let route = this.clone();
        if (parameters !== null) {
            route.setParameters(parameters);
        }
        const pathname = route.fullPath();
        return location.pathname === pathname;
    }

    public isParameters(): boolean {
        return this._path.indexOf(":") !== -1
    }

    public setParameters(params: Record<string, string>): Route {
        if (!this.isParameters()) {
            throw Error(`Route "${toString}" doesn"t need parameters`)
        }

        Object.keys(params).forEach(k => {
            const id = ":" + k
            const value = params[k]
            this._path = this._path.replace(id, value)
        })

        if (this.isParameters()) {
            throw Error(`Route named "${this.name()}" has some parameters not been setted`)
        }

        return this
    }

}
