import { useFetchArrayData } from "../../tools/fetchRequest";
import { EventTypeItemResponse } from "../../models/api/admin/event-types/EventTypeItemResponse";
import { ListEventTypesQuery } from "../../models/api/admin/event-types/ListEventTypesQuery";

const listQuery = new ListEventTypesQuery();

function useListEventTypes() {
    return useFetchArrayData<EventTypeItemResponse>(listQuery);
};

export default useListEventTypes;