import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class ListActivityTypesQuery implements IMessage {

    public constructor(init?: Partial<ListActivityTypesQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET";

    getUrl = () => `/admin/activity-types`;

    getQueryString = () => undefined;

    getBody = () => undefined;
}