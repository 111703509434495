import React from "react";
import { Guid } from "guid-typescript";

import { useFetchDataById } from "../../tools/fetchRequest";
import { GetTownQuery } from "../../models/api/admin/towns/GetTownQuery";
import { TownResponse } from "../../models/api/admin/towns/TownResponse";
import { DepartmentItemResponse } from "../../models/api/admin/departments/DepartmentItemResponse";

const getTownQueryBuilder = (id: Guid) => new GetTownQuery({ id });

const useGetTown = (id: Guid, departments: DepartmentItemResponse[] = []) => {

    const emptyTownBuilder = React.useCallback(() => {
        let town = new TownResponse();
        town.id = Guid.createEmpty();
        town.name = "";
        town.code = "";
        town.slug = "";
        town.departmentId = departments.length > 0
            ? departments[0].id
            : Guid.createEmpty();
        return town;
    }, [departments]);

    return useFetchDataById(
        id,
        getTownQueryBuilder,
        emptyTownBuilder,
    );
};

export default useGetTown;