import React from "react";
import { Redirect } from "react-router-dom";

import { Link } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { CentralPaperLayout } from "../../components/Layouts";
import { LockIcon } from "../../App/Theme";
import { useAuthContext } from "../../tools/Auth";
import { useNotificationContext } from "../../components/Notification";
import AskPasswordFormDialog from "./AskPasswordFormDialog";
import { routeFactory, RouteName } from "../../tools/Route";
import LoginForm from "./LoginForm";

const useStyles = makeStyles((theme: Theme) => createStyles({
  askPasswordLink: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(1) * 2,
    cursor: "pointer",
  },
}));

export default function LoginPage() {

  document.title = "Administration - Ferias40"

  const classes = useStyles();
  const { success, info } = useNotificationContext();
  const { isLogged, payload } = useAuthContext();

  const [askPasswordOpen, setAskPasswordOpen] = React.useState(false);

  if (isLogged && payload) {
    info(`Bienvenue ${payload.firstName} ${payload.lastName}`);
    return <Redirect to={routeFactory.fullPath(RouteName.Home)} />
  }

  return (
    <CentralPaperLayout
      title="Administration"
      icon={<LockIcon />}
    >
      <LoginForm />

      <Link
        onClick={() => setAskPasswordOpen(true)}
        className={classes.askPasswordLink}>
        Mot de passe oublié ?
      </Link>

      <AskPasswordFormDialog
        open={askPasswordOpen}
        onClose={() => setAskPasswordOpen(false)}
        onAfterSubmit={() => {
          success("Votre demande est effectuée, veuillez regarder vos email ...");
          setAskPasswordOpen(false);
        }}
      />

    </CentralPaperLayout>
  )
}
