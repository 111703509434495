import React from "react";
import { Guid } from "guid-typescript";

import ODataGrid, { ODataGridColumnsBuilder } from "../../components/ODataGrid";
import { TownItemResponse } from "../../models/api/admin/towns/ODataResponse[TownItemResponse]";
import { DepartmentIcon } from "../../App/Theme";
import { SearchTownsQuery } from "../../models/api/admin/towns/SearchTownsQuery";

interface TownsGridProps {
  loading: boolean;
  count: number;
  towns: TownItemResponse[];
  onEdit: (id: Guid) => void;

  query: SearchTownsQuery;
  fetchQuery: () => void;
}

export default function TownsGrid({ loading, count, towns, onEdit, query, fetchQuery }: TownsGridProps) {

  const columns = React.useMemo(() => buildColumns(onEdit), [onEdit]);

  return (
    <ODataGrid<TownItemResponse>
      count={count}
      data={towns}
      loading={loading}
      columnsProps={columns}

      query={query}
      fetchQuery={fetchQuery}
    />
  );
}

function buildColumns(onEdit: (d: Guid) => void) {

  const builder = new ODataGridColumnsBuilder<TownItemResponse>(TownItemResponse.Fields);

  builder
    .hiddenField(TownItemResponse.Fields.id)
    .hiddenField(TownItemResponse.Fields.departmentId)
    .setFieldProps(TownItemResponse.Fields.name, {
      label: "nom",
      position: 0,
      sortable: true,
      filterable: true,
    })
    .setFieldProps(TownItemResponse.Fields.slug, {
      position: 1
    })
    .setFieldProps(TownItemResponse.Fields.code, {
      type: "number",
      sortable: true,
      filterable: true,
    })
    .setFieldProps(TownItemResponse.Fields.departmentName, {
      label: "département",
      icon: <DepartmentIcon />,
      sortable: true,
      filterable: true,
    })
    .addEditColumn(town => onEdit(town.id))
    ;

  return builder.build();
}
