import React from "react";

import {
    Dialog, DialogTitle,
    DialogContent, DialogActions,
    Button,
} from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dialogPaper: {
        margin: theme.spacing(1) * 3,
    },
    title: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: 0,
        "& svg": {
            marginRight: theme.spacing(1),
            verticalAlign: "middle",
            marginTop: theme.spacing(1) * -0.5,
        },
        "& h6": {
            paddingBottom: theme.spacing(1) * 2,
            width: "100%",
            borderBottomColor: theme.palette.getContrastText(theme.palette.background.paper),
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
        }
    },
    content: {
        paddingTop: theme.spacing(1) * 3,
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(1) * 2,
    },
    closeBtn: {
        marginRight: "auto"
    },
}));

export interface ConfirmationDialogProps {
    icon?: React.ReactElement<SvgIconProps>;
    title: string;
    open: boolean;
    children: React.ReactNode;
    onClose: (() => void);
    onValid: (() => void);
}

export default function ConfirmationDialog({ open, onClose, onValid, title, icon, children }: ConfirmationDialogProps) {

    const classes = useStyles();

    const handleClose = React.useCallback((e: any) => {
        e.preventDefault()
        onClose()
    }, [onClose]);

    const handleValid = React.useCallback((e: any) => {
        e.preventDefault()
        onValid()
    }, [onValid]);

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={onClose}
            aria-labelledby="dialog-title"
            classes={{ paper: classes.dialogPaper }}
        >

            <DialogTitle id="dialog-title" className={classes.title}>
                {icon ? icon : null} {title}
            </DialogTitle>

            <DialogContent className={classes.content}>

                {children}

            </DialogContent>

            <DialogActions className={classes.actions}>

                <Button variant="contained" color="primary"
                    onClick={handleClose} className={classes.closeBtn}
                >
                    Fermer
                </Button>

                <Button variant="contained" color="secondary"
                    onClick={handleValid}
                >
                    Accepter
                </Button>

            </DialogActions>

        </Dialog>
    )
}
