import { Guid } from "guid-typescript";
import { IField } from "../../../IField";

//Generated code, do not modify

export class EventDayProgramResponse {
    id!: Guid;
    eventId!: Guid;
    date!: Date;
    endAt!: string;
    name!: string;
    townId!: Guid;
    townName!: string;
    eventTypeId!: Guid;
    eventTypeName!: string;
    busTimes!: string[];
    activities!: Activity[];

    public static Fields = {
        id: { name: "id", type: "Guid" },
        eventId: { name: "eventId", type: "Guid" },
        date: { name: "date", type: "Date" },
        endAt: { name: "endAt", type: "string" },
        name: { name: "name", type: "string" },
        townId: { name: "townId", type: "Guid" },
        townName: { name: "townName", type: "string" },
        eventTypeId: { name: "eventTypeId", type: "Guid" },
        eventTypeName: { name: "eventTypeName", type: "string" },
        busTimes: { name: "busTimes", type: "string[]" },
        activities: { name: "activities", type: "Activity[]" },
    } as Record<"id" | "eventId" | "date" | "endAt" | "name" | "townId" | "townName" | "eventTypeId" | "eventTypeName" | "busTimes" | "activities", IField>;
}

export class Activity {
    id!: Guid;
    eventId!: Guid;
    eventDayId!: Guid;
    activityTypeId!: Guid;
    time!: string;
    description!: string;
    activityGroupId!: Guid;
    activityGroupName!: string;
    activityTypeName!: string;
    isArchived!: boolean;
    coverId!: Guid | null;
    name!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        eventId: { name: "eventId", type: "Guid" },
        eventDayId: { name: "eventDayId", type: "Guid" },
        activityTypeId: { name: "activityTypeId", type: "Guid" },
        time: { name: "time", type: "string" },
        description: { name: "description", type: "string" },
        activityGroupId: { name: "activityGroupId", type: "Guid" },
        activityGroupName: { name: "activityGroupName", type: "string" },
        activityTypeName: { name: "activityTypeName", type: "string" },
        isArchived: { name: "isArchived", type: "boolean" },
        coverId: { name: "coverId", type: "Guid" },
        name: { name: "name", type: "string" },
    } as Record<"id" | "eventId" | "eventDayId" | "activityTypeId" | "time" | "description" | "activityGroupId" | "activityGroupName" | "activityTypeName" | "isArchived" | "coverId" | "name", IField>;
}
