import React from "react";
import { Guid } from "guid-typescript";

import Paper from "../../components/Paper";
import CornerButton from "../../components/CornerButton";
import { CentralColumnLayout } from "../../components/Layouts";
import { AddIcon, ActivityTypeIcon } from "../../App/Theme";
import useListActivityTypes from "./useListActivityTypes";
import ActivityTypesGrid from "./ActivityTypesGrid";
import ActivityTypeFormDialog from "./ActivityTypeFormDialog";
import { useStateDelay } from "../../tools/useStateDelay";
import { useNotificationContext } from "../../components/Notification";

export default function ListActivityTypesPage() {

  document.title = "Liste des types d'activités - Administration - Ferias40";

  const { success } = useNotificationContext();

  const emptyGuid = Guid.createEmpty();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogId, setDialogId, setDialogIdDelay] = useStateDelay(emptyGuid);

  const [loading, activityType, updateActivityTypes] = useListActivityTypes();

  const handleClose = React.useCallback(() => {
    setDialogOpen(false);
    setDialogIdDelay(emptyGuid);
  }, [emptyGuid, setDialogIdDelay]);

  const handleCreate = React.useCallback(() => {
    setDialogId(emptyGuid);
    setDialogOpen(true);
  }, [emptyGuid, setDialogId]);

  const handleEdit = React.useCallback((id: Guid) => {
    setDialogId(id);
    setDialogOpen(true);
  }, [setDialogId]);

  const handleAfterSave = React.useCallback(() => {
    updateActivityTypes();
    success("Le type d'activité a bien été enregistrée");
  }, [updateActivityTypes, success]);

  return (
    <CentralColumnLayout>

      <CornerButton
        tooltip="Ajouter un type d'activité"
        icon={<AddIcon />}
        onClick={handleCreate}
      />

      <Paper
        name="Liste des types d'activités"
        icon={<ActivityTypeIcon />}
      >
        <ActivityTypesGrid
          activityTypes={activityType}
          loading={loading}
          onEdit={handleEdit}
        />
      </Paper>

      <ActivityTypeFormDialog
        open={dialogOpen}
        onClose={handleClose}
        activityTypeId={dialogId}
        onAfterSave={handleAfterSave}
      />

    </CentralColumnLayout>
  );
}
