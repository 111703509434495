import React from "react";
import { useParams } from "react-router-dom";
import { Guid } from "guid-typescript";

import { Button, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { CentralColumnLayout } from "../../../components/Layouts";
import CornerButton from "../../../components/CornerButton";
import useGetEventDayProgram from "./useGetEventDayProgram";
import { routeFactory, RouteName } from "../../../tools/Route";
import { PreviousIcon, AddIcon } from "../../../App/Theme";
import Header from "./Header";
import ActivityCard from "./ActivityCard";
import { useStateDelay } from "../../../tools/useStateDelay";
import ActivityFormDialog from "../../Activity/ActivityFormDialog";
import { useNotificationContext } from "../../../components/Notification";
import { format } from "../../../tools/DateExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    rowTop: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    rowBottom: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: theme.spacing(2),
    },
    right: {
        marginLeft: "auto",
    },
}));

export default function EventDayProgramPage() {

    const classes = useStyles();

    const { success } = useNotificationContext();

    const emptyGuid = Guid.createEmpty();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogActivtyId, setDialogActivtyId, setDialogActivtyIdDelay] = useStateDelay(emptyGuid);

    const params = useParams<{ eventDayId: string }>();
    const [eventDayId, setEventDayId] = React.useState(Guid.parse(params.eventDayId));
    const [loading, eventDay] = useGetEventDayProgram(eventDayId);

    document.title = loading
        ? "Chargement du programme ... - Administration - Ferias40"
        : `${eventDay.townName}, ${eventDay.eventTypeName} : programme du ${format(eventDay.date, "dddd Do MMMM YYYY")}`;

    const route = React.useMemo(() => {
        return routeFactory
            .get(RouteName.EventOverview)
            .setParameters({
                eventId: eventDay.eventId.toString(),
                eventDayId: eventDay.eventTypeId.toString(),
            })
            .fullPath();
    }, [eventDay]);

    const backButton = (
        <Button
            className={classes.right}
            color="primary"
            variant="contained"
            startIcon={<PreviousIcon />}
            href={route}
        >
            Retour à l'évènement
        </Button>
    );

    const reloadProgram = React.useCallback(() => {
        setEventDayId(Guid.parse(eventDayId.toString()));
    }, [eventDayId]);

    const handleClose = React.useCallback(() => {
        setDialogOpen(false);
        setDialogActivtyIdDelay(emptyGuid);
    }, [emptyGuid, setDialogActivtyIdDelay]);

    const handleCreate = React.useCallback(() => {
        setDialogActivtyId(emptyGuid);
        setDialogOpen(true);
    }, [emptyGuid, setDialogActivtyId]);

    const handleEdit = React.useCallback((id: Guid) => {
        setDialogActivtyId(id);
        setDialogOpen(true);
    }, [setDialogActivtyId]);

    const handleAfterSave = React.useCallback(() => {
        reloadProgram();
        success("L'activité a bien été enregistrée");
    }, [reloadProgram, success]);

    return (
        <CentralColumnLayout size="md">

            <div className={classes.rowTop}>
                {loading
                    ?
                    <Typography component="h1" variant="h5">
                        Chargement de l'évènement ...
                        </Typography>
                    :
                    <Typography component="h1" variant="h5">
                        {eventDay.townName}, {eventDay.eventTypeName}
                    </Typography>
                }
                {backButton}
            </div>

            <Header
                loading={loading}
                eventDayProgram={eventDay}
            />

            {eventDay.activities.map(activity =>
                <ActivityCard
                    key={activity.id.toString()}
                    activity={activity}
                    onEdit={handleEdit}
                    reloadProgram={reloadProgram}
                />
            )}

            <div className={classes.rowBottom}>
                {backButton}
            </div>

            <CornerButton
                tooltip="Ajouter une activité"
                icon={<AddIcon />}
                onClick={handleCreate}
            />

            <ActivityFormDialog
                open={dialogOpen}
                onClose={handleClose}
                eventDayId={loading ? emptyGuid : eventDay.id}
                activityId={dialogActivtyId}
                onAfterSave={handleAfterSave}
            />

        </CentralColumnLayout>
    );
}
