import React from "react";
import { Guid } from "guid-typescript";

import { useFetchDataById } from "../../tools/fetchRequest";
import { GetEventQuery } from "../../models/api/admin/events/GetEventQuery";
import { EventResponse } from "../../models/api/admin/events/EventResponse";
import { TownItemResponse } from "../../models/api/admin/towns/ODataResponse[TownItemResponse]";
import { EventTypeItemResponse } from "../../models/api/admin/event-types/EventTypeItemResponse";

const getEventQueryBuilder = (id: Guid) => new GetEventQuery({ id });

const useGetEvent = (
    id: Guid,
    towns: TownItemResponse[] = [],
    eventTypes: EventTypeItemResponse[] = []
) => {

    const emptyEventBuilder = React.useCallback(() => {
        let event = new EventResponse();
        event.id = Guid.createEmpty();
        event.areDatesValidated = false;
        event.startAt = new Date();
        event.endAt = new Date();
        event.eventTypeId = eventTypes.length > 0
            ? eventTypes[0].id
            : Guid.createEmpty();
        event.townId = towns.length > 0
            ? towns[0].id
            : Guid.createEmpty();
        return event;
    }, [towns, eventTypes]);

    return useFetchDataById(
        id,
        getEventQueryBuilder,
        emptyEventBuilder,
    );
};

export default useGetEvent;