import { Guid } from "guid-typescript";
import { IField } from "../../../IField";

//Generated code, do not modify

export class EventOverviewResponse {
    id!: Guid;
    townId!: Guid;
    townName!: string;
    eventTypeId!: Guid;
    eventTypeName!: string;
    startAt!: Date;
    endAt!: Date;
    areDatesValidated!: boolean;
    eventDays!: EventDay[];
    isArchived!: boolean;
    coverId!: Guid | null;
    facebookUrl!: string;
    officialUrl!: string;
    busUrl!: string;
    contactEmail!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        townId: { name: "townId", type: "Guid" },
        townName: { name: "townName", type: "string" },
        eventTypeId: { name: "eventTypeId", type: "Guid" },
        eventTypeName: { name: "eventTypeName", type: "string" },
        startAt: { name: "startAt", type: "Date" },
        endAt: { name: "endAt", type: "Date" },
        areDatesValidated: { name: "areDatesValidated", type: "boolean" },
        eventDays: { name: "eventDays", type: "EventDay[]" },
        isArchived: { name: "isArchived", type: "boolean" },
        coverId: { name: "coverId", type: "Guid" },
        facebookUrl: { name: "facebookUrl", type: "string" },
        officialUrl: { name: "officialUrl", type: "string" },
        busUrl: { name: "busUrl", type: "string" },
        contactEmail: { name: "contactEmail", type: "string" },
    } as Record<"id" | "townId" | "townName" | "eventTypeId" | "eventTypeName" | "startAt" | "endAt" | "areDatesValidated" | "eventDays" | "isArchived" | "coverId" | "facebookUrl" | "officialUrl" | "busUrl" | "contactEmail", IField>;
}

export class EventDay {
    id!: Guid;
    eventId!: Guid;
    date!: Date;
    name!: string;
    endAt!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        eventId: { name: "eventId", type: "Guid" },
        date: { name: "date", type: "Date" },
        name: { name: "name", type: "string" },
        endAt: { name: "endAt", type: "string" },
    } as Record<"id" | "eventId" | "date" | "name" | "endAt", IField>;
}
