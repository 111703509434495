import React from "react";
import clsx from "clsx";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, lighten } from "@material-ui/core/styles";
import { amber, green } from '@material-ui/core/colors';

import {
    Snackbar, IconButton, SnackbarContent
} from "@material-ui/core";

import {
    CloseIcon,
    InfoIcon, SuccessIcon,
    WarningIcon, ErrorIcon
} from "../../App/Theme";

const useStyles = makeStyles((theme: Theme) => createStyles({
    snackbar: {
        marginBottom: theme.spacing(2),
    },
    content: {
        backgroundColor: lighten(theme.palette.background.paper, 0.2),
        color: theme.palette.text.primary,
    },
    messageContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    messageIcon: {
        fontSize: 20,
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    closeIcon: {
        padding: theme.spacing(0.5),
    },
    success: {
        color: green[600],
    },
    error: {
        color: theme.palette.error.dark,
    },
    info: {
        color: theme.palette.primary.main,
    },
    warning: {
        color: amber[700],
    },
}));

export type NotificationType = "info" | "success" | "warning" | "error";

export interface NotificationProps {
    open: boolean,
    type: NotificationType,
    message: string,
    onClose: () => void,
    autoHideDuration?: number,
}

function getIconFromType(type: NotificationType) {
    switch (type) {
        case "info": return InfoIcon;
        case "success": return SuccessIcon;
        case "warning": return WarningIcon;
        case "error": return ErrorIcon;
        default: return InfoIcon;
    }
}

export default function Notification({ open, type, message, onClose, autoHideDuration }: NotificationProps) {

    const classes = useStyles();
    const Icon = getIconFromType(type);

    return (
        <Snackbar
            open={open}
            onClose={onClose}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            className={classes.snackbar}
        >
            <SnackbarContent
                className={classes.content}
                aria-describedby="notification-id"
                message={
                    <span id="notification-id" className={classes.messageContainer}>
                        <Icon className={clsx(classes.messageIcon, classes[type])} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>,
                ]}
            />
        </Snackbar>
    );

}
