import React from "react";
import { Guid } from "guid-typescript";

import { EventOverviewResponse, EventDay } from "../../../models/api/admin/events/overview/EventOverviewResponse";
import Paper from "../../../components/Paper";
import { EventIcon, ProgramIcon } from "../../../App/Theme";
import DataGrid, { DataGridColumnsBuilder } from "../../../components/DataGrid";
import { EventDayFormDialog } from "./../../EventDay";
import { useStateDelay } from "../../../tools/useStateDelay";
import { useNotificationContext } from "../../../components/Notification";
import { routeFactory, RouteName } from "../../../tools/Route";
import { IconButton } from "@material-ui/core";
import { format } from "../../../tools/DateExtension";

export interface EventDaysProps {
    loading: boolean,
    event: EventOverviewResponse,
    reloadEvent: () => void;
}

export default function EventDays({ loading, event, reloadEvent }: EventDaysProps) {

    const { success } = useNotificationContext();
    const eventDays = React.useMemo(() => event.eventDays ? event.eventDays : [], [event]);
    const emptyGuid = Guid.createEmpty();
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogEventDayId, setDialogEventDayId, setDialogEventDayIdDelay] = useStateDelay(emptyGuid);

    const handleEdit = React.useCallback((id: Guid) => {
        setDialogEventDayId(id);
        setDialogOpen(true);
    }, [setDialogEventDayId]);

    const handleClose = React.useCallback(() => {
        setDialogOpen(false);
        setDialogEventDayIdDelay(emptyGuid);
    }, [emptyGuid, setDialogEventDayIdDelay]);

    const handleAfterSave = React.useCallback(() => {
        reloadEvent();
        success("La journée a bien été mise à jour");
    }, [reloadEvent, success]);

    const columns = React.useMemo(() => buildColumns(handleEdit), [handleEdit]);

    let title = "Chargement de l'évènement ...";
    if (!loading) {
        const { startAt, endAt } = event;
        const startFormat = startAt.getMonth() === endAt.getMonth() ? "dddd Do" : "dddd Do MMMM"
        const endFormat = "dddd Do MMMM YYYY";
        title = `Journées du ${format(startAt, startFormat)} au ${format(endAt, endFormat)}`
    }

    return (
        <>
            <Paper
                name={title}
                icon={<EventIcon />}
                loading={loading}
            >
                <DataGrid<EventDay>
                    data={eventDays}
                    columnsProps={columns}
                />
            </Paper>

            <EventDayFormDialog
                open={dialogOpen}
                onClose={handleClose}
                eventDayId={dialogEventDayId}
                onAfterSave={handleAfterSave}
            />
        </>
    );
}

function buildColumns(onEdit: (d: Guid) => void) {

    const builder = new DataGridColumnsBuilder<EventDay>(EventDay.Fields);

    builder
        .hiddenField(EventDay.Fields.id)
        .hiddenField(EventDay.Fields.eventId)
        .setFieldProps(EventDay.Fields.date, {
            position: 0,
        })
        .setFieldProps(EventDay.Fields.endAt, {
            label: "heure de fin",
            position: 1,
        })
        .setFieldProps(EventDay.Fields.name, {
            label: "nom",
        })
        .addEditColumn(eventDay => onEdit(eventDay.id))
        .addColumn({
            padding: "checkbox",
            render: (eventDay: EventDay) => {
                const route = routeFactory
                    .get(RouteName.EventDayProgram)
                    .setParameters({
                        eventId: eventDay.eventId.toString(),
                        eventDayId: eventDay.id.toString()
                    })
                    .fullPath();
                return (
                    <IconButton href={route} color="default">
                        <ProgramIcon />
                    </IconButton>
                );
            }
        })
        ;

    return builder.build();
}
