import React from "react";
import { Guid } from "guid-typescript";

import FormDialog from "../../components/FormDialog";
import { EventIcon } from "../../App/Theme";
import { EventDayResponse } from "../../models/api/admin/event-days/EventDayResponse";
import { UpdateEventDayCommand } from "../../models/api/admin/event-days/UpdateEventDayCommand";
import useGetEventDay from "./useGetEventDay";
import { FormFieldsBuilder } from "../../components/FormField";
import { format } from "../../tools/DateExtension";

interface EventDayFormDialogProps {
    open: boolean;
    eventDayId: Guid;
    onClose: () => void;
    onAfterSave: () => void;
}

export default React.memo(({ open, eventDayId, onClose, onAfterSave }: EventDayFormDialogProps) => {

    const [loading, eventDay] = useGetEventDay(eventDayId);

    const title = loading
        ? "Chargement ..."
        : `Journée du ${format(eventDay.date, "dddd Do MMMM YYYY")}`;

    const fields = React.useMemo(() => buildFields(), []);

    const buildMessage = React.useCallback((data: EventDayResponse) => {
        return new UpdateEventDayCommand(data)
    }, []);

    return (
        <FormDialog<EventDayResponse>
            title={title}
            icon={<EventIcon />}
            open={open}
            onClose={onClose}
            loading={loading}

            form={{
                submitLabel: "éditer",
                fields,
                initial: eventDay,
                onSuccess: onAfterSave,
                onPrepareMessage: buildMessage,
            }}
        />
    );
});

function buildFields() {

    const builder = new FormFieldsBuilder<EventDayResponse>(EventDayResponse.Fields);

    builder
        .hiddenField(EventDayResponse.Fields.id)
        .hiddenField(EventDayResponse.Fields.date)
        .setFieldProps(EventDayResponse.Fields.endAt, {
            type: "time",
            label: "Heure de fermeture",
            placeholder: "ex : 20:30",
            position: 0,
        })
        .setFieldProps(EventDayResponse.Fields.name, {
            label: "nom",
            position: 1,
            required: false,
        })
        .setFieldProps(EventDayResponse.Fields.busTimes, {
            type: "time[]",
            label: "Ajouter des horaires de bus",
            placeholder: "Aucun horaire de bus défini",
            required: false,
        });

    return builder.build();
}
