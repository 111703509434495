import React from "react";

export function useStateDelay<T>(defaultValue: T, ms: number = 200)
    : [T, (v: T) => void, (v: T) => void] {

    const [value, setValue] = React.useState(defaultValue);

    const setValueDelay = React.useCallback(
        (v: T) => setTimeout(() => setValue(v), ms),
        [ms]
    );

    return [value, setValue, setValueDelay];
}