enum RouteName {
    Login,
    ResetPassword,

    Home,
    Profile,
    Settings,
    Contact,

    DepartmentList,
    TownList,
    ActivityGroupList,
    ActivityTypeList,
    EventTypeList,
    EventList,

    EventOverview,
    EventDayProgram,
}

export default RouteName;

export function toString(name: RouteName): string {
    return RouteName[name];
}