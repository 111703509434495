import { Guid } from "guid-typescript";
import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class GetTownQuery implements IMessage {
    id!: Guid;

    public constructor(init?: Partial<GetTownQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET";

    getUrl = () => `/admin/towns/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => undefined;
}