import React from "react";

import { CentralColumnLayout } from "../../components/Layouts";
import { ContactIcon, SendIcon } from "../../App/Theme";
import { useNotificationContext } from "../../components/Notification";
import { FormFieldsBuilder } from "../../components/FormField";
import { SendAdminContactEmailCommand } from "../../models/api/admin/contact/SendAdminContactEmailCommand";
import { IField } from "../../models/api/IField";
import FormPaper from "../../components/FormPaper";

class ContactResponse {
  to!: string;
  subject!: string;
  body!: string;

  public static Fields = {
    to: { name: "to", type: "string" },
    subject: { name: "subject", type: "string" },
    body: { name: "body", type: "string" }
  } as Record<"to" | "subject" | "body", IField>;
}

function emptyResponseBuilder() {
  let response = new ContactResponse();
  response.to = "";
  response.subject = "";
  response.body = "";
  return response;
}

export default function ContactPage() {

  document.title = "Contact - Administration - Ferias40";

  const { success } = useNotificationContext();

  const fields = React.useMemo(() => buildFields(), []);
  const [initial, setInitial] = React.useState(emptyResponseBuilder());

  const buildMessage = React.useCallback((data: ContactResponse) => {
    return new SendAdminContactEmailCommand(data);
  }, []);

  const handleSuccess = React.useCallback(() => {
    success("Message envoyé avec succès");
    setInitial(emptyResponseBuilder());
  }, [success]);

  return (
    <CentralColumnLayout size="sm">

      <FormPaper<ContactResponse>
        title="Formulaire de contact"
        icon={<ContactIcon />}

        form={{
          submitLabel: "Envoyer",
          submitIcon: <SendIcon />,
          fields,
          initial: initial,
          onSuccess: handleSuccess,
          onPrepareMessage: buildMessage,
        }}
      />

    </CentralColumnLayout>
  );
}

function buildFields() {

  const builder = new FormFieldsBuilder<ContactResponse>(ContactResponse.Fields);

  builder
    .setFieldProps(ContactResponse.Fields.to, {
      label: "Destinataire",
      placeholder: "ex : contact@ferias40.fr",
      type: "email",
    })
    .setFieldProps(ContactResponse.Fields.subject, {
      label: "Objet",
    })
    .setFieldProps(ContactResponse.Fields.body, {
      label: "Contenu",
      type: "richtext"
    })
    ;

  return builder.build();
}
