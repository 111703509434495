import React from "react";
import { Guid } from "guid-typescript";
import axios, { AxiosRequestConfig } from "axios";

import { authToken } from "./Auth";
import Serializer from "./Serializer";
import { IMessage } from "../models/api/IMessage";
import { IODataResponse } from "../models/api/OData";

declare var API_BASE: any;

if (API_BASE === "{{API_BASE}}") {
    API_BASE = "https://localhost:5000";
}

// --------------------------------------------------------

const serializer = new Serializer();

function serializeBody(message: IMessage): string | undefined {
    return message.getBody() ? serializer.serialize(message.getBody()) : undefined;
}

function serializeQueryString(message: IMessage): string | undefined {
    const queryString = message.getQueryString();
    if (queryString === undefined) return undefined;

    const qsKeys = Object.keys(queryString);
    if (qsKeys.length === 0) return undefined;

    let qsResults = [] as string[];
    qsKeys.forEach(k => {
        let kStr = k;

        const v = queryString[k];
        if (v === undefined ||
            v === null) {
            return;
        }

        const vStr = serializer.serialize(v);
        if (vStr === `"${undefined}"` ||
            vStr === `"${null}"`) {
            return;
        }

        qsResults.push(`${kStr}=${vStr}`);
    });

    if (qsResults.length === 0) return undefined;

    return qsResults.join("&");
}

function buildAxiosRequest(
    method: string,
    url: string,
    queryString: string | undefined = undefined,
    body: string | undefined = undefined)

    : AxiosRequestConfig {

    const requestUrl = url + (queryString !== undefined ? `?${queryString}` : "");

    let axiosRequest = ({
        baseURL: API_BASE,
        url: requestUrl,
        method: method,
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true,
    }) as AxiosRequestConfig;

    axiosRequest.headers.Authorization = "Bearer " + authToken.get();

    if (body !== undefined) {
        axiosRequest.data = body;
    }

    return axiosRequest;
}

export async function fetchRequest<TResponse>(message: IMessage): Promise<TResponse> {
    const queryString = serializeQueryString(message);
    const body = serializeBody(message);

    return fetchRequestProps(
        message.getMethod(),
        message.getUrl(),
        queryString, body
    );
}

export async function fetchRequestProps<TResponse>(
    method: string,
    url: string,
    queryString: string | undefined = undefined,
    body: string | undefined = undefined): Promise<TResponse> {

    try {
        // console.log("fetchRequest", method, url, queryString, body) // TODO debug
        return await axiosRequest<TResponse>(method, url, queryString, body);
    }
    catch (e) {
        if (e.response && e.response.status === 498) {
            const newToken = await fetchRequestProps<{ accessToken: string }>("GET", "/admin/users/access-token");
            authToken.set(newToken.accessToken);
            return axiosRequest<TResponse>(method, url, queryString, body);
        }
        throw e;
    }
}

async function axiosRequest<TResponse>(
    method: string,
    url: string,
    queryString: string | undefined = undefined,
    body: string | undefined = undefined)

    : Promise<TResponse> {

    let axiosRequest = buildAxiosRequest(method, url, queryString, body);
    const response = await axios.request<TResponse>(axiosRequest);
    const data = serializer.deserialize<TResponse>(JSON.stringify(response.data));
    return data;
}

// --------------------------------------------------------

export function useMessage<TResponse>(
    message: IMessage,
    initialValue: TResponse
): [boolean, TResponse, () => void] {

    const [state, setState] = React.useState({
        loading: true,
        data: initialValue,
        error: null as Error | null,
    });

    const isMounted = React.useRef(true);
    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, [])

    const _updateData = React.useCallback(async () => {
        try {
            setState(s => {
                return { ...s, loading: true }
            });
            const data = await fetchRequest<TResponse>(message);
            if (isMounted.current) {
                setState({ data, loading: false, error: null });
            }
        }
        catch (e) {
            setState(s => {
                return { data: s.data, loading: false, error: e }
            });
        }
    }, [message]);

    React.useEffect(
        () => {
            _updateData();
        },
        [_updateData]
    );

    const updateData = React.useCallback(
        () => {
            _updateData();
        },
        [_updateData]
    );

    if (state.error) {
        throw state.error;
    }

    return [state.loading, state.data, updateData];
}

// --------------------------------------------------------

export function useFetchData<TResponse>(message: IMessage) {
    return useMessage<TResponse | null>(message, null);
}

export function useFetchArrayData<TResponse>(message: IMessage) {
    return useMessage<TResponse[]>(message, []);
}

export function useFetchOData<T>(message: IMessage)
    : [boolean, T[], number, () => void] {

    const [loading, data, updateData] =
        useMessage<IODataResponse<T>>(message, { d: { __count: 0, results: [] } });

    return [
        loading,
        data.d.results,
        parseInt(data.d.__count.toString(), 10),
        updateData,
    ];
}

// --------------------------------------------------------

export function useFetchDataById<TResponse>(
    id: Guid,
    messageBuilder: (id: Guid) => IMessage,
    defaultValueInitializer: () => TResponse
): [boolean, TResponse] {

    const isMounted = React.useRef(true);
    const [state, setState] = React.useState({
        loading: true,
        data: defaultValueInitializer(),
        error: null as Error | null,
    });

    React.useEffect(() => {
        return () => {
            isMounted.current = false;
        }
    }, []);

    React.useEffect(() => {
        const fetchData = (async () => {
            if (id.isEmpty()) {
                setState({
                    loading: false,
                    data: defaultValueInitializer(),
                    error: null,
                });
            }
            else {
                setState(s => {
                    return { ...s, loading: true }
                });
                try {
                    const query = messageBuilder(id);
                    // console.log("useFetchDataById", query) // debug
                    const d = await fetchRequest<TResponse>(query);
                    if (isMounted.current) {
                        setState({ loading: false, data: d, error: null });
                    }
                }
                catch (e) {
                    setState(s => {
                        return { loading: false, data: s.data, error: e }
                    });
                }
            }
        });
        fetchData();
    }, [id, defaultValueInitializer, messageBuilder]);

    if (state.error) {
        throw state.error;
    }

    return [state.loading, state.data];
}