import { Guid } from "guid-typescript";
import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class UpdateEventTypeCommand implements IMessage {
    id!: Guid;
    name!: string;

    public constructor(init?: Partial<UpdateEventTypeCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT";

    getUrl = () => `/admin/event-types/${this.id.toString()}`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            name: this.name,
        };
    }
}