import React from "react";
import { Guid } from "guid-typescript";

import DataGrid, { DataGridColumnsBuilder } from "../../components/DataGrid";
import { ActivityTypeItemResponse } from "../../models/api/admin/activity-types/ActivityTypeItemResponse";
import { ActivityGroupIcon } from "../../App/Theme";

interface ActivityTypesGridProps {
  loading: boolean;
  activityTypes: ActivityTypeItemResponse[];
  onEdit: (id: Guid) => void;
}

export default React.memo(({ loading, activityTypes, onEdit }: ActivityTypesGridProps) => {

  const columns = React.useMemo(() => buildColumns(onEdit), [onEdit]);

  return (
    <DataGrid<ActivityTypeItemResponse>
      data={activityTypes}
      loading={loading}
      columnsProps={columns}
    />
  );
});

function buildColumns(onEdit: (d: Guid) => void) {

  const builder = new DataGridColumnsBuilder<ActivityTypeItemResponse>(ActivityTypeItemResponse.Fields);

  builder
    .hiddenField(ActivityTypeItemResponse.Fields.id)
    .hiddenField(ActivityTypeItemResponse.Fields.activityGroupId)
    .setFieldProps(ActivityTypeItemResponse.Fields.name, {
      label: "nom",
      position: 0
    })
    .setFieldProps(ActivityTypeItemResponse.Fields.activityGroupName, {
      label: "groupe",
      icon: <ActivityGroupIcon />,
    })
    .setFieldProps(ActivityTypeItemResponse.Fields.filterable, {
      label: "filtrable ?",
      padding: "checkbox",
      align: "center",
    })
    .setFieldProps(ActivityTypeItemResponse.Fields.isDefaultSelected, {
      label: "filtrage activé ?",
      padding: "checkbox",
      align: "center",
    })
    .addEditColumn(activityType => onEdit(activityType.id))
    ;
  return builder.build();
}
