import { Guid } from "guid-typescript";
import { IMessage } from "../../../IMessage";

//Generated code, do not modify

export class DeleteTimelineMonthCoverCommand implements IMessage {
    timelineMonthId!: Guid;

    public constructor(init?: Partial<DeleteTimelineMonthCoverCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "DELETE";

    getUrl = () => `/admin/timeline-months/${this.timelineMonthId.toString()}/cover`;

    getQueryString = () => undefined;

    getBody = () => undefined;
}