import { Guid } from "guid-typescript";
import { IField } from "../../IField";

//Generated code, do not modify

export class ODataResponse<TownItemResponse> {
    results!: TownItemResponse[];
    count!: number;

    public static Fields = {
        results: { name: "results", type: "TownItemResponse[]" },
        count: { name: "count", type: "number" },
    } as Record<"results" | "count", IField>;
}

export class TownItemResponse {
    id!: Guid;
    code!: string;
    name!: string;
    slug!: string;
    departmentId!: Guid;
    departmentName!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        code: { name: "code", type: "string" },
        name: { name: "name", type: "string" },
        slug: { name: "slug", type: "string" },
        departmentId: { name: "departmentId", type: "Guid" },
        departmentName: { name: "departmentName", type: "string" },
    } as Record<"id" | "code" | "name" | "slug" | "departmentId" | "departmentName", IField>;
}
