import moment from "moment";
import "moment/locale/fr";
moment.locale("fr");

export function toUtcDate(date: Date): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
}

export function lastDayOfYear(year?: number): Date {
    year = year ? year : new Date().getFullYear();
    return new Date(year, 11, 31)
}

export function timeToDate(time: string, date?: Date): Date {
    const parts = time.split(":").map(x => parseInt(x, 10));
    const vmoment = moment(date ? date : new Date());
    vmoment.set("hours", parts[0]);
    vmoment.set("minutes", parts[1]);
    vmoment.set("seconds", 0);
    return vmoment.toDate();
}

export function format(date: Date, format: string): string {
    return moment(date).format(format);
}