import React from "react";
import { Guid } from "guid-typescript";

import { Typography, Fab, CircularProgress } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { ArchiveIcon, CloseIcon } from "../../App/Theme";
import { GetBase64FileQuery } from "../../models/api/admin/images/GetBase64FileQuery";
import { Base64FileResponse } from "../../models/api/admin/images/Base64FileResponse";
import { fetchRequest } from "../../tools/fetchRequest";

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(1) * 3,
        marginBottom: theme.spacing(1) * 3,
        backgroundColor: theme.palette.background.default,
        borderWidth: 3,
        borderColor: theme.palette.common.white,
        borderStyle: "dashed",
        cursor: "pointer",
        "&:hover .title": {
            opacity: 1,
        },
        "&:hover .image": {
            opacity: 0.1,
        },
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    image: {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 1,
    },
    title: {
        textAlign: "center",
        opacity: 0.3,
    },
    delete: {
        position: "absolute",
        top: theme.spacing(1) * -3,
        right: theme.spacing(1) * -3,
    },
}));

export interface ImageSelectorProps {
    inputRef?: React.RefObject<HTMLInputElement>,
    width?: number,
    height?: number,
    imageId?: Guid | null,
    acceptedTypes?: string[],
    maxSize?: number,
    name: string,
    onChange: ((file: File) => void),
    onDelete?: (() => void),
    deleteLoading?: boolean,
    hidden?: boolean,
}


function ImageSelector(props: ImageSelectorProps) {

    const classes = useStyles();
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [imageStyle, setImageStyle] = React.useState<React.CSSProperties>({});

    React.useEffect(() => {
        const fetchImage = (async (id: Guid) => {
            const query = new GetBase64FileQuery({ id });
            const response = await fetchRequest<Base64FileResponse>(query);
            const url = `data:${response.mimeType};base64,${response.base64Content}`;

            setImageStyle({ backgroundImage: `url(${url})` });

        });

        if (props.imageId) {
            fetchImage(props.imageId);
        }
        else {
            setImageStyle({});
        }
    }, [props.imageId]);

    const acceptedTypes = React.useMemo(() =>
        props.acceptedTypes ? props.acceptedTypes : ["image/png", "image/jpeg"]
        , [props.acceptedTypes]);

    const maxSize = React.useMemo(() =>
        props.maxSize ? props.maxSize : 1024 * 1024 * 3
        , [props.maxSize]);

    const handleChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files !== null && files.length === 1) {
            const file = files[0];
            if (!acceptedTypes.includes(file.type)) {
                alert(`Inalid image type, it must be in [${acceptedTypes.join(", ")}] but select image type : ${file.type}`);
                return;
            }
            if (file.size > maxSize) {
                alert(`Inalid image size, it must be less than ${maxSize} but select image size : ${file.size}`);
                return;
            }
            props.onChange(file);
        }
    }, [acceptedTypes, maxSize, props]);

    const openClientFileDialog = React.useCallback(() => {
        if (props.inputRef && props.inputRef.current) {
            props.inputRef.current.click();
        }
        else if (inputRef.current) {
            inputRef.current.click();
        }
    }, [props.inputRef, inputRef]);


    const width = props.width ? props.width : "100%"
    const height = props.height ? props.height : 150
    const hidden = props.hidden ? props.hidden : false
    const deleteLoading = props.deleteLoading ? props.deleteLoading : false

    let sectionStyle = { width, height } as React.CSSProperties
    if (hidden) {
        sectionStyle.display = "none"
    }

    return (
        <section
            className={classes.container}
            style={sectionStyle}
            onClick={openClientFileDialog}
        >
            <input
                ref={props.inputRef ? props.inputRef : inputRef}
                type="file"
                multiple={false}
                accept={acceptedTypes.join(", ")}
                onChange={handleChange}
                style={{ display: "none" }}
            />

            <div className={classes.image + " image"} style={imageStyle}>
            </div>

            <Typography variant="h6" className={classes.title + " title"}>
                {props.name}
                <div><ArchiveIcon /></div>
            </Typography>

            {
                props.onDelete && props.imageId ?
                    <Fab
                        size="small"
                        color="secondary"
                        className={classes.delete}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            props.onDelete && props.onDelete();
                        }}
                    >
                        {deleteLoading ? <CircularProgress size={18} /> : <CloseIcon />}
                    </Fab>
                    : null
            }

        </section>
    )
}

export default ImageSelector;
