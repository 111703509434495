import { Guid } from "guid-typescript";

import { useFetchDataById } from "../../tools/fetchRequest";
import { GetDepartmentQuery } from "../../models/api/admin/departments/GetDepartmentQuery";
import { DepartmentResponse } from "../../models/api/admin/departments/DepartmentResponse";

function emptyDepartmentBuilder() {
    let department = new DepartmentResponse();
    department.id = Guid.createEmpty();
    department.name = "";
    department.code = "";
    department.slug = "";
    return department;
}

const getDepartmentQueryBuilder = (id: Guid) => new GetDepartmentQuery({id});

const useGetDepartment = (id: Guid) => {
    return useFetchDataById(
        id,
        getDepartmentQueryBuilder,
        emptyDepartmentBuilder
    );
};

export default useGetDepartment;