import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { CentralPaperLayout } from "../../components/Layouts";
import { LockIcon } from "../../App/Theme";
import { useNotificationContext } from "../../components/Notification";
import { RouteName, routeFactory } from "../../tools/Route";
import ResetPasswordForm from "./ResetPasswordForm";

export default function ResetPasswordPage() {

  document.title = "Ré-initialisation du mot de passe - Ferias40";

  const history = useHistory();
  const location = useLocation();
  const { info } = useNotificationContext();

  const token = React.useMemo(() => {
    const qs = queryString.parse(location.search);
    return qs.token ? qs.token.toString() : undefined;
  }, [location.search]);

  const handleSuccess = React.useCallback(() => {
    history.push(routeFactory.fullPath(RouteName.Login));
    info("Votre mot de passe a été mis à jour.");
  }, [history, info]);

  return (
    <CentralPaperLayout
      title="Ré-initialisation du mot de passe"
      icon={<LockIcon />}
    >

      <ResetPasswordForm
        token={token}
        onSuccess={handleSuccess}
      />

    </CentralPaperLayout>
  )
}
