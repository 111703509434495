import { Guid } from "guid-typescript";
import { IMessage } from "../../../IMessage";

//Generated code, do not modify

export class UnarchiveActivityCommand implements IMessage {
    id!: Guid;

    public constructor(init?: Partial<UnarchiveActivityCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT";

    getUrl = () => `/admin/activities/${this.id.toString()}/unarchive`;

    getQueryString = () => undefined;

    getBody = () => undefined;
}