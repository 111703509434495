import React from "react";
import { Guid } from "guid-typescript";

import { EventTypeItemResponse } from "../../models/api/admin/event-types/EventTypeItemResponse";
import DataGrid, { DataGridColumnsBuilder } from "../../components/DataGrid";

interface EventTypesGridProps {
  loading: boolean;
  eventTypes: EventTypeItemResponse[];
  onEdit: (id: Guid) => void;
}

export default React.memo(({ loading, eventTypes, onEdit }: EventTypesGridProps) => {

  const columns = React.useMemo(() => buildColumns(onEdit), [onEdit]);

  return (
    <DataGrid<EventTypeItemResponse>
      data={eventTypes}
      loading={loading}
      columnsProps={columns}
    />
  );
});

function buildColumns(onEdit: (d: Guid) => void) {

  const builder = new DataGridColumnsBuilder<EventTypeItemResponse>(EventTypeItemResponse.Fields);

  builder
    .hiddenField(EventTypeItemResponse.Fields.id)
    .setFieldProps(EventTypeItemResponse.Fields.name, {
      label: "nom",
      position: 0
    })
    .setFieldProps(EventTypeItemResponse.Fields.slug, {
      position: 1
    })
    .addEditColumn(eventType => onEdit(eventType.id))
    ;

  return builder.build();
}
