import React from "react";
import clx from "clsx";

import {
    FormControl, FormHelperText, Typography,
} from "@material-ui/core"
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import SimpleMDE from "react-simplemde-editor"
import "easymde/dist/easymde.min.css"

import IFieldBaseProps, { useFormField } from "./IFieldBaseProps";
import { FormError } from "../../models/response"
import { capitalize } from "../../tools/StringExtension";


const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginBottom: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    titleError: {
        color: theme.palette.error.main,
    },
    editor: {
        "& .CodeMirror": {
            maxHeight: 150,
        },
        "& .CodeMirror-scroll": {
            minHeight: 120,
            maxHeight: 120,
        },
        "& .editor-toolbar": {
            borderTopWidth: theme.spacing(1) * 0.5,
        },
        "& .editor-toolbar i.separator": {
            borderColor: theme.palette.text.hint,
        },
        "& .editor-toolbar button": {
            color: theme.palette.text.primary,
        },
        "& .editor-toolbar button:hover, & .editor-toolbar button.active": {
            backgroundColor: theme.palette.background.default,
            borderColor: theme.palette.background.default,
        },
        "& .editor-toolbar button.heading": { display: "none" },
        "& .editor-toolbar button.quote": { display: "none" },
        "& .editor-toolbar button.ordered-list": { display: "none" },
        "& .editor-toolbar button.image": { display: "none" },
        "& .editor-toolbar button.side-by-side": { display: "none" },
        "& .editor-toolbar button.fullscreen": { display: "none" },
        "& .editor-statusbar": { display: "none" },
    }
}));

export interface RichTextProps extends IFieldBaseProps {
    data: any;
    errors?: FormError[];
    onChange?: (fieldName: string, text: string) => void;
}

function RichTextField(inProps: RichTextProps) {

    const classes = useStyles();

    const [props, value, error] =
        useFormField<RichTextProps, string>(inProps, inProps.data, inProps.errors);

    const editorRef = React.createRef<HTMLSpanElement>();

    React.useEffect(() => {
        if (editorRef.current) {
            const scrollEditor = editorRef.current.querySelector(".CodeMirror-scroll") as HTMLElement | null
            if (scrollEditor) {
                scrollEditor.style.minHeight = ""
            }
        }
    }, [editorRef]);

    return (
        <FormControl
            required={props.required}
            fullWidth={props.fullWidth}
            margin={props.margin}
            disabled={props.disabled}
            color={props.color}
            error={error !== undefined}
        >
            <Typography className={clx(classes.title, (error ? classes.titleError : undefined))}>
                {props.label ? capitalize(props.label) : ""}{props.required ? " *" : ""}
            </Typography>

            <span ref={editorRef}>
                <SimpleMDE

                    id={props.fieldName}
                    value={value}
                    onChange={value => {
                        props.onChange && props.onChange(props.fieldName, value);
                    }}
                    options={{
                        spellChecker: false,
                    }}
                    className={classes.editor}
                />
            </span>

            {error && <FormHelperText>{error}</FormHelperText>}

        </FormControl>
    )
}

export default RichTextField;