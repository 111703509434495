import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";

import App from "./App";
import FatalErrorBoundary from "./FatalErrorBoundary";
import { AuthContextProvider } from "../tools/Auth";
import { ThemeContextProvider } from "./Theme";
import { NotificationContextProvider } from "../components/Notification";

const AppContainer = () => {
  return (
    <ThemeContextProvider>
      <CssBaseline />
      <FatalErrorBoundary>
        <NotificationContextProvider>
          <AuthContextProvider>
            <App />
          </AuthContextProvider>
        </NotificationContextProvider>
      </FatalErrorBoundary>
    </ThemeContextProvider>
  )
}

export default AppContainer;
