import RouteName from "./RouteName"
import RouteConfiguration from "./RouteConfiguration"

const routeConfiguration = new RouteConfiguration()
    .add(RouteName.Home, "/")
    .add(RouteName.Login, "/login")
    .add(RouteName.ResetPassword, "/reset-password")
    .add(RouteName.Profile, "/profile")
    .add(RouteName.Settings, "/settings")
    .add(RouteName.Contact, "/contact")

    .add(RouteName.DepartmentList, "/departments")
    .add(RouteName.TownList, "/towns")
    .add(RouteName.ActivityGroupList, "/activity-groups")
    .add(RouteName.ActivityTypeList, "/activity-types")
    .add(RouteName.EventTypeList, "/event-types")
    .add(RouteName.EventList, "/events")
    .add(RouteName.EventOverview, "/events/:eventId/overview")
    .add(RouteName.EventDayProgram, "/events/:eventId/overview/:eventDayId/program")

export default routeConfiguration