import { ODataOrderBy, ODataSelect, IODataQuery } from "../../OData";

//Generated code, do not modify

export class SearchTownsQuery implements IODataQuery {
    top!: number;
    distinct!: boolean;
    filter!: string;
    orderBy!: ODataOrderBy;
    select!: ODataSelect;
    skip!: number;

    public constructor(init?: Partial<SearchTownsQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET";

    getUrl = () => `/admin/towns`;

    getQueryString = () => {
        return {
            top: this.top,
            distinct: this.distinct,
            filter: this.filter,
            orderBy: this.orderBy,
            select: this.select,
            skip: this.skip,
        }
    }

    getBody = () => undefined;
}