import { Guid } from "guid-typescript";
import { IMessage } from "../../../IMessage";

//Generated code, do not modify

export class UpdateEventUrlsCommand implements IMessage {
    id!: Guid;
    facebookUrl!: string;
    officialUrl!: string;
    busUrl!: string;
    contactEmail!: string;

    public constructor(init?: Partial<UpdateEventUrlsCommand>) {
        Object.assign(this, init);
    }

    getMethod = () => "PUT";

    getUrl = () => `/admin/events/${this.id.toString()}/urls`;

    getQueryString = () => undefined;

    getBody = () => {
        return {
            facebookUrl: this.facebookUrl,
            officialUrl: this.officialUrl,
            busUrl: this.busUrl,
            contactEmail: this.contactEmail,
        };
    }
}