import React from "react";

import { LockIcon } from "../../App/Theme";
import { FormFieldsBuilder } from "../../components/FormField";
import { IField } from "../../models/api/IField";
import { useNotificationContext } from "../../components/Notification";
import FormPaper from "../../components/FormPaper";

class ResetPasswordResponse {
  password!: string;
  passwordConfirmation!: string;

  public static Fields = {
    password: { name: "password", type: "string" },
    passwordConfirmation: { name: "passwordConfirmation", type: "string" }
  } as Record<"password" | "passwordConfirmation", IField>;
}

function emptyResponseBuilder() {
  let response = new ResetPasswordResponse();
  response.password = "";
  response.passwordConfirmation = "";
  return response;
}

export default function ResetPasswordFormPaper() {

  const { success } = useNotificationContext();

  const fields = React.useMemo(() => buildFields(), []);
  const [initial, setInitial] = React.useState(emptyResponseBuilder());

  const buildMessage = React.useCallback((data: ResetPasswordResponse) => {
    // return new SendAdminContactEmailCommand(data);
    // TODO : return command to update profile
    // TODO : remettre les champs à vide si erreur (handle on onError)
  }, []);

  const handleSuccess = React.useCallback(() => {
    success("Votre mot de passe a bien été mis à jour");
    setInitial(emptyResponseBuilder());
  }, [success]);

  return (
    <FormPaper<ResetPasswordResponse>
      title="Changer de mot passe"
      icon={<LockIcon />}

      form={{
        submitLabel: "Mettre à jour",
        fields,
        initial: initial,
        onSuccess: handleSuccess,
        onPrepareMessage: buildMessage,
      }}
    />
  );
}

function buildFields() {

  const builder = new FormFieldsBuilder<ResetPasswordResponse>(ResetPasswordResponse.Fields);

  builder
    .setFieldProps(ResetPasswordResponse.Fields.passwordConfirmation, {
      label: "Nouveau mot de passe",
      position: 0,
      type: "password",
    })
    .setFieldProps(ResetPasswordResponse.Fields.password, {
      label: "Confirmer le nouveau mot de passe",
      position: 1,
      type: "password",
    })
    .disabledDefaultAutoFocus()
    ;

  return builder.build();

}
