import React from "react";
import { Guid } from "guid-typescript";
import { Link } from "react-router-dom";

import { IconButton, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import Paper from "../../components/Paper";
import DataGrid, { DataGridColumnsBuilder } from "../../components/DataGrid";
import { ODataOrderBy, OrderDirection, ODataFilters, ODataFilter } from "../../models/api/OData";
import { useSearchEvents, EventFormDialog } from "../Event";
import { SearchEventsQuery } from "../../models/api/admin/events/SearchEventsQuery";
import { EventItemResponse } from "../../models/api/admin/events/ODataResponse[EventItemResponse]";
import { EventIcon, EditIcon } from "../../App/Theme";
import { routeFactory, RouteName } from "../../tools/Route";
import { useNotificationContext } from "../../components/Notification";
import { format } from "../../tools/DateExtension";

function getNextEventsFilter(areDatesValidated: boolean) {
    const t = new ODataFilters();
    t.push(new ODataFilter(EventItemResponse.Fields.startAt.name, "ge", new Date()));
    t.push(new ODataFilter(EventItemResponse.Fields.isArchived.name, "eq", false));
    t.push(new ODataFilter(EventItemResponse.Fields.areDatesValidated.name, "eq", areDatesValidated));
    return t;
}

function getNextEventsQuery(areDatesValidated: boolean) {
    return new SearchEventsQuery({
        top: 10,
        skip: 0,
        orderBy: new ODataOrderBy({
            direction: OrderDirection.Asc,
            member: EventItemResponse.Fields.startAt.name,
        }),
        filter: getNextEventsFilter(areDatesValidated).toQueryString(),
    });
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    block: {
        marginTop: theme.spacing(1) * 2,
        marginBottom: theme.spacing(1) * 2,
    },
    moreLink: {
        float: "right",
        textDecoration: "underline",
        color: theme.palette.text.hint,
    }
}));

export default function HomePage() {

    document.title = "Accueil - Administration - Ferias40"

    const classes = useStyles();
    const { success } = useNotificationContext();

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogEventId, setDialogEventId] = React.useState(Guid.createEmpty());

    const handleEdit = React.useCallback((id: Guid) => {
        setDialogEventId(id);
        setDialogOpen(true);
    }, []);

    const columns = React.useMemo(() => buildColumns(handleEdit), [handleEdit]);
    const nextUnvalidEventsQuery = React.useMemo(() => getNextEventsQuery(false), []);
    const [unvalidLoading, unvalidEvents, , updateUnvalidEvents] = useSearchEvents(nextUnvalidEventsQuery);

    const nextValidEventsQuery = React.useMemo(() => getNextEventsQuery(true), []);
    const [validLoading, validEvents, , updateValidEvents] = useSearchEvents(nextValidEventsQuery);

    const handleClose = React.useCallback(() => setDialogOpen(false), []);

    const handleAfterSave = React.useCallback(() => {
        updateUnvalidEvents();
        updateValidEvents();
        success("L'évènement a bien été enregistré");
    }, [updateUnvalidEvents, updateValidEvents, success]);

    return (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={2}
        >

            <Grid item lg={6} md={12}>
                <Paper
                    name="Prochains évènements validés"
                    icon={<EventIcon />}
                >
                    <DataGrid<EventItemResponse>
                        data={validEvents}
                        loading={validLoading}
                        columnsProps={columns}
                    />

                    <div className={classes.block}>
                        <Link
                            to={routeFactory.fullPath(RouteName.EventList)}
                            className={classes.moreLink}
                        >
                            Plus d'évènements >
                    </Link>
                    </div>
                </Paper>
            </Grid>

            <Grid item lg={6} md={12}>
                <Paper
                    name="Prochains évènements non validés"
                    icon={<EventIcon />}
                >
                    <DataGrid<EventItemResponse>
                        data={unvalidEvents}
                        loading={unvalidLoading}
                        columnsProps={columns}
                    />

                    <div className={classes.block}>
                        <Link
                            to={routeFactory.fullPath(RouteName.EventList)}
                            className={classes.moreLink}
                        >
                            Plus d'évènements >
                    </Link>
                    </div>
                </Paper>
            </Grid>


            <EventFormDialog
                open={dialogOpen}
                onClose={handleClose}
                eventId={dialogEventId}
                onAfterSave={handleAfterSave}
            />

        </Grid>
    );
}

function buildColumns(onEdit: (id: Guid) => void) {

    const builder = new DataGridColumnsBuilder<EventItemResponse>(EventItemResponse.Fields);

    builder
        .hiddenField(EventItemResponse.Fields.id)
        .hiddenField(EventItemResponse.Fields.townId)
        .hiddenField(EventItemResponse.Fields.eventTypeName)
        .hiddenField(EventItemResponse.Fields.eventTypeId)
        .hiddenField(EventItemResponse.Fields.endAt)
        .hiddenField(EventItemResponse.Fields.areDatesValidated)
        .hiddenField(EventItemResponse.Fields.isArchived)
        .setFieldProps(EventItemResponse.Fields.townName, {
            label: "évènement",
            position: 0,
            render: event => <>{event.townName} - {event.eventTypeName}</>
        })
        .setFieldProps(EventItemResponse.Fields.startAt, {
            label: "dates",
            type: "string",
            position: 1,
            render: event => {
                const { startAt, endAt } = event;
                const startFormat = startAt.getMonth() === endAt.getMonth() ? "Do" : "Do MMMM"
                const endFormat = "Do MMMM YYYY";
                return <>Du {format(startAt, startFormat)} au {format(endAt, endFormat)}</>
            }
        })
        .addColumn({
            padding: "checkbox",
            render: (event: EventItemResponse) => {
                if (event.areDatesValidated) {
                    const route = routeFactory
                        .get(RouteName.EventOverview)
                        .setParameters({ eventId: event.id.toString() })
                        .fullPath();
                    return (
                        <IconButton href={route} color="primary">
                            <EventIcon />
                        </IconButton>
                    );
                }
                return (
                    <IconButton onClick={() => onEdit(event.id)} color="primary">
                        <EditIcon />
                    </IconButton>
                );
            }
        })
        ;

    return builder.build();
}