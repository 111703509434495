import React from "react";
import { Guid } from "guid-typescript";

import { DepartmentItemResponse } from "../../models/api/admin/departments/DepartmentItemResponse";
import DataGrid, { DataGridColumnsBuilder } from "../../components/DataGrid";

interface DepartmentsGridProps {
  loading: boolean;
  departments: DepartmentItemResponse[];
  onEdit: (id: Guid) => void;
}


export default React.memo(({ loading, departments, onEdit }: DepartmentsGridProps) => {

  const columns = React.useMemo(() => buildColumns(onEdit), [onEdit]);

  return (
    <DataGrid<DepartmentItemResponse>
      data={departments}
      loading={loading}
      columnsProps={columns}
    />
  );
});

function buildColumns(onEdit: (d: Guid) => void) {

  const builder = new DataGridColumnsBuilder<DepartmentItemResponse>(DepartmentItemResponse.Fields);

  builder
    .hiddenField(DepartmentItemResponse.Fields.id)
    .setFieldProps(DepartmentItemResponse.Fields.name, {
      label: "nom",
      position: 0
    })
    .setFieldProps(DepartmentItemResponse.Fields.slug, {
      position: 1
    })
    .addEditColumn(department => onEdit(department.id))
    ;

  return builder.build();
}
