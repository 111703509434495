import React from "react";
import { Guid } from "guid-typescript";

import { ActivityGroupItemResponse } from "../../models/api/admin/activity-groups/ActivityGroupItemResponse";
import DataGrid, { DataGridColumnsBuilder } from "../../components/DataGrid";

interface ActivityGroupGridProps {
  loading: boolean;
  activityGroups: ActivityGroupItemResponse[];
  onEdit: (id: Guid) => void;
}

export default React.memo(({ loading, activityGroups, onEdit }: ActivityGroupGridProps) => {

  const columns = React.useMemo(() => buildColumns(onEdit), [onEdit]);

  return (
    <DataGrid<ActivityGroupItemResponse>
      data={activityGroups}
      loading={loading}
      columnsProps={columns}
    />
  );
});

function buildColumns(onEdit: (d: Guid) => void) {

  const builder = new DataGridColumnsBuilder<ActivityGroupItemResponse>(ActivityGroupItemResponse.Fields);

  builder
    .hiddenField(ActivityGroupItemResponse.Fields.id)
    .setFieldProps(ActivityGroupItemResponse.Fields.name, {
      label: "nom",
    })
    .addEditColumn(activityGroup => onEdit(activityGroup.id))
    ;

  return builder.build();
}
