import React from "react";

import {
  Table, TableBody,
  TableCell, TableHead, TableRow, CircularProgress, TableFooter
} from "@material-ui/core";
import { Padding } from "@material-ui/core/Table";
import { Theme } from "@material-ui/core/styles";
import { createStyles, makeStyles } from "@material-ui/styles";

import { IODataGridColumnOptions, IODataGridColumnProps } from "./types";
import CellHeaderSortable from "./CellHeaderSortable";
import { CellData, CellHeader } from "../DataGrid";
import CellFilter from "./CellFilter";
import ODataPagination from "./ODataPagination";
import {
  IODataQuery, OrderDirection,
  ODataOrderBy, ODataFilters, ODataFilter, ODataFilterOperator
} from "../../models/api/OData";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    position: "relative",
  },
  table: {
    "& tbody tr:nth-of-type(even)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  loadingContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    opacity: 0.8,
    zIndex: theme.zIndex.appBar - 1,
  },
  tableHead: theme.mixins.getTableHeader(theme),
}));

export interface ODataGridProps<T> {
  count: number;
  data: T[];
  loading?: boolean;
  padding?: Padding;
  columnsProps: IODataGridColumnOptions<T>[];

  initialFiltersValue?: Record<string, any>;
  query: IODataQuery;
  fetchQuery: () => void;
}

const PAGINATION_ROWS_PER_PAGE_OPTIONS = [10, 30, 50, 100];

function buildODataFiltersFromColumnOptions(columns: IODataGridColumnOptions<any>[]): ODataFilters {
  const filters = new ODataFilters();
  columns.forEach(c => {
    if (!c.filterable) return;

    const fieldName = c.filterField.fieldName as string;
    const operator = c.filterOperators;
    const type = c.filterField.type ? c.filterField.type.toLowerCase() : "string";

    let value = "" as any;
    if (type === "date") {
      value = new Date();
    }

    filters.push(new ODataFilter(fieldName, operator[0], value));
  });

  return filters;
}

export default function ODataGrid<T>({ count, data, loading, columnsProps, query, fetchQuery, initialFiltersValue, ...props }: ODataGridProps<T>) {

  const classes = useStyles();
  const padding = React.useMemo(() => props.padding ? props.padding : "default", [props.padding]);
  const nbColumn = React.useMemo(() => columnsProps.length, [columnsProps]);

  if (!PAGINATION_ROWS_PER_PAGE_OPTIONS.includes(query.top)) {
    throw new Error(`The IODataQuery.top must be contained in [${PAGINATION_ROWS_PER_PAGE_OPTIONS.join(', ')}] but given '${query.top}'`);
  }


  const handleOrderBy = React.useCallback((member: string, direction: OrderDirection) => {
    query.orderBy = new ODataOrderBy({ member, direction });
    fetchQuery();
  }, [query, fetchQuery]);

  const renderHeaderRow = React.useCallback(() => {
    return (
      <TableRow>
        {columnsProps.map((columnProps: IODataGridColumnProps<T>, idx) => {
          if (columnProps.sortable) {
            return <CellHeaderSortable<T>
              key={idx}
              {...columnProps}
              orderBy={query.orderBy}
              onOrderBy={handleOrderBy}
            />
          }
          return <CellHeader key={idx} {...columnProps} />
        })}
      </TableRow>
    );
  }, [columnsProps, query.orderBy, handleOrderBy]);


  const [filters, setFilters] = React.useState(() => {
    return buildODataFiltersFromColumnOptions(columnsProps);
  });

  const handleFilterValueChange = React.useCallback((fieldName: string, value: any) => {
    setFilters((oldFilters: ODataFilters) => {
      return oldFilters.clone()
        .setFieldNameValue(fieldName, value);
    });
  }, [setFilters]);

  const handleFilterOperatorChange = React.useCallback((fieldName: string, operator: ODataFilterOperator) => {
    setFilters((oldFilters: ODataFilters) => {
      return oldFilters.clone()
        .setFieldNameOperator(fieldName, operator);
    });
  }, []);

  const handleFilters = React.useCallback((fieldName?: string, value?: any) => {
    const tmp = filters.clone();
    if (fieldName && value) {
      tmp.setFieldNameValue(fieldName, value);
    }
    const fqs = tmp.toQueryString();
    query.filter = fqs ? fqs : "";
    query.skip = 0;
    fetchQuery();
  }, [filters, query, fetchQuery]);

  React.useEffect(() => {
    if (initialFiltersValue) {
      filters.forEach(f => {
        if (initialFiltersValue[f.fieldName]) {
          f.value = initialFiltersValue[f.fieldName];
        }
      });
      handleFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFitlerRow = React.useCallback(() => {
    return (
      <TableRow>
        {columnsProps.map((columnProps: IODataGridColumnProps<T>, idx) => {
          const filter = filters.find(x => x.fieldName === columnProps.fieldName);
          if (columnProps.filterable && filter) {
            return <CellFilter
              key={idx}
              {...columnProps}
              filter={filter}
              onValueChange={handleFilterValueChange}
              onOperatorChange={handleFilterOperatorChange}
              onFilters={handleFilters}
            />
          }
          return (
            <TableCell
              key={idx}
              align={columnProps.align}
              padding="none"
            >
            </TableCell>
          );
        })}
      </TableRow>
    );
  }, [columnsProps, filters, handleFilterOperatorChange, handleFilterValueChange, handleFilters]);


  const renderDataRow = React.useCallback((data: any, key: number) => {
    return (
      <TableRow key={key}>
        {columnsProps.map((columnProps: IODataGridColumnProps<T>, idx) => {
          return <CellData<T>
            key={key * nbColumn + idx}
            {...columnProps}
            data={data}
          />
        })}
      </TableRow>
    );
  }, [columnsProps, nbColumn]);


  const renderEmptyRow = React.useCallback(() => {
    return (
      <TableRow>
        <TableCell
          colSpan={nbColumn}
          scope="row"
        >
          Aucune données trouvées
        </TableCell>
      </TableRow>
    );
  }, [nbColumn]);


  const handlePagination = React.useCallback((page: number, rowsPerPage: number) => {
    page = rowsPerPage !== query.top ? 0 : page;
    query.top = rowsPerPage;
    query.skip = page * rowsPerPage;
    fetchQuery();
  }, [query, fetchQuery]);


  return (
    <div className={classes.container}>
      {loading ? <div className={classes.loadingContainer}><CircularProgress size={28} /></div> : null}

      <Table
        className={classes.table}
        padding={padding}
      >

        <TableHead className={classes.tableHead}>
          {renderHeaderRow()}
          {renderFitlerRow()}
        </TableHead>

        <TableBody>
          {
            data.length === 0
              ? renderEmptyRow()
              : data.map((d, idx) => renderDataRow(d, idx))
          }
        </TableBody>

        <TableFooter>
          <ODataPagination
            colSpan={nbColumn}
            count={count}

            page={query.skip / query.top}
            rowsPerPage={query.top}
            rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
            onChange={handlePagination}
          />
        </TableFooter>

      </Table>
    </div>
  )
}
