import { Guid } from "guid-typescript";

import { useFetchDataById } from "../../tools/fetchRequest";
import { GetEventTypeQuery } from "../../models/api/admin/event-types/GetEventTypeQuery";
import { EventTypeResponse } from "../../models/api/admin/event-types/EventTypeResponse";

function emptyBuilder() {
    let entity = new EventTypeResponse();
    entity.id = Guid.createEmpty();
    entity.name = "";
    entity.slug = "";
    return entity;
}

const getQueryBuilder = (id: Guid) => new GetEventTypeQuery({ id });

const useGetEventType = (id: Guid) => {
    return useFetchDataById(
        id,
        getQueryBuilder,
        emptyBuilder
    );
};

export default useGetEventType;