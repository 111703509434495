import React from "react";

import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import { CentralColumnLayout } from "../../components/Layouts";
import { useAuthContext } from "../../tools/Auth";
import { LogoutIcon } from "../../App/Theme";
import ProfileFormPaper from "./ProfileFormPaper";
import ResetPasswordFormPaper from "./ResetPasswordFormPaper";

const useStyles = makeStyles((theme: Theme) => createStyles({
  row: {
    display: "flex",
    flexDirection: "row",
  },
  logout: {
    marginLeft: "auto",
  }
}));

export default function ProfilePage() {

  document.title = "Mon profil - Administration - Ferias40";

  const classes = useStyles();
  const { setToken } = useAuthContext();

  const handleLogout = React.useCallback(() => setToken(null), [setToken]);

  return (
    <CentralColumnLayout size="sm">

      <ProfileFormPaper />

      <ResetPasswordFormPaper />

      <div className={classes.row}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLogout}
          className={classes.logout}
          startIcon={<LogoutIcon />}
        >
          Se déconnecter
        </Button>
      </div>

    </CentralColumnLayout>
  );
}
