import { Guid } from "guid-typescript";
import { IField } from "../../IField";

//Generated code, do not modify

export class ActivityGroupItemResponse {
    id!: Guid;
    name!: string;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
    } as Record<"id" | "name", IField>;
}
