import { Guid } from "guid-typescript";
import { IField } from "../../IField";

//Generated code, do not modify

export class ActivityResponse {
    id!: Guid;
    name!: string;
    activityTypeId!: Guid;
    time!: string;
    description!: string;
    isArchived!: boolean;

    public static Fields = {
        id: { name: "id", type: "Guid" },
        name: { name: "name", type: "string" },
        activityTypeId: { name: "activityTypeId", type: "Guid" },
        time: { name: "time", type: "string" },
        description: { name: "description", type: "string" },
        isArchived: { name: "isArchived", type: "boolean" },
    } as Record<"id" | "name" | "activityTypeId" | "time" | "description" | "isArchived", IField>;
}
