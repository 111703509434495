import React from "react";

export interface IAuthPayload {
    firstName: string;
    lastName: string;
}

export interface IAuthContext {
    setToken: (token: string | null) => void,
    isLogged: boolean,
    payload: IAuthPayload | null,
}

const AuthContext = React.createContext<IAuthContext>({
    setToken: () => { throw new Error(`undefined IAuthContext.setToken() method`); },
    isLogged: false,
    payload: null,
});

export default AuthContext;