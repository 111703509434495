import { useFetchArrayData } from "../../tools/fetchRequest";
import { DepartmentItemResponse } from "../../models/api/admin/departments/DepartmentItemResponse";
import { ListDepartmentsQuery } from "../../models/api/admin/departments/ListDepartmentsQuery";

const listDepartmentsQuery = new ListDepartmentsQuery();

function useListDepartments() {
    return useFetchArrayData<DepartmentItemResponse>(listDepartmentsQuery);
};

export default useListDepartments;