
function readFileContent(file: File, callback: (content: string | null) => void) {
    let reader = new FileReader();
    reader.onloadend = async () => {
        if (reader.result) {
            const content = reader.result.toString();
            callback(content);
        }
        callback(null);
    }
    reader.readAsDataURL(file);
}

export default readFileContent;