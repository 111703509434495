import React from "react";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import { TextField } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import IFieldBaseProps, { useFormField } from "./IFieldBaseProps";
import { FormError } from "../../models/response";
import { capitalize } from "../../tools/StringExtension";

const useStyles = makeStyles((theme: Theme) => createStyles({
    groupLabel: {
        fontSize: theme.typography.caption.fontSize,
        textTransform: "uppercase",
        fontStyle: "italic",
        fontWeight: "bold",
    },
    groupOption: {
        paddingLeft: theme.spacing(1) * 3,
    }
}));

export interface AutoCompleteOption {
    label: any;
    value: any;
}

export interface AutoCompleteFieldProps extends IFieldBaseProps {
    data: any;
    errors?: FormError[];
    onChange?: (fieldName: string, option: any) => void;

    options?: AutoCompleteOption[];
    noOptionsText?: string;
    groupBy?: (option: AutoCompleteOption) => string;
}

const filterOptions = createFilterOptions({
    stringify: (option: AutoCompleteOption) => option.label,
});

export default function AutoCompleteField(inProps: AutoCompleteFieldProps) {

    const classes = useStyles();

    const [props, optionValue, error] =
        useFormField<AutoCompleteFieldProps, any>(inProps, inProps.data, inProps.errors);

    const value = props.options && props.options.find(x => x.value === optionValue.toString());

    return (

        <Autocomplete<AutoCompleteOption>
            options={props.options}
            getOptionLabel={option => option.label}
            onChange={(e: any, v: AutoCompleteOption | null) => {
                if (v !== null && props.onChange) {
                    props.onChange && props.onChange(props.fieldName, v.value)
                }
            }}
            value={value}
            noOptionsText={props.noOptionsText ? capitalize(props.noOptionsText) : undefined}
            groupBy={props.groupBy}
            getOptionSelected={(option, value) => option.value === value.value}
            filterOptions={filterOptions}
            classes={{
                groupLabel: classes.groupLabel,
                option: props.groupBy ? classes.groupOption : undefined,
            }}

            renderInput={params => (
                <TextField
                    required={props.required}
                    fullWidth={props.fullWidth}
                    margin={props.margin}
                    disabled={props.disabled}
                    autoComplete={props.autoComplete}
                    color={props.color}

                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}

                    label={props.label ? capitalize(props.label) : null}

                    error={error !== undefined}
                    helperText={error}
                />
            )}
        />
    )
}
