import React from "react";
import { TableRow, TablePagination } from "@material-ui/core";
import { LabelDisplayedRowsArgs } from "@material-ui/core/TablePagination";

import ODataPaginationActions from "./ODataPaginationActions";

export interface ODataPaginationProps {
    colSpan: number;
    count: number;
    page: number;
    rowsPerPage: number;
    rowsPerPageOptions: number[];
    onChange: ((page: number, rowPerPage: number) => void);
}

export default function ODataPagination({ onChange, ...props }: ODataPaginationProps) {

    const handleLabelDisplayedRows = React.useCallback((info: LabelDisplayedRowsArgs) => {
        return `${info.from}-${info.to} sur ${info.count} (page ${info.page + 1})`;
    }, []);

    const handlePageChange = React.useCallback((e: any, page: number) => {
        onChange(page, props.rowsPerPage);
    }, [onChange, props.rowsPerPage]);

    const handleRowsPerPageChange = React.useCallback((e: any) => {
        const rowsPerPage = parseInt(e.target.value, 10);
        onChange(props.page, rowsPerPage);
    }, [onChange, props.page]);

    return (
        <TableRow>
            <TablePagination
                {...props}
                labelRowsPerPage="Ligne par page "
                labelDisplayedRows={handleLabelDisplayedRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                ActionsComponent={ODataPaginationActions}
            />
        </TableRow>
    )
}
