import { IMessage } from "../IMessage";

//Generated code, do not modify

export class ListTimelineMonthsQuery implements IMessage {

    public constructor(init?: Partial<ListTimelineMonthsQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET";

    getUrl = () => `/timeline-months`;

    getQueryString = () => undefined;

    getBody = () => undefined;
}