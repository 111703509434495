import React from "react";

import { EventIcon } from "../../App/Theme";
import { IField } from "../../models/api/IField";
import { CreateEventsForNewYearCommand } from "../../models/api/admin/events/new-year/CreateEventsForNewYearCommand";
import { useNotificationContext } from "../../components/Notification";
import { FormFieldsBuilder } from "../../components/FormField";
import FormPaper from "../../components/FormPaper";

class CreateEventsForNewYearResponse {
  sourceYear!: number;
  targetYear!: number;

  public static Fields = {
    sourceYear: { name: "sourceYear", type: "number" },
    targetYear: { name: "targetYear", type: "number" }
  } as Record<"sourceYear" | "targetYear", IField>;
}

function emptyResponseBuilder() {
  let response = new CreateEventsForNewYearResponse();
  const currentYear = new Date().getFullYear()
  response.sourceYear = currentYear;
  response.targetYear = currentYear + 1;
  return response;
}

export default function EventsNewYearFormPaper() {

  const { success } = useNotificationContext();

  const fields = React.useMemo(() => buildFields(), []);

  const buildMessage = React.useCallback((data: CreateEventsForNewYearResponse) => {
    return new CreateEventsForNewYearCommand(data);
  }, []);

  const handleSuccess = React.useCallback(() => {
    success("Les évènements ont bien été générés.");
  }, [success]);

  return (
    <FormPaper<CreateEventsForNewYearResponse>
      title="Évènements pour une nouvelle année"
      icon={<EventIcon />}

      form={{
        submitLabel: "Générer",
        fields,
        initial: emptyResponseBuilder(),
        onSuccess: handleSuccess,
        onPrepareMessage: buildMessage,
      }}
    />
  );
}


function buildFields() {

  const builder = new FormFieldsBuilder<CreateEventsForNewYearResponse>(CreateEventsForNewYearResponse.Fields);

  builder
    .setFieldProps(CreateEventsForNewYearResponse.Fields.sourceYear, {
      label: "Année de référence (source)",
    })
    .setFieldProps(CreateEventsForNewYearResponse.Fields.targetYear, {
      label: "Année à générer (cible)",
    })
    ;

  return builder.build();
}