import React from "react";
import { Guid } from "guid-typescript";

import { TimelineIcon } from "../../App/Theme";
import ImageSelector from "../../components/ImageSelector";
import { useNotificationContext } from "../../components/Notification";
import { CreateTimelineMonthCoverCommand } from "../../models/api/admin/timeline-months/cover/CreateTimelineMonthCoverCommand";
import { DeleteTimelineMonthCoverCommand } from "../../models/api/admin/timeline-months/cover/DeleteTimelineMonthCoverCommand";
import useListTimelineMonths from "./useListTimelineMonths";
import { fetchRequest } from "../../tools/fetchRequest";
import readFileContent from "../../tools/readFileContent";
import { format } from "../../tools/DateExtension";
import Paper from "../../components/Paper";


export default function TimelineMonthsPaper() {

  const { success, error } = useNotificationContext();

  const emptyGuid = Guid.createEmpty();
  const [loadingMonthId, setLoadingMonthId] = React.useState(emptyGuid);
  const [timelineMonthsLoading, timelineMonths, updateTimelineMonths] = useListTimelineMonths();

  const handleUpdateCover = React.useCallback((monthId: Guid, file: File) => {
    readFileContent(file, async (content) => {
      try {
        setLoadingMonthId(monthId);
        if (content !== null) {
          const command = new CreateTimelineMonthCoverCommand({
            timelineMonthId: monthId,
            mimeType: file.type,
            content: content.split("base64,")[1],
          });
          await fetchRequest(command);
          setLoadingMonthId(emptyGuid);
          updateTimelineMonths();
          success("La couverture a bien été mise à jour");
        }
        else {
          throw new Error("unreadable file content");
        }
      }
      catch (err) {
        setLoadingMonthId(emptyGuid);
        error("Une erreur est survenue !");
      }
    });
  }, [emptyGuid, updateTimelineMonths, success, error]);

  const handleDeleteCover = React.useCallback(async (monthId: Guid) => {
    try {
      setLoadingMonthId(monthId);
      const command = new DeleteTimelineMonthCoverCommand({ timelineMonthId: monthId });
      await fetchRequest(command);
      setLoadingMonthId(emptyGuid);
      updateTimelineMonths();
      success("La couverture a bien été supprimée");
    }
    catch (err) {
      setLoadingMonthId(emptyGuid);
      error("Une erreur est survenue !");
    }
  }, [emptyGuid, updateTimelineMonths, success, error]);

  return (
    <Paper
      name="Couvertures des mois"
      icon={<TimelineIcon />}
      loading={timelineMonthsLoading}
    >
      {(timelineMonths.map(tm => {
        const month = format(new Date(`1986-${tm.month}-01`), "MMMM");

        return <ImageSelector
          key={tm.id.toString()}
          height={80}
          name={month}
          imageId={tm.coverId}
          onChange={(file: File) => handleUpdateCover(tm.id, file)}
          onDelete={() => handleDeleteCover(tm.id)}
          deleteLoading={loadingMonthId.toString() === tm.id.toString()}
        />

      }))}
    </Paper>
  );
}

