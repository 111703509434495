import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class ListDepartmentsQuery implements IMessage {

    public constructor(init?: Partial<ListDepartmentsQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET";

    getUrl = () => `/admin/departments`;

    getQueryString = () => undefined;

    getBody = () => undefined;
}