import React from "react";
import { Guid } from "guid-typescript";

import FormDialog from "../../components/FormDialog";
import { FormFieldsBuilder, } from "../../components/FormField";

import { EventTypeIcon } from "../../App/Theme";
import { EventTypeResponse } from "../../models/api/admin/event-types/EventTypeResponse";
import { CreateEventTypeCommand } from "../../models/api/admin/event-types/CreateEventTypeCommand";
import { UpdateEventTypeCommand } from "../../models/api/admin/event-types/UpdateEventTypeCommand";
import useGetEventType from "./useGetEventType";

interface EventTypeFormDialogProps {
    open: boolean;
    eventTypeId: Guid;
    onClose: () => void;
    onAfterSave: () => void;
}


export default React.memo(({ open, eventTypeId, onClose, onAfterSave }: EventTypeFormDialogProps) => {

    const [loading, eventType] = useGetEventType(eventTypeId);

    const submitLabel = React.useMemo(
        () => eventTypeId.isEmpty() ? "Ajouter" : "Editer",
        [eventTypeId]
    );

    const fields = React.useMemo(() => buildFields(), []);

    const buildMessage = React.useCallback((data: EventTypeResponse) => {
        return eventTypeId.isEmpty()
            ? new CreateEventTypeCommand(data)
            : new UpdateEventTypeCommand(data);
    }, [eventTypeId]);

    return (
        <FormDialog<EventTypeResponse>
            title={`${submitLabel} un type d'évènement`}
            icon={<EventTypeIcon />}
            open={open}
            onClose={onClose}
            loading={loading}

            form={{
                submitLabel,
                fields,
                initial: eventType,
                onSuccess: onAfterSave,
                onPrepareMessage: buildMessage,
            }}
        />
    );
});

function buildFields() {

    const builder = new FormFieldsBuilder<EventTypeResponse>(EventTypeResponse.Fields);

    builder
        .attachFieldToSlug(
            EventTypeResponse.Fields.name,
            EventTypeResponse.Fields.slug
        )
        .hiddenField(EventTypeResponse.Fields.id)
        .setFieldProps(EventTypeResponse.Fields.name, {
            label: "nom",
            position: 0,
        })
        .setFieldProps(EventTypeResponse.Fields.slug, {
            position: 1,
        });

    return builder.build();
}
