import { IMessage } from "./IMessage";
import { capitalize } from "../../tools/StringExtension";
import { format } from "../../tools/DateExtension";

export interface IODataQuery extends IMessage {
  top: number;
  distinct: boolean;
  filter: string;
  orderBy: ODataOrderBy;
  select: ODataSelect;
  skip: number;

  getMethod: () => string;
  getUrl: () => string;
  getQueryString: () => Record<string, any> | undefined;
  getBody: () => Record<string, any> | undefined;
}

export interface IODataResponse<T> {
  d: {
    results: T[];
    __count: number;
  }
}

export enum OrderDirection {
  Asc = 0,
  Desc = 1,
}

export class ODataOrderBy {
  direction!: OrderDirection;
  member!: string;

  public static Fields = {
    direction: { name: "direction", type: "OrderDirection" },
    member: { name: "member", type: "string" },
  }

  public constructor(init?: Partial<ODataOrderBy>) {
    Object.assign(this, init);
  }

  public toString(): string {
    if (!this.member || this.direction === undefined) return "undefined";

    const direction = this.direction === OrderDirection.Asc ? "asc" : "desc";
    return `${this.member} ${direction}`;
  }

}

export class ODataSelect {
  members!: string[];

  public static Fields = {
    members: { name: "members", type: "string[]" },
  }

  public constructor(init?: Partial<ODataSelect>) {
    this.members = [];
    Object.assign(this, init);
  }

  public toString(): string {
    if (!this.members || this.members.length === 0) return "undefined";

    return this.members.join(',');
  }
}


export type ODataFilterOperator = "contains" | "eq" | "ne" | "lt" | "le" | "gt" | "ge";

export class ODataFilter {
  fieldName: string;
  operator: ODataFilterOperator;
  value: any;

  constructor(fieldName: string, operator: ODataFilterOperator, value: any = undefined) {
    this.fieldName = fieldName;
    this.operator = operator;
    this.value = value;
  }

  public toString(): string | undefined {
    if (!this.operator || this.value === undefined || this.value === "" || this.value === "undefined") {
      return undefined;
    }

    let valueStr = `'${this.value.toString()}'`;
    if (Object.prototype.toString.call(this.value) === "[object Date]") {
      valueStr = format(this.value, "YYYY-MM-DD");
    }
    else if (this.value === "true" || this.value === "false") {
      valueStr = this.value;
    }
    else if (this.value === true || this.value === false) {
      valueStr = this.value === true ? "true" : "false";
    }

    if (this.operator === "contains") {
      return `${this.operator}(${capitalize(this.fieldName)}, ${valueStr})`;
    }

    return `(${capitalize(this.fieldName)} ${this.operator} ${valueStr})`
  }
}

export class ODataFilters extends Array<ODataFilter> {

  public setFieldNameValue(fieldName: string, value: any): ODataFilters {
    const filter = this.find(x => x.fieldName === fieldName);
    if (filter) {
      filter.value = value;
    }
    return this;
  }

  public setFieldNameOperator(fieldName: string, operator: ODataFilterOperator): ODataFilters {
    const filter = this.find(x => x.fieldName === fieldName);
    if (filter) {
      filter.operator = operator;
    }
    return this;
  }

  public toQueryString(): string | undefined {
    let parts = [] as string[];
    this.forEach(x => {
      const f = x.toString();
      if (f) {
        parts.push(f);
      }
    });

    return parts.length > 0 ? parts.join(" and ") : undefined;
  }

  public clone(): ODataFilters {
    const copy = new ODataFilters();
    this.forEach(x => copy.push(x));
    return copy;
  }

}