import React from "react";

import ThemeContext, { ThemeType } from "./ThemeContext";
import { darkTheme, lightTheme } from "./Theme";
import { MuiThemeProvider } from "@material-ui/core/styles";

export interface ThemeContextProviderProps {
    children?: React.ReactNode,
}

const STORAGE_THEME_TYPE_KEY = "ferias40_theme_type";

export default function ThemeContextProvider({ children }: ThemeContextProviderProps) {

    const [type, setType] = React.useState<ThemeType>(() => {
        const t = localStorage.getItem(STORAGE_THEME_TYPE_KEY);
        if (t && (t === "dark" || t === "light")) {
            return t as ThemeType;
        }
        return "light";
    });
    const [theme, setTheme] = React.useState(lightTheme);

    React.useEffect(() => {
        localStorage.setItem(STORAGE_THEME_TYPE_KEY, type);
        setTheme(type === "dark" ? darkTheme : lightTheme);
    }, [type]);

    const set = React.useCallback((type: ThemeType) => {
        setType(type);
    }, []);

    return (
        <ThemeContext.Provider value={{ set, type, theme }}>
            <MuiThemeProvider theme={theme}>
                {children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
}
