import React from "react";
import { Guid } from "guid-typescript";
import { useParams } from "react-router-dom";

import { Button, CircularProgress, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import { CentralColumnLayout } from "../../../components/Layouts";
import useGetEventOverview from "./useGetEventOverview";
import { PreviousIcon, ArchiveIcon, EventIcon } from "../../../App/Theme";
import { routeFactory, RouteName } from "../../../tools/Route";
import Cover from "./Cover";
import Informations from "./Informations";
import EventDays from "./EventDays";
import { useNotificationContext } from "../../../components/Notification";
import { fetchRequest } from "../../../tools/fetchRequest";
import { ArchiveEventCommand } from "../../../models/api/admin/events/archive/ArchiveEventCommand";
import ConfirmationDialog from "../../../components/ConfirmationDialog";

const useStyles = makeStyles((theme: Theme) => createStyles({
  rowTop: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  rowBottom: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  right: {
    marginLeft: "auto",
  },
}));

export default function ListEventsPage() {

  const classes = useStyles();
  const { success, error } = useNotificationContext();

  const params = useParams<{ eventId: string }>();
  const [eventId, setEventId] = React.useState(Guid.parse(params.eventId));
  const [loading, event] = useGetEventOverview(eventId);

  const [archiveDialogOpen, setArchiveDialogOpen] = React.useState(false);
  const [archiveLoading, setArchiveLoading] = React.useState(false);

  const reloadEvent = React.useCallback(() => {
    setEventId(Guid.parse(eventId.toString()));
  }, [eventId]);

  const handleValidArchiveDialog = React.useCallback(async () => {
    try {
      setArchiveLoading(true);
      setArchiveDialogOpen(false);
      const command = new ArchiveEventCommand({ id: event.id });
      await fetchRequest(command);
      setArchiveLoading(false);
      reloadEvent();
      success("L'évènement a bien été archivé");
    }
    catch (err) {
      setArchiveLoading(false);
      error("Une erreur est survenue !");
    }
  }, [event, reloadEvent, success, error]);

  document.title = loading
    ? "Chargement de l'évènement - Administration - Ferias40"
    : `${event.townName}, ${event.eventTypeName} - Administration - Ferias40`;

  const backButton = (
    <Button
      className={classes.right}
      color="primary"
      variant="contained"
      startIcon={<PreviousIcon />}
      href={routeFactory.fullPath(RouteName.EventList)}
    >
      Liste des évènements
    </Button>
  );

  return (
    <CentralColumnLayout size="md">

      <div className={classes.rowTop}>
        {loading
          ?
          <Typography component="h1" variant="h5">
            Chargement de l'évènement ...
              </Typography>
          :
          <Typography component="h1" variant="h5">
            {event.townName}, {event.eventTypeName}
          </Typography>
        }
        {backButton}
      </div>

      <EventDays
        loading={loading}
        event={event}
        reloadEvent={reloadEvent}
      />

      <Informations
        loading={loading}
        event={event}
      />

      <Cover
        loading={loading}
        event={event}
        reloadEvent={reloadEvent}
      />

      <div className={classes.rowBottom}>
        <Button
          color="secondary"
          variant="contained"
          startIcon={archiveLoading ? <CircularProgress size={18} /> : <ArchiveIcon />}
          onClick={() => setArchiveDialogOpen(true)}
          disabled={loading || event.isArchived || archiveLoading}
        >
          {event.isArchived ? "Archivé" : "Archiver"}
        </Button>

        {backButton}
      </div>

      <ConfirmationDialog
        icon={<EventIcon />}
        title="Confirmation"
        open={archiveDialogOpen}
        onClose={() => setArchiveDialogOpen(false)}
        onValid={handleValidArchiveDialog}
      >
        <Typography component="p">
          Etes vous sûr de vouloir archiver cet évènement,
          il ne pourra pas être désarchivé ultérieurement ?
          </Typography>
      </ConfirmationDialog>

    </CentralColumnLayout>
  );
}
