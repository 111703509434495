import { IMessage } from "../../IMessage";

//Generated code, do not modify

export class ListActivityGroupsQuery implements IMessage {

    public constructor(init?: Partial<ListActivityGroupsQuery>) {
        Object.assign(this, init);
    }

    getMethod = () => "GET";

    getUrl = () => `/admin/activity-groups`;

    getQueryString = () => undefined;

    getBody = () => undefined;
}