import { Guid } from "guid-typescript";

import { useFetchDataById } from "../../../tools/fetchRequest";
import { GetEventDayProgramQuery } from "../../../models/api/admin/event-days/program/GetEventDayProgramQuery";
import { EventDayProgramResponse } from "../../../models/api/admin/event-days/program/EventDayProgramResponse";

function emptyEventDayProgramBuilder() {
    let entity = new EventDayProgramResponse();
    entity.id = Guid.createEmpty();
    entity.eventId = Guid.createEmpty();
    entity.date = new Date();
    entity.endAt = "";
    entity.name = "";
    entity.townId = Guid.createEmpty();
    entity.townName = "";
    entity.eventTypeId = Guid.createEmpty();
    entity.eventTypeName = "";
    entity.busTimes = [];
    entity.activities = [];
    return entity;
}

const getEventDayProgramQueryBuilder = (id: Guid) => new GetEventDayProgramQuery({ id });

const useGetEventDayProgram = (id: Guid) => {
    return useFetchDataById(
        id,
        getEventDayProgramQueryBuilder,
        emptyEventDayProgramBuilder
    );
};

export default useGetEventDayProgram;