import React from "react";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress,
    Button,
    SvgIconProps,
} from "@material-ui/core";

import { CloseIcon, SaveIcon } from "../../App/Theme";
import Form, { FormProps } from "../Form";

const useStyles = makeStyles((theme: Theme) => createStyles({
    dialogPaper: {
        width: "100%",
        margin: theme.spacing(3),
    },
    title: {
        display: "flex",
        flexDirection: "row",
        paddingBottom: 0,
        "& svg": {
            marginRight: theme.spacing(1),
            verticalAlign: "middle",
            marginTop: theme.spacing(1) * -0.5,
        },
        "& h6": {
            paddingBottom: theme.spacing(1) * 2,
            width: "100%",
            borderBottomColor: theme.palette.getContrastText(theme.palette.background.paper),
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
        }
    },
    contentWrapper: {
        position: "relative",
    },
    contentLoading: {
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.palette.background.paper,
        opacity: 0.8,
        zIndex: theme.zIndex.appBar,
    },
    actions: {
        display: "flex",
        flexDirection: "row",
        padding: theme.spacing(1) * 2,
        justifyContent: "space-between",
    },
}));

export interface FormDialogProps<T, R = any> {
    title: string;
    icon?: React.ReactElement<SvgIconProps>;
    loading?: boolean;
    open: boolean;
    onClose: (() => void);

    form: FormProps<T, R>;
}

export default function FormDialog<T, R = any>(props: FormDialogProps<T, R>) {

    const classes = useStyles();
    const submitRef = React.useRef<HTMLButtonElement>(null);
    const [formLoading, setFormLoading] = React.useState(false);

    const handleClose = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        props.onClose();
    }, [props]);

    const handleSubmit = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        if (submitRef && submitRef.current) {
            submitRef.current.click();
        }
    }, []);

    const handleFormPrepareMessage = React.useCallback((model: T) => {
        setFormLoading(true);
        return props.form.onPrepareMessage(model);
    }, [props.form]);

    const handleFormSuccess = React.useCallback((response: R) => {
        setFormLoading(false);
        props.form.onSuccess && props.form.onSuccess(response);
        props.onClose();
    }, [props]);

    const handleFormError = React.useCallback((error: any) => {
        setFormLoading(false);
        props.form.onError && props.form.onError(error);
    }, [props]);

    return (
        <Dialog
            fullWidth
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="dialog-title"
            classes={{ paper: classes.dialogPaper }}
        >

            <DialogTitle id="dialog-title" className={classes.title}>
                {props.icon ? props.icon : null} {props.title}
            </DialogTitle>

            <DialogContent>

                <div className={classes.contentWrapper}>
                    {props.loading && <div className={classes.contentLoading}><CircularProgress size={28} /></div>}
                    <Form<T, R>
                        {...props.form}
                        isSubmit={false}
                        submitRef={submitRef}
                        onPrepareMessage={handleFormPrepareMessage}
                        onSuccess={handleFormSuccess}
                        onError={handleFormError}
                    />
                </div>

            </DialogContent>

            <DialogActions className={classes.actions}>

                <Button
                    color="primary"
                    variant="contained"
                    startIcon={<CloseIcon />}
                    onClick={handleClose}
                >
                    Fermer
                </Button>

                <Button
                    color="secondary"
                    variant="contained"
                    disabled={props.loading || formLoading}
                    onClick={handleSubmit}
                    endIcon={formLoading
                        ? <CircularProgress size={18} />
                        : (props.form.submitIcon ? props.form.submitIcon : <SaveIcon />)
                    }
                >
                    {props.form.submitLabel ? props.form.submitLabel : "Sauvegarder"}
                </Button>

            </DialogActions>

        </Dialog>
    )
}
