import { Guid } from "guid-typescript";

import { useFetchDataById } from "../../tools/fetchRequest";
import { GetEventDayQuery } from "../../models/api/admin/event-days/GetEventDayQuery";
import { EventDayResponse } from "../../models/api/admin/event-days/EventDayResponse";

function emptyEventDayBuilder() {
    let eventDay = new EventDayResponse();
    eventDay.id = Guid.createEmpty();
    eventDay.name = "";
    eventDay.date = new Date();
    eventDay.endAt = "";
    eventDay.busTimes = [];
    return eventDay;
}

const getEventDayQueryBuilder = (id: Guid) => new GetEventDayQuery({id});

const useGetEventDay = (id: Guid) => {
    return useFetchDataById(
        id,
        getEventDayQueryBuilder,
        emptyEventDayBuilder
    );
};

export default useGetEventDay;