import React from "react"
import { RouteComponentProps, useHistory } from "react-router"

import {
  Tooltip, Fab,
} from "@material-ui/core"
import { SvgIconProps } from "@material-ui/core/SvgIcon"
import { Theme } from "@material-ui/core/styles"
import { createStyles, makeStyles } from "@material-ui/styles"

const useStyles = makeStyles((theme: Theme) => createStyles({
  fab: {
    position: "fixed",
    bottom: theme.spacing(1) * 5,
    right: theme.spacing(1) * 3,
    zIndex: theme.zIndex.modal,
    "& a": {
      color: "inherit",
      height: 24,
    }
  },
}));

export interface CornerButtonProps {
  icon: React.ReactElement<SvgIconProps>,
  tooltip: string,
  tooltipPlacement?: "left" | "right" | "top" | "bottom",
  to?: string,
  onClick?: (() => void),
}

export type CombineProps = CornerButtonProps & RouteComponentProps

export default function CornerButton(props: CornerButtonProps) {

  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (props.to) {
      history.push(props.to)
    }
    else if (props.onClick) {
      props.onClick();
    }
  }

  const renderFab = () => {
    return (
      <Fab
        size="medium"
        color="primary"
        className={classes.fab}
        onClick={handleClick}
      >
        {props.icon}
      </Fab>
    )
  }

  const tooltipPlacement = props.tooltipPlacement ? props.tooltipPlacement : "left"

  return (
    <Tooltip
      title={props.tooltip}
      placement={tooltipPlacement}
      disableFocusListener={true}
    >
      {renderFab()}
    </Tooltip>
  )
}
