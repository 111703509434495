import { useFetchOData } from "../../tools/fetchRequest";
import { OrderDirection, ODataOrderBy } from "../../models/api/OData";
import { SearchEventsQuery } from "../../models/api/admin/events/SearchEventsQuery";
import { EventItemResponse } from "../../models/api/admin/events/ODataResponse[EventItemResponse]";

const defaultSearchEventsQuery = new SearchEventsQuery({
    top: 1000,
    skip: 0,
    orderBy: new ODataOrderBy({
        direction: OrderDirection.Asc,
        member: EventItemResponse.Fields.startAt.name,
    }),
});

function useSearchEvents(searchEventsQuery: SearchEventsQuery | undefined = undefined) {
    return useFetchOData<EventItemResponse>(searchEventsQuery !== undefined
        ? searchEventsQuery
        : defaultSearchEventsQuery
    );
};

export default useSearchEvents;