import React from "react";

import { ContactIcon } from "../../App/Theme";
import { FormFieldsBuilder } from "../../components/FormField";
import { AskPasswordResetQuery } from "../../models/api/admin/users/password/AskPasswordResetQuery";
import { IField } from "../../models/api/IField";
import FormDialog from "../../components/FormDialog";

class AskPasswordResponse {
    email!: string;

    public static Fields = {
        email: { name: "email", type: "string" },
    } as Record<"email", IField>;
}

function emptyResponseBuilder() {
    let response = new AskPasswordResponse();
    response.email = "";
    return response;
}

export interface AskPasswordFormDialogProps {
    open: boolean,
    onClose: (() => void),
    onAfterSubmit: (() => void),
}

function AskPasswordFormDialog({ open, onClose, onAfterSubmit }: AskPasswordFormDialogProps) {

    const fields = React.useMemo(() => buildFields(), []);

    const buildMessage = React.useCallback((data: AskPasswordResponse) => {
        return new AskPasswordResetQuery(data);
    }, []);

    return (
        <FormDialog<AskPasswordResponse>
            title="Mot de passe oublié"
            icon={<ContactIcon />}
            open={open}
            onClose={onClose}

            form={{
                submitLabel: "Demander",
                fields,
                initial: emptyResponseBuilder(),
                onSuccess: onAfterSubmit,
                onPrepareMessage: buildMessage,
            }}
        />
    )
}

function buildFields() {

    const builder = new FormFieldsBuilder<AskPasswordResponse>(AskPasswordResponse.Fields);

    builder
        .setFieldProps(AskPasswordResponse.Fields.email, {
            label: "Adresse email",
            placeholder: "ex : contact@ferias40.fr",
            type: 'email',
        })
        ;

    return builder.build();

}

export default AskPasswordFormDialog;