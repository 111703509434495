import React from "react";

import { SvgIconProps } from "@material-ui/core/SvgIcon";
import { IconButton } from "@material-ui/core";

import { IODataGridColumnOptions } from "./types";
import { IField } from "../../models/api/IField";
import { EditIcon } from "../../App/Theme";

const POSITION_MAX = 500;

export default class ODataGridColumnsBuilder<T> {

    fieldNames: string[];
    columns: IODataGridColumnOptions<T>[];

    constructor(entityFields: Record<string, IField>) {
        this.columns = [];
        this.fieldNames = Object.keys(entityFields);

        this.fieldNames.forEach(k => {
            const f = entityFields[k];
            this.columns.push({
                fieldName: f.name,
                type: f.type,
                label: f.name,
                hidden: false,
                position: POSITION_MAX,
                sortable: false,
                filterable: false,
                filterOperators: ["contains", "eq", "ne"],
                filterField: {
                    hidden: false,
                    position: POSITION_MAX,
                    fieldName: f.name,
                    type: f.type,
                    margin: "dense",
                    required: false,
                    fullWidth: false,
                    color: "primary",
                    label: "",
                    autoClearAdornment: true,
                },
            });
        });
    }

    public hiddenField(field: IField): ODataGridColumnsBuilder<T> {
        this.setFieldProps(field, { hidden: true });
        return this;
    }

    public setFieldProps<P extends Partial<IODataGridColumnOptions<T>>>(field: IField, props: P): ODataGridColumnsBuilder<T> {
        const idx = this.fieldNames.findIndex(x => x === field.name);
        if (idx !== -1) {
            let tmp = this.columns[idx];
            if (props.filterField) {
                tmp.filterField = { ...tmp.filterField, ...props.filterField };
            }
            tmp = { ...tmp, ...props, filterField: tmp.filterField };
            this.columns[idx] = tmp;
        }

        return this;
    }

    public addColumn<P extends Partial<IODataGridColumnOptions<T>>>(props: P): ODataGridColumnsBuilder<T> {
        this.columns.push({
            hidden: false,
            position: POSITION_MAX,
            ...props as IODataGridColumnOptions<T>
        });
        return this;
    }

    public addAction(icon: React.ReactElement<SvgIconProps>, onAction: (item: T) => void): ODataGridColumnsBuilder<T> {
        return this.addColumn({
            hidden: false,
            position: POSITION_MAX,
            type: "action",
            padding: "checkbox",
            render: (item: T) =>
                <IconButton onClick={() => onAction(item)} color="primary">
                    {icon}
                </IconButton>
        });
    }

    public addEditColumn(onEdit: (item: T) => void): ODataGridColumnsBuilder<T> {
        this.addAction(<EditIcon />, onEdit);
        return this;
    }

    public build(): IODataGridColumnOptions<T>[] {
        return this.columns
            .filter(c => c.hidden !== true)
            .sort((a, b) => this.compare(a.position, b.position));
    }

    public compare(a: number | undefined, b: number | undefined) {
        if (a === undefined && b === undefined) {
            return 1;
        }
        else if (a === undefined && b !== undefined) {
            return 1;
        }
        else if (a !== undefined && b === undefined) {
            return -1;
        }
        else {
            return (a as number) - (b as number);
        }
    }
}